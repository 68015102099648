import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Button from "./Button";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import ToolsDropdown from "./ToolsDropdown";

export default function Header() {
  const isUser = useSelector((state) => state?.user);

  console.log(isUser)
  const handleScroll = () => {
    const targetElement = document.getElementById("faq-sec");

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handlePolicyScroll = () => {
    const targetElement = document.getElementById("policy-sec");

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const aboutUsScroll = () => {
    const targetElement = document.getElementById("about-us");

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToFeedbackForm = () => {
    const targetElement = document.getElementById("feed-back-form");

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="flex flex-row w-screen items-center py-2 bg-white sticky border-b-2 border-gray-100 top-0 z-[999]">
      <div className="flex-none flex justify-center items-center  w-48 h-16 desktopMd:w-52 desktopMd:h-28 desktopXmd:w-56 desktopXmd:h-32 desktopLg:w-60 desktopLg:h-40 desktopXlg:w-64 desktopXlg:h-48 ml-10">
        <img
          src={logo}
          alt="logo_img"
          className="h-16 w-16 desktopMd:h-24 desktopMd:w-24 desktopXmd:h-24 desktopXmd:w-24 desktopLg:h-32 desktopLg:w-32 desktopXlg:h-38 desktopXlg:w-38 select-none"
        />
      </div>
      <div className=" grow flex flex-row text-gray-500 items-center h-16 justify-center gap-x-20 font-poppins font-semibold text-base desktopSm:gap-x-28 desktopMd:gap-x-32 desktopXmd:gap-x-36 desktopLg:gap-x-44 desktopXlg:gap-x-48 desktopMd:h-32 desktopXmd:h-36 desktopLg:h-44 desktopXlg:h-52  desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl ">
        <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md select-none">
          <a href="/home">Home</a>
        </ul>
        <ToolsDropdown />
        {/* <ul className="hover:text-primary active:text-primary">
                    <a href="#">Allied Infra</a>
                </ul>
                <ul className="hover:text-primary active:text-primary">
                    <a href="#">Roads</a>
                </ul> */}
        {/* <ul className="hover:text-primary active:text-primary">
                    <a to="#" onClick={handlePolicyScroll}>Implementation Policy</a>
                </ul> */}

        <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md select-none">
          {isUser ? <Link to="/projects">Projects</Link> : <Link to="/login">Login/Signup</Link>}
        </ul>
        {/* <ul className="hover:text-primary active:text-primary">
                    <a href={`${userExist ? '/projects' : '/login'}`}>Account</a>
                </ul> */}
      </div>
      <div className="flex-none flex w-48 h-16 justify-center items-center mr-10 select-none">
        <Link to="#" onClick={scrollToFeedbackForm}>
          <Button
            label={"Contact Us"}
            className={
              "bg-white  border-primary border-2  rounded-full  w-full  px-3 py-1"
            }
            textColor="text-primary hover:text-white font-saira"
            hoverBg="hover:bg-primary"
            icon={<IoIosSend className="text-2xl" />}
          />
        </Link>
      </div>
    </div>
  );
}

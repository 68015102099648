export const bill3_estimation = [
  {
    "A": "16-09-a",
    "B": "Bitumenous Prime Coat",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-09-b",
    "B": "Bitumenous Tack Coat",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-10-a",
    "B": "Single bitumenous Surface Treatment",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-10-b",
    "B": "Double bitumenous Surface Treatment",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-10-c",
    "B": "Triple bitumenous Surface Treatment",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-13-b",
    "B": "Dense Graded Hot Bitmac (Mobile Asphalt Mixer) 1.5\" Thick",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-13-c",
    "B": "Dense Graded Hot Bitmac (Mobile Asphalt Mixer) 2\" Thick",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-14-a",
    "B": "Asphaltic Base Course (Asphalt Batch Plant Hot Mixed) i/c Transportation and Finishing complete",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": 4,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-14-b",
    "B": "Asphaltic Wearing Course (Asphalt Batch Plant Hot Mixed) i/c Transportation and Finishing complete",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": 4,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "Sub-Total A",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [

    ]
  },
  // {
  //   "A": "MRS code",
  //   "B": "Item description",
  //   "C": "Item selection",
  //   "D": "Unit",
  //   "E": "Number",
  //   "F": "Length",
  //   "G": "Width",
  //   "H": "Height",
  //   "I": "Quantity",
  //   "J": 0,
  //   "K": "Amount (PKR)",
  //   "inputFields": [

  //   ]
  // },
  {
    "A": "16-11-a",
    "B": "Resurfacing of road complete with per 100sft : 22 Ibs. bitumen, 2.5\"cft bajri/crush aggregate",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-11-b",
    "B": "Resurfacing of road complete with per 100sft : 20 Ibs. bitumen, 2 cft bajri/crush aggregate",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-12",
    "B": "Scarification Of Existing Road Pavement Structure",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000,
    "G": 0,
    "H": 0,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-13-a",
    "B": "Dense Graded Hot Bitmac (Mobile Asphalt Mixer) 1\" Thick",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1000,
    "G": 7.4,
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "Sub-Total A",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "Sub-Total Bill 3 (A+B)",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0
  }
]
export const cut = [

    {
        "A": "03-03-a",
        "B": "Excavate Unsuitable Common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-b",
        "B": "Excavate Unsuitable Hard Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-c",
        "B": "Excavate Unsuitable Medium Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-d",
        "B": "Excavate Unsuitable Soft Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-e",
        "B": "Excavate Surplus Common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-f",
        "B": "Excavate Surplus Hard Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-g",
        "B": "Excavate Surplus Medium Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-03-h",
        "B": "Excavate Surplus Soft Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-66-a",
        "B": "Roadway Excavation in Surplus / Unsuitable Common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-66-b",
        "B": "Roadway Excavation in Surplus / Unsuitable Rock (Hard) Material requiring blasting.",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-66-c",
        "B": "Roadway Excavation in Surplus / Unsuitable Rock (Medium) Material requiring blasting",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-66-d",
        "B": "Roadway Excavation in Surplus / Unsuitable Rock (Soft) Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    }


]
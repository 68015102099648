import React, { useEffect, useState } from "react";

import hero_Img from "../assets/home_banner.png";
import hardhat_Img from "../assets/hardhat_Img.png"; //
import './home.css'
import FrontendLayout from "../components/FrontendLayout";

import standardised_light_icon from "../assets/cards_icons/standardised_light.svg";
import standardised_dark_icon from "../assets/cards_icons/standardised_dark.svg";

import tbridge_light_icon from "../assets/cards_icons/bridge_code_light.svg";
import tbridge_dark_icon from "../assets/cards_icons/bridge_code_dark.svg";

import technical_light_icon from "../assets/cards_icons/technical_light.svg";
import technical_dark_icon from "../assets/cards_icons/technical_dark.svg";

import material_light_icon from "../assets/cards_icons/material_testing_light.svg";
import material_dark_icon from "../assets/cards_icons/material_testing_dark.svg";

import project_light_icon from "../assets/cards_icons/project_implementation_light.svg";
import project_dark_icon from "../assets/cards_icons/project_implementation_dark.svg";

import tors_light_icon from "../assets/cards_icons/model_tors_light.svg";
import tors_dark_icon from "../assets/cards_icons/model_tors_dark.svg";

import standardised_Img from "../assets/info_cards/standarddised_project_Img.svg";
import bridge_Img from "../assets/info_cards/bridge_Img.svg";
import technical_Img from "../assets/info_cards/tcehncal_appraisal_Img.svg";
import material_Img from "../assets/info_cards/material_testing_Img.svg";
import moderlTors_Img from "../assets/info_cards/model_tors_Img.svg";
import project_policy_Img from "../assets/info_cards/project_policy_Img.svg";

import arrow_icon from "../assets/info_cards/arrow_icon.svg";
import Card from "../components/Card";
import InfoCard from "../components/InfoCard";
import HomeHeadings from "../components/HomeHeadings";
import Input from "../components/Input";
import First_Section_image from "../assets/First_Section_image.png";
import balloon_green from "../assets/green-balloon.png";
import balloon_blue from "../assets/blue-balloon.png";
import balloon_yellow from "../assets/yellow-balloon.png";
import balloon_red from "../assets/red-balloon.png";
import air_balloon from "../assets/air-balloon.png";
import red_a_balloon from "../assets/red-air-balloon.png"

import { useLocation, useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const [showBalloons, setShowBalloons] = useState(false);
  const [balloons, setBalloons] = useState([]);
  const [isAnimating, setIsAnimating] = useState(true);
  const location = useLocation();

  const balloonImages = [balloon_blue, balloon_green, balloon_yellow,air_balloon,red_a_balloon];

  useEffect(() => {
    if (location.pathname === "/home/launch") {
      setShowBalloons(true);
    }
  }, [location.pathname]);

  useEffect(() => {
 
    const interval = setInterval(() => {
      const randomBalloon = balloonImages[Math.floor(Math.random() * balloonImages.length)];
      const randomLeft = Math.random() * 100;

      setBalloons((prevBalloons) => [
        ...prevBalloons,
        { id: Date.now(), left: randomLeft, image: randomBalloon }, // Add random image and position
      ]);
    }, 200);

    const animationTimeout = setTimeout(() => {
      setIsAnimating(false);
      clearInterval(interval);
    }, 10000); // Stop after 5 seconds

    return () => {
      clearInterval(interval);
      clearTimeout(animationTimeout);
    };

  }, []);

  return (
    <FrontendLayout>
      <div className="flex justify-center">
        {showBalloons &&


          (<>
            {balloons?.map((balloon, index) => (
              <img
                key={balloon.id}
                src={balloon.image}
                alt="balloon"
                className="ballon z-50 w-40 h-40"
                style={{ left: `${balloon.left}%` }}
              />

            ))}
          </>)

        }
        <div className="absolute flex-col w-[456px] mr-8 mt-40 bg-green-900 text-center bg-opacity-55 py-3">
          <span className="text-4xl font-poppins text-center py-10 text-white  select-none">
            Khyber Pakhtunkhwa PIM Portal
          </span>
        </div>
        <img
          className="h-[70vh] w-screen object-cover pointer-events-none select-none"
          src={hero_Img}
          alt="hero_section "
        />
      </div>

      <div className="flex items-center justify-center px-5 gap-5 -translate-y-24 z-0 ">
        <Card
          label="Standardised Modular Designs"
          icon={standardised_dark_icon}
          lightIcon={standardised_light_icon}
          navigate={() => navigate("/download-doc/Standardised_Modular_Designs")}
        />
        <Card
          label="Bridge Code"
          icon={tbridge_dark_icon}
          lightIcon={tbridge_light_icon}
          navigate={() => navigate("/download-doc/Bridge_Code")}
        />
        <Card
          label="Technical Appraisal Checklist"
          icon={technical_dark_icon}
          lightIcon={technical_light_icon}
          navigate={() => navigate("/download-doc/Technical_Appresial")}
        />
        <Card
          label="Materials and Qualification Testing"
          icon={material_dark_icon}
          lightIcon={material_light_icon}
          navigate={() => navigate("/download-doc/Material_Testing")}
        />
        <Card
          label="CRI Handbook"
          icon={project_dark_icon}
          lightIcon={project_light_icon}
          navigate={() => navigate("/download-doc/CRI_Guidelines_Handbook")}
        />
        <Card
          label="Project Implementation Policy"
          icon={tors_dark_icon}
          lightIcon={tors_light_icon}
          navigate={() => navigate("/download-doc/Project_Implementation")}
        />
        <Card
          label="Model TORs"
          icon={tors_dark_icon}
          lightIcon={tors_light_icon}
          navigate={() => navigate("/download-doc/Mode_Tors")}
        />
      </div>

      <div id="about-us" className="flex flex-row gap-10 p-10  justify-center">
        <div className="font-poppins text-primary w-1/2 ">
          <p className="text-lg font-normal  font-roboto py-5  leading-8 ">
            The Khyber Pakhtunkwa (KP) Public Investment Management (PIM) portal is a significant step towards enhancing the efficiency and effectiveness of public investment in infrastructure. On this portal, you will find a suite of seven essential tools designed to optimize public investment management. These tools are part of the KP government’s ongoing efforts to streamline processes, improve transparency, and foster sustainable development. Explore these tools and avail resources for efficient and climate-resilient public infrastructure investment.
          </p>
        </div>
        <div className="flex flex-row justify-center items-center w-1/3 select-none ">
          <img
            src={First_Section_image}
            alt="stop sign construction"
            className="w-96"
          />
        </div>
      </div>

      <div className="flex flex-col gap-10 p-10 px-28 select-none  ">
        <InfoCard
          ImageSource={standardised_Img}
          heading="Standardized Modular Designs"
          text="Generate entire PC-1 documents for your projects using our comprehensive forms. These standardized modular designs ensure efficiency and consistency in project planning."
          linkAddress="/download-doc/Standardised_Modular_Designs#"
        />
        <InfoCard
          ImageSource={bridge_Img}
          heading="Bridge Code"
          LTR
          text="Access the latest standards for robust bridge construction. Stay informed on regulations, guidelines, and specifications to enhance the structural integrity of your projects."
          linkAddress="/download-doc/Bridge_Code"
        />
        <InfoCard
          ImageSource={technical_Img}
          heading="Technical Appraisal Checklist"
          text="Optimize project assessments with our detailed checklists. Ensure that your projects meet the highest standards by systematically evaluating key technical aspects at every stage."
          linkAddress="/download-doc/Technical_Appresial"
        />
        <div id="policy-sec"></div>
        <InfoCard
          ImageSource={project_policy_Img}
          heading="Project Implementation Policy"
          LTR
          text="Gain insights into effective project implementation with our comprehensive policy guidelines. Navigate the intricacies of project execution and management for successful outcomes."
          linkAddress="/download-doc/Project_Implementation"
        />

        <InfoCard
          ImageSource={material_Img}
          heading="Materials and Qualification Testing"
          text="Stay informed on materials testing and qualification procedures. Access guidelines and resources to ensure that the materials used in your projects meet stringent quality standards."
          linkAddress="/download-doc/Material_Testing"
        />

        <InfoCard
          ImageSource={moderlTors_Img}
          heading="Model TORs"
          LTR
          text="Effortlessly navigate project planning with our model TORs. Tailored to meet the unique requirements of the C&W Department, these templates serve as a foundation for clear project scopes and objectives."
          linkAddress="/download-doc/Mode_Tors"
        />
        <InfoCard
          ImageSource={material_Img}
          heading="CRI Handbook"
          text="Consult detailed guidelines on designing and building climate resilient infrastructure (CRI). This handbook ensures that your project designs incorporate essential aspects of climate resilience."
          linkAddress="/download-doc/CRI_Guidelines_Handbook"
        />
      </div>

      <div className="flex flex-col gap-5 bg-primary bg-cover w-full h-full bg-pattern-bg px-2 text-white py-10 border-b border-gray-500">
        <div className="flex flex-row gap-10  p-10 justify-center">
          <p id="feed-back-form" className="w-1/4 text-5xl font-poppins ">
            Feedback
          </p>
          <p className="w-7/12 text-base font-roboto">
            We are dedicated to refining and evolving our tools based on your
            valuable insights. Your feedback is crucial in shaping the future of
            the PIM portal. Whether you have suggestions for improvement,
            want to share your experiences, or have encountered any issues, we
            want to hear from you.
          </p>
        </div>

        <div className="flex flex-col">
          <div className="flex flex-row gap-10  p-10 justify-center ">
            <div className=" flex flex-col gap-1 w-1/2  ">
              <label className="text-white font-poppins">Name</label>
              <Input
                type="text"
                required
                name="username"
                placeholder="Enter Name"
                className="h-10 w-50 font-poppins bg-primary py-2 text-white"
                focusBorder="focus:border-secondary"
              />
            </div>
            <div className=" flex flex-col gap-1 w-1/2  ">
              <label className="text-white font-poppins">Email</label>
              <Input
                type="email"
                required
                name="useremail"
                placeholder="Enter Email"
                className="h-10 w-50 font-poppins bg-primary py-2 "
                focusBorder="focus:border-secondary"
              />
            </div>
          </div>

          <div className="flex flex-row gap-10  p-10 justify-center">
            <div className=" flex flex-col gap-1 w-full">
              <label className="text-white font-poppins">Message</label>
              <Input
                type="text"
                required
                name="message"
                placeholder="Enter Message"
                className="h-10 w-50 font-poppins bg-primary py-2 text-white"
                focusBorder="focus:border-secondary"
              />
            </div>
          </div>
          <button className=" flex flex-row items-center w-fit px-10 py-3 rounded-lg text-secondary gap-2 transition hover:text-green-400 hover:translate-x-2 duration-300">
            <span> Submit</span>
            <img src={arrow_icon} alt="arrow_icon" className="w-8 " />
          </button>
        </div>
      </div>
    </FrontendLayout>
  );
}

import React from 'react'
import { formatNumberWithCommas, round, sumKValues } from '../helpers';

const RoadBoqsTable = ({ billName, billData }) => {


    return (
        <div className="flex flex-col flex-grow gap-4 p-2" >
            <span className={`font-bold text-xl  font-poppins text-center`}>{billName}</span>
            <div className="flex flex-col flex-1   px-10" >
                <div className='flex flex-row gap-1  border-primary border-b font-semibold'>
                    <div className="text-primary w-40 flex flex-row justify-center items-center ">MRs Codes</div>
                    <div className="text-primary w-[420px] flex flex-row justify-center items-center ">Description</div>
                    <div className="text-primary w-44 flex flex-row justify-center items-center ">Quantity</div>
                    <div className="text-primary w-44 flex flex-row justify-center items-center ">Unit Rate</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">Amount (PKR)</div>
                </div>

                {Object.entries(billData)?.map(([key, item], index) => {
                    return (
                        <div key={index} className='flex flex-row '>
                            <div className="flex flex-row justify-start items-center gap-1">

                                <span className={" w-40 px-10"}>{item?.A}</span>
                                <span className={" w-[416px] text-left text-sm p-2"}>{item?.B}</span>
                                <span className={"w-40 text-center"}>{formatNumberWithCommas(round(item?.I, 2))}</span>
                                <span className={"w-40 text-center"}>{formatNumberWithCommas(round(item?.J, 2))} </span>
                                <span className={"w-[200px] text-center"}> {formatNumberWithCommas(round(item?.K, 2))} </span>
                            </div>


                        </div>
                    );
                })}


                <div className='flex flex-row p-2 border-primary border-t gap-2 font-semibold'>
                    <div className="text-primary w-[250px] flex flex-row px-10 items-center ">Total</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                    <div className="text-primary w-52 flex flex-row justify-center items-center ">{formatNumberWithCommas(round(sumKValues(billData)))}</div>
                </div>
            </div>

        </div>
    )
}

export default RoadBoqsTable
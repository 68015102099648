import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Seperator from './Seperator';
import Input from './Input';
import Heading from "./Heading";
import Button from './Button';

import EstimationInputs from './EstimationInputs';
import { formatNumberWithCommas, round, transformArrayToObject } from '../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import RoadBoqsTable from './RoadBoqsTable';

const RoadTable = ({ roadId }) => {

    const [activeTabBill, setActiveTabBill] = useState("boq");
    const { projectId } = useParams();
    const projectData = useSelector((state) =>
        state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
    );

    const currentEditedSegment = projectData?.segments?.find(
        (s) => s.id === parseInt(roadId, 10)
    );


    const bill1OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill1_estimates"));
    const bill2OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill2_estimates"));
    const bill3OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill3_estimates"));
    const bill5OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill5_estimates"));
    const bill6OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill6_estimates"));
    const bill7OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill7_estimates"));



    const sumKValues = function (bill_estimation) {
        let sum = 0;
        for (const key in bill_estimation) {
            if (bill_estimation.hasOwnProperty(key)) {
                const item = bill_estimation[key];

                if (item.K > 0) {
                    sum = sum + parseFloat(item.K);
                }
            }
        }
        return parseFloat(sum);
    };




    const handleTabClick = (tab) => {
        setActiveTabBill(tab);
    };

    return (
        <>
            <div className="flex px-10 mt-5 gap-5">
                <Button onClick={() => handleTabClick("boq")} label="Bill of Quantities"
                    bgColor={activeTabBill === "boq" ? "bg-green-500" : "bg-gray-500"}
                    hoverBg={activeTabBill === "boq" ? "hover:bg-green-600" : "hover:bg-gray-700"}
                />
                <Button onClick={() => handleTabClick("abc")} label="Abstract Of Cost"
                    bgColor={activeTabBill === "abc" ? "bg-green-500" : "bg-gray-500"}
                    hoverBg={activeTabBill === "abc" ? "hover:bg-green-600" : "hover:bg-gray-700"
                    }
                />
            </div>
            {activeTabBill === "boq" ? (
                <div className='flex flex-col gap-2  font-poppins' >

                    <RoadBoqsTable billName={"Bill 1 Estimation: Earth Work"} billData={bill1OnEdit} />
                    <RoadBoqsTable billName={"Bill 2 Estimation Total: Subbase & Base"} billData={bill2OnEdit} />
                    <RoadBoqsTable billName={"Bill 3 Estimation: Surface Course"} billData={bill3OnEdit} />
                    <RoadBoqsTable billName={"Bill 5 Estimation: Drainage Works"} billData={bill5OnEdit} />
                    <RoadBoqsTable billName={"Bill 6 Estimation: Ancillary Works"} billData={bill6OnEdit} />
                    <RoadBoqsTable billName={"Bill 7 Estimation: Miscellaneous"} billData={bill7OnEdit} />



                </div>
            ) : <>
                <div className=' font-poppins text-primary p-5 flex justify-center' >
                    <table cellPadding={2} cellSpacing={2} className='border border-primary w-full'>

                        <tbody>
                            <tr className='border border-primary' >
                                <td className='text-lg text-center' colSpan={5}>
                                    Bill of Quantities
                                </td>
                            </tr>
                            <tr>
                                <td className='text-lg text-center' colSpan={5}>
                                    Abstract of Costs

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary '>
                                    Bill #
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Description
                                </td>
                                <td className='text-base text-center border border-primary'>
                                    BoQ Cost
                                </td>
                                <td className='text-base text-center border border-primary'>
                                    Remarks
                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    1
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Earthwork
                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill1OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    2
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Subbase &amp; Base

                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill2OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    3
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Surface course
                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill3OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    5
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Drainage works

                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill5OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    6
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Ancillary works

                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill6OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base text-center border border-primary'>
                                    7
                                </td>
                                <td className='text-base text-center border border-primary' colSpan={2}>
                                    Miscellaneous

                                </td>
                                <td className='text-base text-center border border-primary'>
                                    {formatNumberWithCommas(round(sumKValues(bill7OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>
                            </tr>
                            <tr>
                                <td className='text-base font-semibold p-1 border border-primary' colSpan={3}>
                                    Subtotal amount of Civil Works (A):
                                </td>
                                <td className='text-base text-center border border-primary' >
                                    {formatNumberWithCommas(round(sumKValues(bill1OnEdit) + sumKValues(bill2OnEdit) + sumKValues(bill3OnEdit) + sumKValues(bill5OnEdit) + sumKValues(bill6OnEdit) + sumKValues(bill7OnEdit)))}
                                </td>
                                <td className='text-base text-center border border-primary'>

                                </td>

                            </tr>
                        </tbody>

                    </table>
                </div>
            </>
            }
        </>
    )
}

export default RoadTable


import React, { useEffect, useState } from "react";
import Input from "../components/Input";
import { useDispatch } from "react-redux";
import { round, formatNumberWithCommas } from "../helpers";
import Select from "react-select";

const EstimationInputs = ({ data, bill, children }) => {
  const arrayData = Object.entries(data);
  const dispatch = useDispatch();

  useEffect(() => {
    const needToDispatch = {};
    arrayData.forEach(([code, j]) => {
      if (j?.mustInclude) {

        needToDispatch[code] = true;
      }
    });

  }, []);

  if (!arrayData || !Array.isArray(arrayData) || arrayData.length === 0) {

    return <div>No data available</div>;
  }



  const updateBillEstimates = (e, indexNumber, itemCode) => {
    const indexData = parseFloat(e.target.value);

    if (bill === "bill7_estimates") {
      dispatch({
        type: "road/updateBill7Estimates",
        payload: {
          billName: bill,
          index: indexNumber,
          item: itemCode,
          billData: indexData,
        },
      });
    }
    else {
      dispatch({
        type: "road/updateBillEstimates",
        payload: {
          billName: bill,
          index: indexNumber,
          item: itemCode,
          billData: indexData,
        },
      });
    }
  };
  const handleAddLine = (e) => {
    const code = e.value;
    dispatch({
      type: "road/includeBillLine",
      payload: { bill, code, include: true },
    });
  };
  const handleRemoveLine = (e) => {
    const code = e.value;
    dispatch({
      type: "road/includeBillLine",
      payload: { bill, code, include: false },
    });
  };



  let count = 0;

  return (
    <div className="w-full">

      {
        bill === "bill1_estimates_A" ? "" :
          <Select
            onChange={(e) => handleAddLine(e)}
            options={arrayData
              .filter(([i, item]) => {
                return (item?.inputFields && Array.isArray(item?.inputFields) && item?.inputFields.length > 0 && (item?.included === false || item?.included === undefined));
              })
              .map(([i, j]) => {
                return { label: <span className="px-2 flex flex-row gap-10 w-full "><span className="flex flex-2  w-24">{i}</span><span className="text-sm flex-1 justify-start">{j?.B}</span></span>, value: i };
              })}
          />
      }
      <>
        <div className="py-2">
          {children}
        </div>
      </>
      {arrayData
        .filter(([i, j]) => {
          return j?.included === true || j?.mustInclude === true;
        })
        .map(([key, item]) => {
          count++;
          if (
            item?.inputFields && Array.isArray(item.inputFields) && item.inputFields.length > 0 || item?.mustInclude === true
          ) {
            return (
              <div key={`input-${item.A}-${count}`} className="flex flex-row gap-2 items-center  border-b sticky top-0">

                <div>
                  <div className="flex justify-center">
                    {!item.mustInclude && <div
                      className=" flex flex-col justify-center items-center rounded-full  bg-red-700  text-white p-1 h-5 w-5 cursor-pointer"
                      onClick={() => handleRemoveLine({ value: item.A })}
                    >
                      <span>x</span>
                    </div>}

                    <div className="text-gray-400 p-2  text-sm w-[250px] ">
                      <div>{item.A}</div>
                      <div className="block overflow-hidden truncate line-clamp-2">
                        {item.B}
                      </div>
                    </div>
                  </div>
                </div>


                <div className="flex gap-2 flex-row justify-start">


                  {item?.inputFields?.map((inputName, key) => {


                    return (
                      <Input
                        key={inputName}
                        type="number"
                        name={inputName}
                        required
                        min={0}
                        width="w-36"
                        value={item[inputName] ? item[inputName] : ""}
                        placeholder={getPlaceholder(inputName)}
                        onChange={(e) => {
                          updateBillEstimates(e, inputName, item.A);
                        }}
                        className={"border"}
                      />
                    );
                  })}

                  {item?.inputFields !== undefined && !item?.inputFields.includes("F") &&
                    <span className={`${bill === "bill7_estimates" ? "w-20 flex justify-center items-center " : "w-36 text-center flex justify-center items-center"}`}>
                      {isNaN(item?.F) ? "-" : formatNumberWithCommas(round(item?.F, 2))}
                    </span>}

                  {item?.inputFields !== undefined && !item?.inputFields.includes("G") &&
                    <span className="w-36 text-center flex justify-center items-center">
                      {isNaN(item?.G) ? "-" : formatNumberWithCommas(round(item?.G, 2))}
                    </span>}

                  {item?.inputFields !== undefined && !item?.inputFields.includes("H") &&
                    <span className="w-36 text-center flex justify-center items-center">
                      {isNaN(item?.H) ? "-" : formatNumberWithCommas(round(item?.H, 2))}
                    </span>}

                  {
                    item?.inputFields == undefined && item?.mustInclude == true ? (<span className={`${item?.inputFields == undefined ? "w-52 text-center" : "w-52"}`}>{formatNumberWithCommas(round(item?.I, 2))}</span>) : null

                  }

                  {bill !== 'bill7_estimates' &&
                    <span className={`${item?.inputFields == undefined ? "w-52 text-center" : "w-20 text-center flex justify-center items-center"}`}>
                      {formatNumberWithCommas(round(item?.J, 2))}
                    </span>
                  }

                  <span className={`${item?.inputFields == undefined ? "w-52 text-center" : "w-36 text-center flex justify-center items-center"}`}>
                    {formatNumberWithCommas(round(item?.K, 2))}
                  </span>
                </div>
                <div>

                </div>
              </div>
            );
          } else {
            return null; // Skip rendering for items with no inputs
          }
        })}
    </div>
  );
};

const getPlaceholder = (inputName) => {
  switch (inputName) {
    case "E":
      return "Number";
    case "F":
      return "Length";
    case "G":
      return "Width";
    case "H":
      return "Height";
    case "J":
      return "Unit Rate";
    default:
      return "";
  }
};

export default EstimationInputs;

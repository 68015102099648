export const bill7_estimation = [
  {
    "A": "701-NHA",
    "B": "Provision of Survey Teams and Instruments",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "702-NHA",
    "B": "Provide, Equip and Maintain Office Facility and Residence \nfor the Engineer (Base Camp Facility)",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "703-NHA",
    "B": "Provide, Equip and Maintain Laboratory for the Project",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "704-NHA",
    "B": "Mainte'ce of Works for one year after Completion \n(Period of Maintenance)",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "705-NHA",
    "B": "Temporary Road Works",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "706-NHA",
    "B": "Control and Protection of Traffic",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "707-NHA",
    "B": "Removal/Relocation of Utilities",
    "C": "No",
    "D": "",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": "",
    "K": 0,
    "inputFields": [
      "E", "J"
    ]
  },
  {
    "A": "Sub-Total Bill 7",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [

    ]
  }
]
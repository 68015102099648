import React from 'react'
import Input from './Input';
import { round } from '../helpers';

const RoadTableInputs = ({ data,onChange }) => {
    let count = 0;
    return (
        <>
            {
                data.map((item) => {
                    count++;
                    if (item.inputs && Array.isArray(item.inputs) && item.inputs.length > 0) {
                        return (

                            <React.Fragment key={`k-${count}-${item.A}`}>
                                {
                                    item.inputs.map(inputName => (

                                        <>
                                            <tr key={`table-input-${item.A}-count`} className="py-4 border-b-2 border-gray-200">
                                                <td className={'px-3 py-4 w-fit'}>{item.A}</td>
                                                <td className={'px-3 py-4  w-fit'}>{item.B}</td>
                                                <td className={'py-2 flex flex-row items-center px-2'}>
                                                    <Input
                                                        key={inputName}
                                                        type="number"
                                                        name={item.A}
                                                        required
                                                        min={0}
                                                        onChange={onChange}
                                                        defaultValue={item.C}
                                                        value={item.C}
                                                        // placeholder={getPlaceholder(inputName)}
                                                        // onChange={(e) => { updateBillEstimates(e, inputName, item.A) }}
                                                        className={"border"}
                                                    />
                                                </td>
                                                <td className={'px-3 py-4  w-fit'}>{round(item.D, 2)}</td>
                                            </tr>
                                        </>
                                    ))}

                            </React.Fragment>

                        )
                    } else {
                        return null; // Skip rendering for items with no inputs
                    }
                })}
        </>
    );
}

export default RoadTableInputs
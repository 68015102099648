import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaIcons } from "../components/Icons";
import BackendLayout from "../components/BackendLayout";
import SearchBar from "../components/SearchBar";
import FilterBtn from "../components/FilterBtn";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";

import two_Ax_Single_Bedford from "../assets/esel_truck/2-Ax_Single_Bedford.svg";
import three_Ax_Single from "../assets/esel_truck/3-Ax Single.svg";
import three_Ax_Tendem from "../assets/esel_truck/3-Ax Tandem.svg";
import four_Ax_Single from "../assets/esel_truck/4-Ax Single.svg";
import four_Ax_Single_Tendem from "../assets/esel_truck/4-Ax Single-Tandem.svg";
import four_Ax_Single_Single_Tendem from "../assets/esel_truck/4-Ax_Tandem-Single.svg";
import five_Ax_Single_Single_Tandem from "../assets/esel_truck/5-Ax Single-Single-Tandem.svg";
import five_Ax_Single_Tridem from "../assets/esel_truck/5-Ax Single-Tridem.svg";
import five_Ax_Tandem_Single_Single from "../assets/esel_truck/5-Ax Tandem-Single-Single.svg";
import five_Ax_Tandem_Tandem from "../assets/esel_truck/5-Ax Tandem-Tandem.svg";
import six_Ax_Tandem_Tridem from "../assets/esel_truck/6-Ax Tandem-Tridem.svg";
import six_Ax_Tandem_Single_Tandem from "../assets/esel_truck/6-Ax Tandem-Single-Tandem 1.svg";
import four_WD from "../assets/esel_truck/4WD.svg";
import bus from "../assets/esel_truck/Bus.svg";
import car from "../assets/esel_truck/Car.svg";
import Hiace from "../assets/esel_truck/Hiace.svg";
import Minibus from "../assets/esel_truck/Minibus.svg";
import Motorcycle from "../assets/esel_truck/Motorcycle.svg";
import Rikshaw from "../assets/esel_truck/Rikshaw.svg";
import Tractor_Trolly from "../assets/esel_truck/Tractor_Trolley.svg";

import { useDispatch, useSelector } from "react-redux";
import { round } from "../helpers";

const Esal = () => {
  const adt = useSelector((state) => state.road.adt);
  const adtEsal = useSelector((state) => state.road.adtEsal);
  const { dl, lf, g, df } = adtEsal;
  const dispatch = useDispatch();
  const plusIcon = "FaPlus";
  let count = 1;
  const traffic = {
    1: { label: "Passenger cars", icon: car },
    2: { label: "Buses", icon: bus },
    3: { label: "Tractor trolley", icon: Tractor_Trolly },
    4: { label: "Pickup/Jeep/4WD", icon: four_WD },
    5: { label: "Motorcycle", icon: Motorcycle },
    6: { label: "Rickshaw", icon: Rikshaw },
    7: { label: "Van/Hiace", icon: Hiace },
    8: { label: "Minibus/Flying coach", icon: Minibus },
    9: { label: "2-Ax Single (Bedford)", icon: two_Ax_Single_Bedford },
    10: {
      label: "2-Ax Single (Hino/Nissan)",
      icon: two_Ax_Single_Bedford,
    },
    11: { label: "3-Ax Tandem", icon: three_Ax_Tendem },
    12: { label: "3-Ax Single", icon: three_Ax_Single },
    13: { label: "4-Ax Single-Tandem", icon: four_Ax_Single_Tendem },
    14: { label: "4-Ax Tandem-Single", icon: four_Ax_Single_Single_Tendem },
    15: { label: "4-Ax Single", icon: four_Ax_Single },
    16: { label: "5-Ax Single-Tridem", icon: five_Ax_Single_Tridem },
    17: { label: "5-Ax Tandem-Tandem", icon: five_Ax_Tandem_Tandem },
    18: {
      label: "5-Ax Single-Single-Tandem",
      icon: five_Ax_Single_Single_Tandem,
    },
    19: {
      label: "5-Ax Tandem-Single-Single",
      icon: five_Ax_Tandem_Single_Single,
    },
    20: { label: "6-Ax Tandem-Tridem", icon: six_Ax_Tandem_Tridem },
    21: {
      label: "6-Ax Tandem-Single-Tandem",
      icon: six_Ax_Tandem_Single_Tandem,
    },
  };
  const esalFactors = {
    1: 0.0008,
    2: 0.6806,
    3: 5.4729,
    4: 0.0024,
    5: 0.0002,
    6: 0.0004,
    7: 0.3403,
    8: 0.5105,
    9: 5.4729,
    10: 5.4729,
    11: 6.9824,
    12: 12.8296,
    13: 9.804,
    14: 9.804,
    15: 13.9447,
    16: 5.667,
    17: 10.8602,
    18: 15.6168,
    19: 16.0943,
    20: 11.471,
    21: 16.9676,
  };
  const handleChange = (e, inputIndex, key) => {
    const { value } = e.target;
    dispatch({ type: "road/changeAdt", payload: { value, key, inputIndex } });
  };
  const handleDesignChange = (e) => {
    const { value, name } = e.target;
    dispatch({ type: "road/changeAdtEsal", payload: { value, name } });
  };
  const growthFactor = (growthRate, designLife) => {
    return ((1 + growthRate / 100) ** designLife - 1) / (growthRate / 100);
  };
  let commulativeEsal = 0;
  return (
    <BackendLayout screenName="Modular Design ">
      <div className="flex flex-row items-center ">
        <Breadcrumb
          items={[
            { itemName: "Home", itemLink: "" },
            { itemName: "ESAL Calculator", itemLink: "" },
          ]}
        />


        {/* <div className="flex flex-grow flex-row  gap-4 items-center justify-end p-2 font-poppins">
          <Link to={"add-project"}>
            <Button
              label={"Add a New Project"}
              icon={<FaIcons iconName={plusIcon} />}
              className={"w-fit rounded-lg"}
            />
          </Link>
        </div> */}


      </div>
      <p className="ml-5 font-poppins font-semibold text-2xl mt-2 p-y-20 desktopSm:text-3xl desktopMd:text-4xl desktopXmd:text-5xl desktopLg:text-6xl desktopXlg:text-7xl">
        Design ESALs Calculation
      </p>
      {/* <div className="flex flex-row p-6 items-center"> */}
      {/* <SearchBar /> */}
      {/* <FilterBtn /> */}

      {/* </div> */}
      <table className="border-b border-solid m-6 text-sm">
        <tbody>

          {/* <tr className="border">
            <td colSpan="2">Design Life (DL) in years</td>
            <td colSpan="3">
              <input
                onChange={(e) => handleDesignChange(e)}
                value={dl}
                name="dl"
                type="text"
                placeholder="DL"
                className="flex rounded-lg gap-2  bg-gray-200 items-center p-2 font-roboto "
              />
            </td>
            <td colSpan="3"></td>
          </tr>
          <tr className="border">
            <td colSpan="2">Growth Rate (g) in percent</td>
            <td colSpan="3">
              <input
                onChange={(e) => handleDesignChange(e)}
                value={g}
                name="g"
                type="text"
                placeholder="%"
                className="flex rounded-lg gap-2  bg-gray-200 items-center p-2 font-roboto "
              />
            </td>
            <td colSpan="3"></td>
          </tr>
          <tr className="border">
            <td colSpan="2">Directional factor (DF)</td>
            <td colSpan="3">
              <input
                onChange={(e) => handleDesignChange(e)}
                value={df}
                name="df"
                type="text"
                placeholder="DF"
                className="flex rounded-lg gap-2  bg-gray-200 items-center p-2 font-roboto "
              />
            </td>
            <td colSpan="3"></td>
          </tr>
          <tr className="border">
            <td colspan="2">Lane factor (LF)</td>
            <td colSpan="3">
              <input
                onChange={(e) => handleDesignChange(e)}
                value={lf}
                name="lf"
                type="text"
                placeholder="LF"
                className="flex rounded-lg gap-2  bg-gray-200 items-center p-2 font-roboto "
              />
            </td>
            <td colSpan="3"></td>
          </tr> */}

          <tr className="border desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold border"
            >

              Design Life (DL) in years
              <input
                onChange={(e) => handleDesignChange(e)}
                value={dl}
                name="dl"
                type="text"
                placeholder="DL"
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"

              />
            </td>
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold border"
            >

              Growth Rate (g) in percent
              <input
                onChange={(e) => handleDesignChange(e)}
                value={g}
                name="g"
                type="text"
                placeholder="%"
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
              />
            </td>


          </tr>
          <tr className="border desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl ">
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold border"
            >
              Directional factor (DF)
              <input
                onChange={(e) => handleDesignChange(e)}
                value={df}
                name="df"
                type="text"
                placeholder="DF"

                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
              />
            </td>
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold border"
            >
              Lane factor (LF)
              <input
                onChange={(e) => handleDesignChange(e)}
                value={lf}
                name="lf"
                type="text"
                placeholder="LF"

                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
              />
            </td>

          </tr>


          <tr className="border text-center font-bold desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td className="border-t border-r">Sr/No</td>
            <td className="border-t border-r">Vehicle type</td>
            <td className="border-t border-r">Illustration</td>
            <td className="border-t border-r">Adt</td>
            <td className="border-t border-r">Growth Factor</td>
            <td className="border-t border-r">Design Traffic</td>
            <td className="border-t border-r">Esal Factor</td>
            <td className="border-t border-r">Desgin Esal</td>
          </tr>
          {/* <?php foreach ($model->traffic as $key => $traffic) { ?> */}
          {Object.entries(traffic).map(([key, entry]) => {
            console.log(key, entry);
            const total = adt[key]
              ? Object.values(adt[key]).reduce(
                (acc, item) => parseFloat(acc) + parseFloat(item),
                0
              )
              : 0;
            const counts = adt[key] ? Object.keys(adt[key]).length : 0;
            const average = total > 0 ? round(total / counts) : 0;
            const gf = round(
              growthFactor(parseFloat(adtEsal.g), parseFloat(adtEsal.dl)),
              3
            );
            const yearlyTraffic = average * gf * 365;
            const esalFactor = esalFactors[key];
            const designEsal = yearlyTraffic * esalFactor;
            commulativeEsal += designEsal;
            return (
              <tr key={key} className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
                <td className="py-3 px-4 border-b border-t border-r">{key}</td>
                <td
                  className="py-3 px-4 border-b border-t border-r"
                  style={{ minWidth: "150px" }}
                >
                  <label>{entry.label}</label>
                </td>
                <td
                  style={{ minWidth: "80px" }}
                  className="input-cell py-3 px-4 border-b border-t border-r"
                >
                  <img
                    style={{
                      transform:
                        key === "3" || key === "5" ? null : "scalex(-1)",
                    }}
                    src={entry.icon}
                  />
                </td>
                <td className="py-3 px-4 border-b border-t border-r">
                  <span className="average">{average}</span>
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  {gf}
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  {yearlyTraffic}
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  {round(esalFactor, 0)}
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  {round(designEsal, 0)}
                </td>
              </tr>
            );
          })}
          <tr key={"nothing"} className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td className="py-3 px-4 border-b border-t border-r"></td>
            <td className="py-3 px-4 border-b border-t border-r" colspan="6">
              Commulative Esals
            </td>
            <td
              style={{ maxWidth: "110px" }}
              className="input-cell border-b border-t border-r"
            >
              {round(commulativeEsal / 1000000, 6)} Million
            </td>
          </tr>
          {/* <? php } ?> */}

          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
          </tr>
          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
          </tr>
          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td colSpan="4" className="text-left">
              Collected By:
            </td>
            <td colSpan="4" className="text-left">
              Signature of concerned officer:
            </td>
          </tr>
        </tbody>
      </table>
    </BackendLayout>
  );
};

export default Esal;

import React, { useEffect, useReducer } from "react";
import BackendLayout from "../../components/BackendLayout";
import Heading from "../../components/Heading";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcumb";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  MRS_Years,
  NO_OF_LANES,
  SKEW_ANGLE,
  handleAddSegment,
  handleUpdateSegment,
} from "../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";

const SlabBridge = () => {
  const { projectId, segmentId } = useParams();
  const navigate = useNavigate();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      scourDepth: "",
      highFloodLevelAboveBedLevel: "",
      noOfLanes: "",
      spanLength: "",
      abutmentHeight: "",
      skewAngle: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );
  const dispatch = useDispatch();
  const created = useSelector((state) => state.project.created);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "SlabBridge",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "SlabBridge",
      projectId,
      //segmentInformation,
      segmentId

    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Slab-Bridge", itemLink: "" },
        ]}
      />

      <Heading item={"Slab Bridge Module"} className={"px-8 py-5"} />
      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Scour Depth (m)
              </label>

              <Input
                type="number"
                name="scourDepth"
                required
                className="text-base"
                placeholder="2.00"
                value={formState?.scourDepth}
                onChange={(e) =>
                  updateFormState({ scourDepth: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                High Flood Level above Bed Level (m)
              </label>
              <Input
                type="number"
                name="highFloodLevelAboveBedLevel"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                value={formState?.highFloodLevelAboveBedLevel}
                onChange={(e) =>
                  updateFormState({
                    highFloodLevelAboveBedLevel: e.target.value,
                  })
                }
              />
              {(parseInt(formState?.highFloodLevelAboveBedLevel) + parseInt(formState?.scourDepth) + 1) > 12 ? <span className="text-red-500">The Design is not applicable</span> : formState?.highFloodLevelAboveBedLevel && <span className="text-green-500">&#10004;</span>}

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                No. of Lanes - 1 or 2
              </label>

              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="No of Lanes"
                name="noOfLanes"
                required
                value={NO_OF_LANES.find(
                  (f) => f.value === formState?.noOfLanes
                )}
                options={NO_OF_LANES}
                onChange={(e) => updateFormState({ noOfLanes: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Span Length of Bridge (m) (6.1-12.0)
              </label>
              <Input
                type="number"
                name="spanLength"
                required
                className="text-lg "
                value={formState?.spanLength}
                placeholder={"6-12"}
                min={6}
                max={12}
                onChange={(e) =>
                  updateFormState({ spanLength: e.target.value })
                }
              />
              {(parseInt(formState?.spanLength) > 12) || (parseInt(formState?.spanLength) < 6) ? <span className="text-red-500">The value shall be greater than 6 and less than 12 </span> : formState?.spanLength && <span className="text-green-500">&#10004;</span>}

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Abutment Height from Bed Level (m) {`Type a value from ${isNaN(parseInt(formState?.highFloodLevelAboveBedLevel)) ? 0 : Math.max(2, parseInt(formState?.highFloodLevelAboveBedLevel) + 1)} to ${isNaN(parseInt(formState?.scourDepth)) ? 0 : 8 - parseInt(formState?.scourDepth)}`}
              </label>
              <Input
                type="number"
                name="abutmentHeight"
                required
                className="text-lg bg-white"
                placeholder={`Type a value from ${isNaN(parseInt(formState?.highFloodLevelAboveBedLevel)) ? 0 : Math.max(2, parseInt(formState?.highFloodLevelAboveBedLevel) + 1)} to ${isNaN(parseInt(formState?.scourDepth)) ? 0 : 8 - parseInt(formState?.scourDepth)}`}
                value={formState?.abutmentHeight}
                min={2}
                max={6}
                onChange={(e) =>
                  updateFormState({ abutmentHeight: e.target.value })
                }
              />

              {parseInt(formState?.abutmentHeight) < parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ?
                <span className="text-red-500">The height shall be greater than or equal to {parseInt(formState?.highFloodLevelAboveBedLevel) + 1}</span> :

                parseInt(formState?.abutmentHeight) > 8 - parseInt(formState?.scourDepth) ?
                  <span className="text-red-500">The height shall be less than or equal to {8 - parseInt(formState?.scourDepth)}</span> :

                  parseInt(formState?.abutmentHeight) < 2 ?
                    <span className="text-red-500">The height shall be greater than or equal to 2</span> :
                    formState?.abutmentHeight && <span className="text-green-500">&#10004;</span>
              }


            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Skew Angle (degree)
              </label>

              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Skew Angle"
                name="skewAngle"
                required
                value={SKEW_ANGLE.find((f) => f.value === formState?.skewAngle)}
                options={SKEW_ANGLE}
                onChange={(e) => updateFormState({ skewAngle: e.value })}
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                MRS Area/Location/Cost Factor
              </label>
              <Input
                type="number"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({
                    MRSAreaLocationCostFactor: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>
              {/* <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select MRS Year"
                name="year"
                required
                isSearchable={true}
                value={MRS_Years.find(
                  (city) => city.value === formState?.year
                )}
                options={MRS_Years}
                onChange={(e) => {
                  updateFormState({ year: e.value });
                }}
              /> */}
              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}

              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          {(parseInt(formState?.spanLength) > 12) || (parseInt(formState?.spanLength) < 6) ||
            parseInt(formState?.abutmentHeight) < parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ||
            parseInt(formState?.abutmentHeight) > 8 - parseInt(formState?.scourDepth) ||
            parseInt(formState?.abutmentHeight) < 2 ?
            <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} className={"opacity-20 cursor-not-allowed"} disabled type={"Submit"} /> : <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
          }
        </div>
      </form>
    </BackendLayout>
  );
};

export default SlabBridge;

import React, { useRef, useState } from "react";
import Button from "../Button";
import { BiIcons } from "../Icons";
import {
  CustSectionTableHeaders1,
  CutSectionTableHeaders2,
  round,
} from "../../helpers";
import CustomTableAndHeaders from "../CustomTableAndHeaders";
import Input from "../Input";
import Heading from "../Heading";
import RoadTableInputs from "../RoadTableInputs";
import { useDispatch, useSelector } from "react-redux";
import RoadStepAction from "./RoadStepAction";
import readXlsxFile from "read-excel-file";
import { Link, useParams } from "react-router-dom";

const CutSection = () => {
  const uploadRef = useRef(null);
  const dispatch = useDispatch();
  let { projectId, segmentId } = useParams();
  const cutObject = useSelector((state) => state.road?.cut);
  const cut = Object.entries(cutObject).map(([i, j]) => j);
  const totalVolumeAssigned = Object.values(cutObject).reduce(
    (acc, item) => acc + item.D,
    0
  );

  const { roadFormInputs } = useSelector((state) => state.road);
  const cutInputs = roadFormInputs?.cut;
  const totalVolume = cutInputs ? Object.values(cutInputs).reduce((acc, item) => acc + item.volume, 0) : 0;

  const addIcon = "BiAddToQueue";
  const deleteIcon = "BiSolidTrash";
  const downloadIcon = "BiArrowToBottom";
  const UploadIcon = "BiArrowToTop";

  const difference = totalVolume - totalVolumeAssigned;
  const onePercentThreshold = totalVolume * 0.01;

  const UploadFile = (e) => {
    uploadRef.current.click();
  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      readXlsxFile(e.target.files[0]).then((rows) => {
        let records = {};
        let i = 0;
        for (let key in rows) {
          const row = rows[key];
          if (key > 1 && row[4]) {
            records[i] = { start: row[0], end: row[2], area: row[4] };
            i++;
          }
        }
        recalculateAll(records);
      });
    }
  };

  const DownloadFile = (e) => {
    return console.log("I am pressed", e);
  };
  const handleChange = ({ target }, i) => {
    const { value, name } = target;
    const updatedState = {
      ...cutInputs,
      [i]: {
        ...cutInputs[i],
        [name]: parseFloat(value),
      },
    };
    recalculateAll(updatedState);
  };
  const handleRoadChange = ({ target }, i) => {
    const { value, name } = target;
    const updatedState = {
      ...cutInputs,
      [i]: {
        ...cutInputs[i],
        [name]: parseFloat(value),
      },
    };
    recalculateAll(updatedState);
  };
  const handleDuplicate = (i, j) => {
    const intI = parseInt(i);
    const shouldBeLength =
      cutInputs[intI]?.start * 1000 +
      cutInputs[intI]?.end -
      (cutInputs[intI - 1]?.start * 1000 + cutInputs[intI - 1]?.end);
    const temp = j?.end + cutInputs[intI]?.end - cutInputs[intI - 1]?.end;
    const currentEnd = j?.end == 0 ? shouldBeLength : shouldBeLength + j?.end;
    const start = parseFloat(
      currentEnd >= 1000 ? parseFloat(j.start) + 1 : j.start
    );
    const end = parseFloat(currentEnd >= 1000 ? currentEnd - 1000 : currentEnd);
    const updatedState = {
      ...cutInputs,
      [intI + 1]: {
        ...cutInputs[intI],
        start,
        end,
      },
    };
    recalculateAll(updatedState);
  };
  const recalculateAll = (updatedState) => {
    let totalVolume = 0;
    for (let i in Object.entries(updatedState)) {
      let averageArea = 0;
      let volume = 0;
      let previousKm = 0;
      let currentKm = updatedState[i].start;
      let previousM = 0;
      let currentM = updatedState[i]?.end;
      if (i > 0) {
        previousM = updatedState[i - 1].end;
        previousKm = updatedState[i - 1].start;
        currentM = updatedState[i]?.end;
        averageArea = round(
          (parseFloat(updatedState[i - 1].area) +
            parseFloat(updatedState[i].area)) /
          2,
          2
        );
        const currentLenth =
          currentKm * 1000 + currentM - (previousKm * 1000 + previousM);
        volume = round(currentLenth * averageArea, 2);
        totalVolume = volume + totalVolume;
      }
      updatedState[i] = {
        ...updatedState[i],
        start: isNaN(updatedState[i].start) ? 0 : updatedState[i].start,
        end: isNaN(updatedState[i]?.end) ? 0 : updatedState[i]?.end,
        averageArea,
        volume,
      };
    }
    dispatch({
      type: "road/setRoadFormInputs",
      payload: { name: "cut", ...updatedState },
    });
    // setState(updatedState);
    // setVolume(totalVolume)
  };
  const removeEntry = (i, j) => {
    if (i == 0) return; //don't remove last
    const updatedState = { ...cutInputs };
    delete updatedState[i];
    recalculateAll(updatedState);
  };
  const calculateLength = (i, j) => {
    let currentTotal = j.start * 1000 + j?.end;
    let previousTotal = cutInputs[i - 1]?.start * 1000 + cutInputs[i - 1]?.end;
    if (isNaN(currentTotal)) currentTotal = 0;
    if (isNaN(previousTotal)) previousTotal = 0;
    let len = currentTotal - previousTotal;
    if (i == 0) {
      return 0;
    }
    return len;
  };

  
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-row gap-4 px-6 py-4">
        <Button
          label={"Upload Btn"}
          iconStyle={"text-2xl"}
          icon={<BiIcons iconName={UploadIcon} />}
          onClick={() => {
            UploadFile("Upload");
          }}
        />
        <Link to="/area-input.xlsx" target="_blank" rel="noopener noreferrer">
          <Button
            label={"Download Btn"}
            iconStyle={"text-2xl"}
            icon={<BiIcons iconName={downloadIcon} />}
            onClick={() => {
              DownloadFile("download");
            }}
          />
        </Link>
        <input
          onChange={(e) => handleFileChange(e)}
          style={{ visibility: "hidden" }}
          type="file"
          ref={uploadRef}
        />
      </div>

      <div className="py-4 px-6">
        <CustomTableAndHeaders
          headers={CustSectionTableHeaders1}
          className={"border border-gray-300 rounded-t-lg "}
          ThStyle=" border:none  py-4 w-fit text-start px-4"
          TrStyle="bg-gray-200"
        >
          {Object.entries(cutInputs).map(([i, j]) => {
            const length = calculateLength(i, j);
            return (
              <tr
                key={`cut-${i}`}
                className=" text-center  border-b-2 border-gray-200"
              >
                <td className={"py-2 flex flex-row px-4 items-center "}>
                  <Input
                    type="text"
                    name="start"
                    value={j?.start}
                    required
                    className="text-sm  bg-green-50 text-primary  border-gray-200 border"
                    placeholder="Enter Value"
                    width="w-1/3"
                    onChange={(e) => handleRoadChange(e, i)}
                  />{" "}
                  +
                  <Input
                    type="text"
                    name="end"
                    value={j?.end}
                    required
                    className="text-sm  bg-green-50 text-primary  border-gray-200 border"
                    placeholder="Enter Value"
                    width="w-1/2"
                    onChange={(e) => handleRoadChange(e, i)}
                  />
                </td>
                <td className={"py-2 w-fit px-2 text-cyan-900"}>
                  <span>{length}</span>
                </td>

                <td className={"py-2 flex flex-row items-center px-2"}>
                  <Input
                    type="text"
                    name="area"
                    value={j?.area ? j?.area : ""}
                    required
                    className="text-sm w-fit bg-green-50 text-primary  border-gray-200 border"
                    placeholder="Enter Area"
                    width="w-1/2"
                    onChange={(e) => handleChange(e, i)}
                  />
                </td>
                <td className={"py-2 w-fit"}>
                  <span>{j.averageArea ? j.averageArea : 0}</span>
                </td>
                <td className={"py-2  w-fit"}>{length}</td>
                <td className={"py-2  w-fit"}>{j.volume ? j.volume : 0}</td>
                <td className={"py-2  w-fit"}>
                  <div className="flex flex-row items-center text-gray-400 w-full text-2xl ">
                    <div
                      className="hover:text-primary"
                      onClick={(e) => handleDuplicate(i, j)}
                    >
                      <BiIcons iconName={addIcon} />
                    </div>
                    <div
                      className="hover:text-primary"
                      onClick={(e) => removeEntry(i, j)}
                    >
                      <BiIcons iconName={deleteIcon} />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </CustomTableAndHeaders>
      </div>
      <div className="w-full flex flex-row items-center justify-end px-4 font-roboto">
        <p className="bg-green-50 py-2 px-4">
          Total volume Of cut (m<sup>3</sup>):{" "}
          <span className="font-bold w-fit text-end px-2 text-primary">
            {totalVolume ? totalVolume : 0}
          </span>
        </p>
      </div>

      <Heading item={"Proportion & Quantities"} className={"px-7"} />

      <div className="px-6">
        <CustomTableAndHeaders
          headers={CutSectionTableHeaders2}
          className={"border rounded-t-lg "}
          ThStyle=" border:none  py-4 w-fit text-start px-4"
          TrStyle="bg-gray-200"
        >
          <RoadTableInputs
            data={cut}
            onChange={(e) => {
              const { name, value } = e.target;
              dispatch({
                type: "road/updateQuantity",
                payload: {
                  name: "cut",
                  mrs_code: name,
                  C: parseFloat(value),
                  D: (value / 100) * totalVolume,
                },
              });
            }}
          />

          <tr className="py-4  border-b-2 border-gray-200 font-bold">
            <td className={"px-3 py-4 w-fit text-start"} colSpan={2}>
              Total
            </td>
            <td className={"px-3 py-4  w-fit"}>100</td>
            <td className={"px-3 py-4  w-fit"}>
              {round(totalVolumeAssigned, 2)} m<sup>3</sup>
            </td>
          </tr>
          <tr className="py-4  border-b-2 border-gray-200 font-bold">
            <td className={"px-3 py-4 w-fit text-start"} colSpan={3}>
              Difference (shall be less than 1%)
            </td>
            <td className={"px-3 py-4  w-fit"}>
              {isNaN(round(totalVolume - totalVolumeAssigned, 2) ? 0 : round(totalVolume - totalVolumeAssigned, 2))}
            </td>
          </tr>
        </CustomTableAndHeaders>
      </div>
      <div className="flex flex-row items-center justify-end py-4 px-5">


        <RoadStepAction
          projectId={projectId}
          disabled={Math.abs(difference) > onePercentThreshold || totalVolume === 0}
        />
        {/* <RoadStepAction projectId={projectId} disabled={totalVolume - totalVolumeAssigned > 1 || totalVolume - totalVolumeAssigned < -1 || totalVolume == 0} /> */}
      </div>
    </div>
  );
};

export default CutSection;

export const model = {
  selectedEsalBin: 0,
  selectedSubGrade: 0,
  selectedSubGradeCbr: 0,
  selectedSubBase: 0,
  selectedSubBaseCbr: 0,
  selectedBase: 0,
  selectedBaseCbr: 0,

  setSelectedEsalBin: function (index) {
    this.selectedEsalBin = index;
  },
  setSelectedSubGrade: function (index) {
    this.selectedSubGrade = index;
    this.selectedSubGradeCbr = null;
  },
  setSelectedSubGradeCbr: function (index) {
    this.selectedSubGrade = this.subgradeCBRRangeValues[index];
    this.selectedSubGradeCbr = index;
  },
  setSelectedSubBase: function (index) {
    this.selectedSubBase = index;
    this.selectedSubBaseCbr = null;
  },
  setSelectedSubBaseCbr: function (index) {
    this.selectedSubBaseCbr = index;
    this.selectedSubBase = this.subBaseCBRRangeValues[index];
  },
  setSelectedBase: function (index) {
    this.selectedBase = index;
    this.selectedBaseCbr = null;
  },
  setSelectedBaseCbr: function (index) {
    this.selectedBaseCbr = index;
    this.selectedBase = this.baseCBRRangeValue[index];
  },
  getSelectedEsalBin: function () {
    return this.selectedEsalBin;
  },
  getBinEsalCeiling: function (i) {
    return this.esalBinCellings(i);
  },
  esalBinSelection: function (esal) {
    const bins = this.esalBins;
    if (esal <= 50000) return 1;
    if (esal <= 150000) return 2;
    if (esal <= 500000) return 3;
    if (esal <= 2000000) return 4;
    if (esal <= 7000000) return 5;
    if (esal <= 10000000) return 6;
    return 0;
  },
  getBinLabelFromIndex: function (index) {
    return this.esalBins[index].label;
  },
  getBinRangeFromIndex: function (data, index) {
    return data[index]?.range;
  },

  esalBins: {
    0: { label: "None", value: undefined },
    1: {
      label: "Bin # 1 (Upto 0.05 Million ESALs)",
      value: 0.05,
      range: "0.000-0.05",
      ceilling: 50000,
    },
    2: {
      lable: "Bin # 2 (0.051-0.15 Million ESALs)",
      value: 0.051,
      range: "0.051-0.15",
      ceilling: 150000,
    },
    3: {
      label: "Bin # 3 (0.151-0.50 Million ESALs)",
      value: 0.151,
      range: "0.151-0.50",
      ceilling: 500000,
    },
    4: {
      label: "Bin # 4 (0.501-2.00 Million ESALs)",
      value: 0.501,
      range: "0.501-2.00",
      ceilling: 2000000,
    },
    5: {
      label: "Bin # 5 (2.001-7.00 Million ESALs)",
      value: 2.001,
      range: "2.001-7.00",
      ceilling: 7000000,
    },
    6: {
      label: "Bin # 6 (7.001-10.00 Million ESALs)",
      value: 7.001,
      range: "7.001-10.00",
      ceilling: 10000000,
    },
  },
  esalBinCellings: (index) => {
    const bin = {
      1: 50000,
      2: 150000,
      3: 500000,
      4: 2000000,
      5: 7000000,
      6: 10000000,
    };
    return bin[index];
  },

  binFromRange: (index, range) => {
    let foundIndex = undefined;
    let previousIndex = 1;
    Object.entries(range).map((i) => {
      const j = parseInt(parseInt(i[0]));
      if (j > index && foundIndex === undefined) {
        foundIndex = previousIndex;
      } else {
        previousIndex = j;
      }
    });
    return foundIndex;
  },

  subgradeCBRRange: {
    0: "Not Applicable <1",
    1: {
      label: "Bin # 7(1 - 3.999 %) / (1500 - 5999 psi)",
      range: "1-3.999",
    },
    4: {
      label: "Bin # 6(4 - 6.999 %) / (6000 - 10499 psi)",
      range: "4-6.999",
    },
    7: {
      label: "Bin # 5(7 - 9.999 %) / (10500 - 14999 psi)",
      range: "7-9.999",
    },
    10: {
      label: "Bin # 4(10 - 25.998 %) / (15000 - 20557 psi)",
      range: "10-25.998",
    },
    26: {
      label: "Bin # 3(26 - 45.998 %) / (20558 - 29617 psi)",
      range: "26-45.998",
    },
    46: {
      label: "Bin # 2(46 - 70.998 %) / (29618 - 39101 psi)",
      range: "46-70.998",
    },
    71: {
      label: "Bin # 1(71 - 100 %) / (39102 - 48685 psi)",
      range: "71-100",
    },
    101: {
      label: "Bin # 1(71 - 100 %) / (39102 - 48685 psi)",
      range: "71-100",
    },
  },
  subgradeCBRRangeValues: {
    1: 1500,
    4: 6000,
    7: 10500,
    10: 15000,
    26: 20558,
    46: 29618,
    71: 39102,
    101: 39102,
  },
  subgradeMrRange: {
    0: { label: "Not Applicable < 1500" },
    1500: {
      label: "Bin # 7(1 - 3.999 %) / (1500 - 5999 psi)",
      range: "1-3.999",
    },
    6000: {
      label: "Bin # 6(4 - 6.999 %) / (6000 - 10499 psi)",
      range: "4-6.999",
    },
    10500: {
      label: "Bin # 5(7 - 9.999 %) / (10500 - 14999 psi)",
      range: "7-9.999",
    },
    15000: {
      label: "Bin # 4(10 - 25.998 %) / (15000 - 20557 psi)",
      range: "10-25.998",
    },
    20558: {
      label: "Bin # 3(26 - 45.998 %) / (20558 - 29617 psi)",
      range: "26-45.998",
    },
    29618: {
      label: "Bin # 2(46 - 70.998 %) / (29618 - 39101 psi)",
      range: "46-70.998",
    },
    39102: {
      label: "Bin # 1(71 - 100 %) / (39102 - 48685 psi)",
      range: "71-100",
    },
    48686: {
      label: "Not Applicable > 48685",
    },
  },

  subBaseCBRRange: {
    0: { label: "Not Applicable for <30" },
    30: {
      label: "Bin # 7(30 - 34.99 %) / (15000 - 15999 psi)",
      range: "30-34.99",
    },
    35: {
      label: "Bin # 6(35 - 39.99 %) / (16000 - 16999 psi)",
      range: "35-39.99",
    },
    40: {
      label: "Bin # 5(40 - 49.99 %) / (17000 - 17999 psi)",
      range: "40-49.99",
    },
    50: {
      label: "Bin # 4(50 - 69.99 %) / (18000 - 18999 psi)",
      range: "50-69.99",
    },
    70: {
      label: "Bin # 3(70 - 84.999 %) / (19000 - 19999 psi)",
      range: "70-84.999",
    },
    85: {
      label: "Bin # 2(85 - 99.99 %) / (20000 - 20999 psi)",
      range: "85-99.99",
    },
    100: {
      label: "Bin # 1(100 %) / (21000 - 45000 psi)",
      range: "100",
    },
    101: "Not Applicable >100",
  },
  subBaseCBRRangeValues: {
    30: 15000,
    35: 16000,
    40: 17000,
    50: 18000,
    70: 19000,
    85: 20000,
    100: 21000,
  },

  subBaseEbsRange: {
    0: { label: "Not Applicable for <15000" },
    15000: {
      label: "Bin # 7(30 - 34.99 %) / (15000 - 15999 psi)",
      range: "30-34.99",
    },
    16000: {
      label: "Bin # 6(35 - 39.99 %) / (16000 - 16999 psi)",
      range: "35-39.99",
    },
    17000: {
      label: "Bin # 5(40 - 49.99 %) / (17000 - 17999 psi)",
      range: "40-49.99",
    },
    18000: {
      label: "Bin # 4(50 - 69.99 %) / (18000 - 18999 psi)",
      range: "50-69.99",
    },
    19000: {
      label: "Bin # 3(70 - 84.999 %) / (19000 - 19999 psi)",
      range: "70-84.999",
    },
    20000: {
      label: "Bin # 2(85 - 99.99 %) / (20000 - 20999 psi)",
      range: "85-99.99",
    },
    21000: {
      label: "Bin # 1(100 %) / (21000 - 45000 psi)",
      range: "100",
    },
    45001: {
      label: "Not Applicable",
    },
  },
  baseCBRRange: {
    0: {
      label: "Not Applicable for <80",
      range: "",
    },
    80: {
      label: "Bin # 3(80 - 89.99 %) / (28500 - 29249 psi)",
      range: "80-89.99",
    },
    90: {
      label: "Bin # 2(90 - 99.99 %) / (29250 - 29999 psi)",
      range: "90-99.99",
    },
    100: {
      label: "Bin # 1(100 %) / (30000 - 45000 psi)",
      range: "100",
    },
    101: {
      label: "Not Applicable",
    },
  },
  baseCBRRangeValue: {
    80: 28500,
    90: 29250,
    100: 30000,
  },
  baseEBSRange: {
    0: { label: "Not Applicable < 28500" },
    28500: {
      label: "Bin # 3(80 - 89.99 %) / (28500 - 29249 psi)",
      range: "80-89.99",
    },
    29250: {
      label: "Bin # 2(90 - 99.99 %) / (29250 - 29999 psi)",
      range: "90-99.99",
    },
    30000: {
      label: "Bin # 1(100 %) / (30000 - 45000 psi)",
      range: "100",
    },
    45001: { label: "Not Applicable" },
  },

  layerCoeffAcCourse: (value) => {
    let coeff = 0;
    let keys = {
      417000: 0.43,
      435000: 0.44,
      445000: 0.44,
    };
    if (value < 417000 || value > 445000) return 0;
    Object.keys(keys).map((i) => {
      if (value <= parseInt(i) && coeff === 0) {
        coeff = keys[i];
      }
    });
    return coeff;
  },
  layerCoeffSubBaseCourse: (value) => {
    let coeff = 0;
    let keys = {
      15000: 0.11,
      16000: 0.115,
      17000: 0.12,
      18000: 0.125,
      19000: 0.13,
      20000: 0.135,
      21000: 0.14,
      45000: 0.14,
    };
    if (value < 15000) return 0;
    if (value > 45000) return 0.14;
    Object.keys(keys).map((i) => {
      if (value <= parseInt(i) && coeff === 0) {
        coeff = keys[i];
      }
    });
    return coeff;
  },

  layerCoeffBaseCourse: (value) => {
    let coeff = 0;
    let keys = {
      28500: 0.13,
      29250: 0.135,
      30000: 0.14,
      45000: 0.14,
    };
    if (value < 28500 || value > 45000) return 0;
    Object.keys(keys).map((i) => {
      if (value <= parseInt(i) && coeff === 0) {
        coeff = keys[i];
      }
    });
    return coeff;
  },

  thickness: (value) => {
    let coeff = 0;
    const keys = {
      0: { ac: 1, base: 4 },
      50001: { ac: 2, base: 4 },
      150001: { ac: 2.5, base: 4 },
      500001: { ac: 3, base: 6 },
      2000001: { ac: 3.5, base: 6 },
      7000001: { ac: 4, base: 6 },
      10000000: { ac: 4, base: 6 },
    };
    let prevI = 0;
    Object.keys(keys).map((i) => {
      if (value <= parseInt(i) && coeff === 0) {
        coeff = keys[prevI];
      }
      prevI = i;
    });
    // debugger;
    console.log("388 🚀 ~ coeff:", coeff, "value:", value);
    return coeff;
  },
  defaultThickness: {
    ac: 3.5,
    base: 12,
    subBase: 39.5,
  },
  setDefaultThiness: function ({ ac, base, subBase }) {
    if (ac) this.defaultThickness.ac = ac;
    if (base) this.defaultThickness.base = base;
    if (subBase) this.defaultThickness.subBase = subBase;
  },
};

//Road Module Calculate Total SN,Cost
// const calcDesignOutput = ({ final = false }) => {

//     const binCeiling = model.getBinEsalCeiling();
//     const thickness = model.thickness(binCeiling);
//     const finalClass = final ? '.final' : '.first';

//     //inches thickness
//     const acThickness = final ? $('#roaddesignoutput-finalacthicknessinches').val() : thickness.ac;
//     const abThickness = final ? $('#roaddesignoutput-finalabthicknessinches').val() : thickness.base;
//     const firstThickness = $(`#roaddesignoutput-asbthicknessinches`).val();
//     const finalThickness = $(`#roaddesignoutput-finalasbthicknessinches`).val();
//     const asbThickness = final ? finalThickness : firstThickness;

//     //meter thickness
//     const actm = round(parseFloat(acThickness) * 0.0254, 3);
//     $(`#road-design-output-form  ${finalClass} .actm`).html(actm);

//     const abtm = round(parseFloat(abThickness) * 0.0254, 3);
//     $(`#road-design-output-form  ${finalClass} .abtm`).html(abtm);

//     const asbtm = round(parseFloat(asbThickness) * 0.0254, 3);
//     $(`#road-design-output-form ${finalClass}  .asbtm`).html(asbtm);

//     const w1 = 3.7;
//     const w2 = parseFloat(w1 + actm, 3);
//     const w3 = parseFloat(w2 + abtm, 3);
//     const w4 = parseFloat(w3 + asbtm * 2);
//     //volume
//     const actv = ((w1 + w2) / 2) * parseFloat(actm);
//     $(`#road-design-output-form ${finalClass} .actv`).html(round(actv, 4));
//     const abtv = ((w2 + w3) / 2) * parseFloat(abtm);
//     $(`#road-design-output-form ${finalClass} .abtv`).html(round(abtv, 3));
//     const asbtv = ((w3 + w4) / 2) * parseFloat(asbtm);
//     $(`#road-design-output-form ${finalClass} .asbtv`).html(round(asbtv, 3));
//     //price
//     const actp = parseFloat($(`#road-design-output-form ${finalClass} .actp`).html());
//     const abtp = parseFloat($(`#road-design-output-form ${finalClass} .baseCost`).val());
//     $(`#road-design-output-form ${finalClass} .abtp`).html(abtp)
//     //parseFloat($(`#road-design-output-form ${finalClass} .abtp`).html());
//     const asbtp = parseFloat($(`#road-design-output-form ${finalClass} .subBaseCost`).val());
//     $(`#road-design-output-form ${finalClass} .asbtp`).html(asbtp)
//     //parseFloat($(`#road-design-output-form ${finalClass} .asbtp`).html());

//     const actppkm = actp * actv * 1000;
//     $(`#road-design-output-form ${finalClass} .actppkm`).html(round(actppkm));
//     const abtppkm = abtp * abtv * 1000;
//     $(`#road-design-output-form ${finalClass} .abtppkm`).html(round(abtppkm));
//     const asbtppkm = asbtp * asbtv * 1000;
//     $(`#road-design-output-form  ${finalClass} .asbtppkm`).html(round(asbtppkm));
//     //sn
//     const dma1 = parseFloat($(`#road-design-process-form .dma1`).html());
//     const dma2 = parseFloat($(`#road-design-process-form .dma2`).html());
//     const dma3 = parseFloat($(`#road-design-process-form .dma3`).html());
//     const actsn = round(dma1 * acThickness, 2);
//     const abtsn = round(dma2 * abThickness, 2);
//     const asbtsn = round(dma3 * asbThickness, 2);
//     $(`#road-design-output-form ${finalClass} .actsn`).html(actsn);
//     $(`#road-design-output-form ${finalClass} .abtsn`).html(abtsn);
//     $(`#road-design-output-form ${finalClass} .asbtsn`).html(asbtsn);

//     const dmsn = parseFloat($(`#road-design-process-form .dmsn`).html())
//     $(`#road-design-output-form ${finalClass} .totalsn`).html(dmsn);
//     const providedSn = round(actsn + abtsn + asbtsn, 2);
//     $(`#road-design-output-form  ${finalClass} .providedSn`).html(providedSn);
//     if (dmsn > providedSn) {
//         $(`#road-design-output-form ${finalClass} .dsn1`).html('SN provided < SN required (Not Acceptable)');
//         $(`#road-design-output-form ${finalClass} .dsn1`).removeClass(`accepted-cell`);
//         $(`#road-design-output-form ${finalClass} .dsn1`).addClass(`danger-cell`);
//     } else {
//         $(`#road-design-output-form ${finalClass} .dsn1`).html('SN provided > SN required (Acceptable)');
//         $(`#road-design-output-form ${finalClass} .dsn1`).addClass(`accepted-cell`);
//         $(`#road-design-output-form ${finalClass} .dsn1`).removeClass(`danger-cell`);
//     }
//     //cost
//     const totalCost = round(parseFloat(actppkm)) + round(parseFloat(abtppkm)) + round(parseFloat(asbtppkm));
//     $(`#road-design-output-form  ${finalClass} .totalCost`).html(round(totalCost));
//     if (final) {
//         const firstActi = parseFloat($('#road-design-output-form .first .acti').html());
//         const firstAbti = parseFloat($('#road-design-output-form .first .abti').html());
//         const finalActi = parseFloat($('#road-design-output-form .final .acti input').val());
//         const finalAbti = parseFloat($('#road-design-output-form .final .abti input').val());
//         if (firstActi > finalActi) {
//             $('#road-design-output-form .final .acti').closest('td').addClass('danger-cell');
//         } else {
//             $('#road-design-output-form .final .acti').closest('td').removeClass('danger-cell');
//         }
//         if (firstAbti > finalAbti) {
//             $('#road-design-output-form .final .abti').closest('td').addClass('danger-cell');
//         } else {
//             $('#road-design-output-form .final .abti').closest('td').removeClass('danger-cell');
//         }
//     }
// }


export const sumKValues = function (bill_estimation) {
  let sum = 0;
  for (const key in bill_estimation) {
    if (bill_estimation.hasOwnProperty(key)) {
      const item = bill_estimation[key];

      if (item.K > 0) {
        sum = sum + parseFloat(item.K);
      }
    }
  }
  return parseFloat(sum);
};

export const transformArrayToObject = (array) => {
  const transformedObject = {};

  array.forEach((item) => {
    const key = item.A;
    if (key) {
      transformedObject[key] = item;
      // delete transformedObject[key].inputFields;
    }
  });

  return transformedObject;
};

export const SOIL_Type = [
  {
    label: "Granular Soil",
    value: "C",
  },
  {
    label: "Clayey Soil",
    value: "D",
  },
];

export const FOUNDATION_TYPE = [
  {
    label: "Deep",
    value: "Deep",
  },
  {
    label: "Shallow",
    value: "Shallow",
  },
];

export const SKEW_ANGLE = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "30",
    value: "30",
  },
];
export const NO_OF_LANES = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
];

export const NO_OF_SPAN = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
];

export const Abutment_Material = [
  { label: "Brick Masonry", value: "Brick Masonry" },
  { label: "Stone Masonry", value: "Stone Masonry" },
];

export const Wall_Material = [
  {
    label: "Plum Concrete",
    value: "Plum Concrete",
  },
  {
    label: "Stone Masonry",
    value: "Stone Masonry",
  },
];

export const STATUS_CODE = {
  ERR_NETWORK: "ERR_NETWORK",
  ERR_BAD_RESPONSE: "ERR_BAD_RESPONSE",
  TypeError: "TypeError",
};

export const segmentTypes = [
  "t-beam-bridges",
  "slab-bridges",
  "box-culverts",
  "counterfort-retaining-walls",
  "cantilever-retaining-walls",
  "gravity-retaining-wall-sloping-inwards",
  "gravity-retaining-wall-sloping-outwards",
  "closed-causeways",
  "vented-causeways",
  "slab-culverts",
  "road",
];

export const updatedSegmentTypes = [
  { label: "T-Beam Bridges", value: "t-beam-bridges" },
  { label: "Slab Bridges", value: "slab-bridges" },
  { label: "Box Culverts", value: "box-culverts" },
  {
    label: "Counterfort Retaining Walls",
    value: "counterfort-retaining-walls",
  },
  { label: "Cantilever Retaining Walls", value: "cantilever-retaining-walls" },
  {
    label: "Gravity Retaining Wall Sloping Inwards",
    value: "gravity-retaining-wall-sloping-inwards",
  },
  {
    label: "Gravity Retaining Wall Sloping Outwards",
    value: "gravity-retaining-wall-sloping-outwards",
  },
  { label: "Closed Causeways", value: "closed-causeways" },
  { label: "Vented Causeways", value: "vented-causeways" },
  { label: "Slab Culverts", value: "slab-culverts" },
  { label: "Road", value: "road" },
];

export const projectLabels = [
  "Project Title",
  "MRS Year",
  "Location",
  "PSDP/ADP No",
  "Length of road (km)",
  "Actions",
];

export const segmentLabels = [
  "Segment Title",
  "Segment Category",
  "RD",
  "Creation Date",
  "Actions",
];

export const Cities = [
  "Abbottabad",
  "Bajaur",
  "Balakot",
  "Bannu",
  "Batagram",
  "Buner/Daggar",
  "Chitral Lower",
  "Charsadda",
  "DI Khan/Kulachi, Paharpur",
  "Dir",
  "Hangu",
  "Chitral Upper",
  "Dassu",
  "Dera Ismail Khan",
  "DI Khan/Daraban",
  "Haripur",
  "Karak",
  "Khyber/ Bara, Jamru",
  "Khyber/ Landi Kotal, Mulagor",
  "Kohat",
  "Kurram",
  "Lakki Marwat",
  "Malakand/Swat Ranizai",
  "Malakand/Swat, Sam Ranizai",
  "Mansehra",
  "Mardan",
  "Mohmand",
  "North Wazirista",
  "Nowshera",
  "Oghi",
  "Orakza",
  "Peshawar",
  "Shangl",
  "Shangla/ Pura",
  "South Wazirista",
  "Swab",
  "Swat",
  "T.A.Adj. Mansehra Distt",
  "Tangi",
  "Tank",
];

export const AllCitiesListInPakistan = [
  { label: "Abbottabad", value: "Abbottabad" },
  { label: "Astore", value: "Astore" },
  { label: "Attock", value: "Attock" },
  { label: "Awaran", value: "Awaran" },
  { label: "Badin", value: "Badin" },
  { label: "Bagh", value: "Bagh" },
  { label: "Bahawalnagar", value: "Bahawalnagar" },
  { label: "Bahawalpur", value: "Bahawalpur" },
  { label: "Bajaur", value: "Bajaur" },
  { label: "Bannu", value: "Bannu" },
  { label: "Barkhan", value: "Barkhan" },
  { label: "Batagram", value: "Batagram" },
  { label: "Bhakkar", value: "Bhakkar" },
  { label: "Bhimber", value: "Bhimber" },
  { label: "Buner", value: "Buner" },
  { label: "Chagai", value: "Chagai" },
  { label: "Chakwal", value: "Chakwal" },
  { label: "Charsadda", value: "Charsadda" },
  { label: "Chiniot", value: "Chiniot" },
  { label: "Dadu", value: "Dadu" },
  { label: "Darel", value: "Darel" },
  { label: "Dera Bugti", value: "Dera Bugti" },
  { label: "Dera Ghazi Khan", value: "Dera Ghazi Khan" },
  { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
  { label: "Diamer", value: "Diamer" },
  { label: "Duki", value: "Duki" },
  { label: "Faisalabad", value: "Faisalabad" },
  { label: "Ghanche", value: "Ghanche" },
  { label: "Ghizer", value: "Ghizer" },
  { label: "Ghotki", value: "Ghotki" },
  { label: "Gilgit", value: "Gilgit" },
  { label: "Gujranwala", value: "Gujranwala" },
  { label: "Gujrat", value: "Gujrat" },
  { label: "Gupis Yasin", value: "Gupis Yasin" },
  { label: "Gwadar", value: "Gwadar" },
  { label: "Hafizabad", value: "Hafizabad" },
  { label: "Hangu", value: "Hangu" },
  { label: "Haripur", value: "Haripur" },
  { label: "Harnai", value: "Harnai" },
  { label: "Hattian", value: "Hattian" },
  { label: "Haveli", value: "Haveli" },
  { label: "Hunza", value: "Hunza" },
  { label: "Hyderabad", value: "Hyderabad" },
  { label: "Islamabad", value: "Islamabad" },
  { label: "Jacobabad", value: "Jacobabad" },
  { label: "Jafarabad", value: "Jafarabad" },
  { label: "Jamshoro", value: "Jamshoro" },
  { label: "Jhal Magsi", value: "Jhal Magsi" },
  { label: "Jhang", value: "Jhang" },
  { label: "Jhelum", value: "Jhelum" },
  { label: "Kachhi", value: "Kachhi" },
  { label: "Kalat", value: "Kalat" },
  { label: "Karachi Central", value: "Karachi Central" },
  { label: "Karachi East", value: "Karachi East" },
  { label: "Karachi South", value: "Karachi South" },
  { label: "Karachi West", value: "Karachi West" },
  { label: "Karak", value: "Karak" },
  { label: "Kashmore", value: "Kashmore" },
  { label: "Kasur", value: "Kasur" },
  { label: "Kech", value: "Kech" },
  { label: "Khairpur", value: "Khairpur" },
  { label: "Khanewal", value: "Khanewal" },
  { label: "Kharan", value: "Kharan" },
  { label: "Kharmang", value: "Kharmang" },
  { label: "Khushab", value: "Khushab" },
  { label: "Khuzdar", value: "Khuzdar" },
  { label: "Khyber", value: "Khyber" },
  { label: "Killa Abdullah", value: "Killa Abdullah" },
  { label: "Kohat", value: "Kohat" },
  { label: "Kohlu", value: "Kohlu" },
  { label: "Kolai Pallas", value: "Kolai Pallas" },
  { label: "Korangi", value: "Korangi" },
  { label: "Kotli", value: "Kotli" },
  { label: "Kurram", value: "Kurram" },
  { label: "Lahore", value: "Lahore" },
  { label: "Lakki Marwat", value: "Lakki Marwat" },
  { label: "Larkana", value: "Larkana" },
  { label: "Lasbela", value: "Lasbela" },
  { label: "Layyah", value: "Layyah" },
  { label: "Lodhran", value: "Lodhran" },
  { label: "Loralai", value: "Loralai" },
  { label: "Lower Chitral", value: "Lower Chitral" },
  { label: "Lower Dir", value: "Lower Dir" },
  { label: "Lower Kohistan", value: "Lower Kohistan" },
  { label: "Malakand", value: "Malakand" },
  { label: "Malir", value: "Malir" },
  { label: "Mandi Bahauddin", value: "Mandi Bahauddin" },
  { label: "Mansehra", value: "Mansehra" },
  { label: "Mardan", value: "Mardan" },
  { label: "Mastung", value: "Mastung" },
  { label: "Matiari", value: "Matiari" },
  { label: "Mianwali", value: "Mianwali" },
  { label: "Mirpur Khas", value: "Mirpur Khas" },
  { label: "Mirpur", value: "Mirpur" },
  { label: "Mohmand", value: "Mohmand" },
  { label: "Multan", value: "Multan" },
  { label: "Musakhel", value: "Musakhel" },
  { label: "Muzaffarabad", value: "Muzaffarabad" },
  { label: "Muzaffargarh", value: "Muzaffargarh" },
  { label: "Nagar", value: "Nagar" },
  { label: "Nankana Sahib", value: "Nankana Sahib" },
  { label: "Narowal", value: "Narowal" },
  { label: "Naseerabad", value: "Naseerabad" },
  { label: "Naushahro Firoze", value: "Naushahro Firoze" },
  { label: "Neelum", value: "Neelum" },
  { label: "North Waziristan", value: "North Waziristan" },
  { label: "Nowshera", value: "Nowshera" },
  { label: "Nushki", value: "Nushki" },
  { label: "Okara", value: "Okara" },
  { label: "Orakzai", value: "Orakzai" },
  { label: "Pakpattan", value: "Pakpattan" },
  { label: "Panjgur", value: "Panjgur" },
  { label: "Peshawar", value: "Peshawar" },
  { label: "Pishin", value: "Pishin" },
  { label: "Poonch", value: "Poonch" },
  { label: "Qambar Shahdadkot", value: "Qambar Shahdadkot" },
  { label: "Qilla Saifullah", value: "Qilla Saifullah" },
  { label: "Quetta", value: "Quetta" },
  { label: "Rahim Yar Khan", value: "Rahim Yar Khan" },
  { label: "Rajanpur", value: "Rajanpur" },
  { label: "Rawalpindi", value: "Rawalpindi" },
  { label: "Roundu", value: "Roundu" },
  { label: "Sahiwal", value: "Sahiwal" },
  { label: "Sanghar", value: "Sanghar" },
  { label: "Sargodha", value: "Sargodha" },
  { label: "Shaheed Benazirabad", value: "Shaheed Benazirabad" },
  { label: "Shaheed Sikandarabad", value: "Shaheed Sikandarabad" },
  { label: "Shangla", value: "Shangla" },
  { label: "Sheikhupura", value: "Sheikhupura" },
  { label: "Sherani", value: "Sherani" },
  { label: "Shigar", value: "Shigar" },
  { label: "Shikarpur", value: "Shikarpur" },
  { label: "Sialkot", value: "Sialkot" },
  { label: "Sibi", value: "Sibi" },
  { label: "Skardu", value: "Skardu" },
  { label: "Sohbatpur", value: "Sohbatpur" },
  { label: "South Waziristan", value: "South Waziristan" },
  { label: "Sudhnutti", value: "Sudhnutti" },
  { label: "Sujawal", value: "Sujawal" },
  { label: "Sukkur", value: "Sukkur" },
  { label: "Swabi", value: "Swabi" },
  { label: "Swat", value: "Swat" },
  { label: "Tando Allahyar", value: "Tando Allahyar" },
  { label: "Tando Muhammad Khan", value: "Tando Muhammad Khan" },
  { label: "Tangir", value: "Tangir" },
  { label: "Tank", value: "Tank" },
  { label: "Tharparkar", value: "Tharparkar" },
  { label: "Thatta", value: "Thatta" },
  { label: "Toba Tek Singh", value: "Toba Tek Singh" },
  { label: "Tor Ghar", value: "Tor Ghar" },
  { label: "Umerkot", value: "Umerkot" },
  { label: "Upper Chitral", value: "Upper Chitral" },
  { label: "Upper Dir", value: "Upper Dir" },
  { label: "Upper Kohistan", value: "Upper Kohistan" },
  { label: "Vehari", value: "Vehari" },
  { label: "Washuk", value: "Washuk" },
  { label: "Zhob", value: "Zhob" },
  { label: "Ziarat", value: "Ziarat" },
];

export const CitiesListOfKPK = [
  { label: "Abbottabad", value: "Abbottabad" },
  { label: "Bajaur", value: "Bajaur" },
  { label: "Balakot", value: "Balakot" },
  { label: "Bannu", value: "Bannu" },
  { label: "Batagram", value: "Batagram" },
  { label: "Buner/Daggar", value: "Buner/Daggar" },
  { label: "Chitral Lower", value: "Chitral Lower" },
  { label: "Charsadda", value: "Charsadda" },
  { label: "DI Khan/Kulachi, Paharpur", value: "DI Khan/Kulachi, Paharpur" },
  { label: "Dir", value: "Dir" },
  { label: "Hangu", value: "Hangu" },
  { label: "Chitral Upper", value: "Chitral Upper" },
  { label: "Dassu", value: "Dassu" },
  { label: "Dera Ismail Khan", value: "Dera Ismail Khan" },
  { label: "DI Khan/Daraban", value: "DI Khan/Daraban" },
  { label: "Haripur", value: "Haripur" },
  { label: "Karak", value: "Karak" },
  { label: "Khyber/ Bara, Jamru", value: "Khyber/ Bara, Jamru" },
  {
    label: "Khyber/ Landi Kotal, Mulagor",
    value: "Khyber/ Landi Kotal, Mulagor",
  },
  { label: "Kohat", value: "Kohat" },
  { label: "Kurram", value: "Kurram" },
  { label: "Lakki Marwat", value: "Lakki Marwat" },
  { label: "Malakand/Swat Ranizai", value: "Malakand/Swat Ranizai" },
  { label: "Malakand/Swat, Sam Ranizai", value: "Malakand/Swat, Sam Ranizai" },
  { label: "Mansehra", value: "Mansehra" },
  { label: "Mardan", value: "Mardan" },
  { label: "Mohmand", value: "Mohmand" },
  { label: "North Waziristan", value: "North Waziristan" },
  { label: "Nowshera", value: "Nowshera" },
  { label: "Oghi", value: "Oghi" },
  { label: "Orakzai", value: "Orakzai" },
  { label: "Peshawar", value: "Peshawar" },
  { label: "Shangla", value: "Shangla" },
  { label: "Shangla/ Pura", value: "Shangla/ Pura" },
  { label: "South Waziristan", value: "South Waziristan" },
  { label: "Swabi", value: "Swabi" },
  { label: "Swat", value: "Swat" },
  { label: "T.A.Adj. Mansehra Distt", value: "T.A.Adj. Mansehra Distt" },
  { label: "Tangi", value: "Tangi" },
  { label: "Tank", value: "Tank" },
];

export const Road_Module_Section_Headers = [
  "Layer",
  "Thickness",
  "Layer-wise cost/km",
  "Provided SN",
  "Req'd Design SN",
];

export const TableHeaders1 = [
  "RD",
  "Length",
  "Width",
  "Average Width",
  "Area",
  "Action",
];

export const TableHeaders2 = [
  "Item Code",
  "Description",
  "Proportion(%)",
  "Quantity(m^2)",
];

export const CustSectionTableHeaders1 = [
  "RD",
  "Length",
  "X-sectional Area",
  "Average Area",
  "Length b/w sections",
  "Volume",
  "Action",
];

export const CutSectionTableHeaders2 = [
  "Item Code",
  "Description",
  "Proportion(%)",
  "Volume(m^3)",
];

export const WeekDays = [
  {
    label: "Monday",
    value: "Monday",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
  },
  {
    label: "Thursday",
    value: "Thursday",
  },
  {
    label: "Friday",
    value: "Friday",
  },
  {
    label: "Saturday",
    value: "Saturday",
  },
  {
    label: "Sunday",
    value: "Sunday",
  },
];

export const MRS_Years = [
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
];
//Sunny / Partial cloudy / cloudy / Rain
export const WeatherCondition = [
  {
    label: "Sunny",
    value: "Sunny",
  },
  {
    label: "Partial Cloudy",
    value: "Partial Cloudy",
  },
  {
    label: "Cloudy",
    value: "Cloudy",
  },
  {
    label: "Rain",
    value: "Rain",
  },
];

// "2.0TSF", "1.0TSF", "1.5TSF"
export const bearingCapacity = [
  {
    label: "2.0TSF",
    value: "2.0TSF",
  },
  {
    label: "1.0TSF",
    value: "1.0TSF",
  },
  {
    label: "1.5TSF",
    value: "1.5TSF",
  },
];

export const VentedCausewayPipeDiameters = [
  {
    label: "600",
    value: "600",
  },
  {
    label: "900",
    value: "900",
  },
];

export const totalHeightFoundationDepths = [
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
];

export const getInitialsFromName = (userInfo) => {
  const name = userInfo?.name;
  if (name === undefined) {
    return "-";
  }
  return name
    .split(" ")
    .map((word) => word.slice(0, 1).toUpperCase())
    .join("")
    .slice(0, 3);
};
export const handleAddSegment = (
  e,
  dispatch,
  { module, projectId, segmentInformation }
) => {
  e.preventDefault();
  const formData = new FormData(e.target);

  const payloadData = {
    project_id: projectId,
    ...segmentInformation,
    [module]: {},
    //...Object.fromEntries(formData.entries()), // Convert FormData to object
  };
  for (const [name, value] of formData.entries()) {
    payloadData[module][name] = value;
  }
  dispatch({
    type: "project/addSegment",
    payload: { url: `/segments`, ...payloadData },
  });
  // return console.log(payloadData);

  // dispatch({ type: "project/deleteProject", payload: { url: `/projects/`, projectId } });
};

export const handleUpdateSegment = (
  e,
  dispatch,
  { module, projectId, segmentInformation, segmentId }) => {

  e.preventDefault();
  const formData = new FormData(e.target);

  const payloadData = {
    project_id: projectId,
    ...segmentInformation,
    [module]: {},
    //...Object.fromEntries(formData.entries()), // Convert FormData to object
  };

  for (const [name, value] of formData.entries()) {
    payloadData[module][name] = value;
  }
  dispatch({
    type: "project/updateSegment",
    payload: { url: `/segments/${segmentId}`, ...payloadData },
  });
  // return console.log(payloadData);

  // dispatch({ type: "project/deleteProject", payload: { url: `/projects/`, projectId } });
};

export const handleAddRoadSegment = (
  e,
  dispatch,
  { module, projectId, segmentInformation, roadPayload }
) => {
  e.preventDefault();
  const formData = new FormData();

  const payloadData = {
    project_id: projectId,
    ...segmentInformation,
    ...roadPayload,
    module,
    //...Object.fromEntries(formData.entries()), // Convert FormData to object
  };
  for (const [name, value] of formData.entries()) {
    payloadData[module][name] = value;
  }
  dispatch({
    type: "project/addSegment",
    payload: { url: `/segments`, ...payloadData },
  });
  // return console.log(payloadData);

  // dispatch({ type: "project/deleteProject", payload: { url: `/projects/`, projectId } });
};

export const handleUpdateRoadSegment = (
  e,
  dispatch,
  { module, projectId, segmentId, roadPayload }
) => {
  e.preventDefault();
  const formData = new FormData();

  const payloadData = {
    project_id: projectId,
    // ...segmentInformation,
    ...roadPayload,
    module,
    //...Object.fromEntries(formData.entries()), // Convert FormData to object
  };
  for (const [name, value] of formData.entries()) {
    payloadData[module][name] = value;
  }
  dispatch({
    type: "project/updateSegment",
    payload: { url: `/segments/${segmentId}`, ...payloadData },
  });
  // return console.log(payloadData);

  // dispatch({ type: "project/deleteProject", payload: { url: `/projects/`, projectId } });
};

export const normSInv = (fa) => {
  const p = fa; // / 100;
  var a1 = -39.6968302866538,
    a2 = 220.946098424521,
    a3 = -275.928510446969;
  var a4 = 138.357751867269,
    a5 = -30.6647980661472,
    a6 = 2.50662827745924;
  var b1 = -54.4760987982241,
    b2 = 161.585836858041,
    b3 = -155.698979859887;
  var b4 = 66.8013118877197,
    b5 = -13.2806815528857,
    c1 = -7.78489400243029e-3;
  var c2 = -0.322396458041136,
    c3 = -2.40075827716184,
    c4 = -2.54973253934373;
  var c5 = 4.37466414146497,
    c6 = 2.93816398269878,
    d1 = 7.78469570904146e-3;
  var d2 = 0.32246712907004,
    d3 = 2.445134137143,
    d4 = 3.75440866190742;
  var p_low = 0.02425,
    p_high = 1 - p_low;
  var q, r;
  var retVal;

  if (p < 0 || p > 1) {
    console.log("NormSInv: Argument out of range.");
    retVal = 0;
  } else if (p < p_low) {
    q = Math.sqrt(-2 * Math.log(p));
    retVal =
      (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  } else if (p <= p_high) {
    q = p - 0.5;
    r = q * q;
    retVal =
      ((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
      (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
  } else {
    q = Math.sqrt(-2 * Math.log(1 - p));
    retVal =
      -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
      ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
  }

  return retVal;
};
export const round = (n, dp) => {
  let h = 1;
  if (dp) h = +"1".padEnd(dp + 1, "0"); // 10 or 100 or 1000 or etc
  return Math.round(n * h) / h;
};
export const calculateDesignAndCalRatio = ({
  a24,
  r,
  std,
  dmfs,
  pi,
  zr,
  pt,
  cde,
}) => {
  const c5 = r;
  const c6 = std;
  const c18 = parseFloat(dmfs);
  const c7 = pi;
  const c13 = zr;
  const c14 = parseFloat(pi) - parseFloat(pt);
  const d24 = c18;
  //console.log("a24:", a24, "c5:", c5, "c6:", c6, "c18:", c18, "c7:", c7, "c13:", c13, "c14:", c14, "d24:", d24);
  return (
    10 **
    (c13 * c6 +
      (9.36 * Math.log10(a24 + 1) - 0.2) +
      Math.log10(c14 / (4.2 - 1.5)) / (0.4 + 1094 / (a24 + 1) ** 5.19) +
      (2.32 * Math.log10(d24) - 8.07))
  );
};

export const getRequiredSn = (stateValues, model) => {
  const r = stateValues.R;
  const pi = stateValues.Pi;
  const pt = stateValues.Pt;
  const std = stateValues.So;
  const eac = stateValues.Eac;
  const m1 = stateValues.m1;
  const m2 = stateValues.m2;
  const m3 = stateValues.m3;
  const zr = round(-normSInv(r / 100), 4);
  const opsi = (parseFloat(pi) - parseFloat(pt)).toString();

  const dmfs = model.selectedSubGrade; //$('#road-module-selection-form .dmfs').html();
  const defb = model.selectedBase; //$('#road-module-selection-form .defb').html();
  const defs = model.selectedSubBase; //$('#road-module-selection-form .defs').html();

  const dma1 = model.layerCoeffAcCourse(parseInt(eac));
  const dma2 = model.layerCoeffBaseCourse(parseInt(defb));
  const dma3 = model.layerCoeffSubBaseCourse(parseInt(defs));
  const cde = model.getBinEsalCeiling(model.selectedEsalBin); //parseFloat($('#road-module-selection-form .cde').html()); Commulative
  let a24 = 0.01;
  let count = 1;
  console.log(
    "-------------------->",
    "cde:",
    cde,
    "std:",
    std,
    "dmfs:",
    dmfs,
    "pi:",
    pi,
    "zr:",
    zr,
    "cde:",
    cde,
    "a24:",
    a24,
    "defs:",
    defs,
    "defb:",
    defb
  );
  if (!r || !std || !dmfs || !pi || !zr || !cde || !a24 || !defs || !defb) {
    return;
  }
  for (let step = 0; step < 999; step++) {
    let w18 = round(
      calculateDesignAndCalRatio({ r, std, dmfs, pi, zr, pt, cde, a24 }),
      5
    );
    let ratio = round(w18 / cde, 2);
    if (ratio > 1) break;
    a24 += 0.01;
    count++;
  }
  const sn = round(a24 - 0.01, 3);
  const returnedValues = {
    pi,
    std,
    a24,
    dmfs,
    defb,
    defs,
    cde,
    sn,
    zr,
    dma1,
    dma2,
    dma3,
  };
  return returnedValues;
};

export const formatNumberWithCommas = (number) => {
  const numString = number.toString();
  const [integerPart, decimalPart] = numString.split(".");
  const formattedInteger = integerPart
    .split("")
    .reverse()
    .map((digit, index) =>
      (index + 1) % 3 === 0 && index !== 0 ? "," + digit : digit
    )
    .reverse()
    .join("")
    .replace(/^,/, "");
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
};

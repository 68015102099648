import React from "react";
import { Tooltip } from 'react-tooltip';
import { BiIcons, FaIcons } from "./Icons";
const Actions = ({ onPreview, onEdit, onDuplicate, OnDelete, editToolTip = "Edit", deleteToolTip = "Delete", ...props }) => {
  const editIcon = "BiEdit";
  const addIcon = "BiAddToQueue";
  const deleteIcon = "BiSolidTrash";
  const previewIcon = "FaRegEye";

  return (
    <div className="flex flex-row items-center w-full desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl ">
      {!props?.hidePreview && (
        <div
          className={`hover:ease-in-out transition hover:-translate-y-1 hover:scale-110 duration-300 delay-50 hover:text-secondary hover:right-8 hover:font-bold cursor-pointer `}
          onClick={onPreview}
        >
          <FaIcons iconName={previewIcon} />
        </div>
      )}
      <div
        className="hover:ease-in-out transition hover:-translate-y-1 hover:scale-110 duration-300 delay-50 hover:text-secondary hover:right-8 hover:font-bold cursor-pointer"
        onClick={onEdit}
        data-tooltip-id="tooltip-edit"
        data-tooltip-content={editToolTip}
        data-tooltip-place="left"
        data-tooltip-variant="info"
        data-tooltip-offset={3}

      >
        <BiIcons iconName={editIcon} />
      </div>
      <Tooltip id="tooltip-edit" opacity={0.5} style={{ background: "#012D35", color: "white", fontSize: "1rem", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} />

      {!props?.hideAdd && (
        <div
          className="hover:ease-in-out transition hover:-translate-y-1 hover:scale-110 duration-300 delay-50 hover:text-secondary hover:right-8 hover:font-bold cursor-pointer"
          onClick={onDuplicate}
        >
          <BiIcons iconName={addIcon} />
        </div>
      )}
      <div
        className="hover:ease-in-out transition hover:-translate-y-1 hover:scale-110 duration-300 delay-50 hover:text-secondary hover:right-8 hover:font-bold cursor-pointer"
        onClick={OnDelete}
        data-tooltip-id="tooltip-delete"
        data-tooltip-content={deleteToolTip}
        data-tooltip-place="left"
        data-tooltip-variant="warning"
        data-tooltip-offset={3}
      >
        <BiIcons iconName={deleteIcon} />
      </div>
      <Tooltip id="tooltip-delete" opacity={0.5} style={{ background: "#012D35", color: "white", fontSize: "1rem", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center" }} />

    </div>
  );
};

export default Actions;

import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { addSegmentInfo } from "../store/actions";
import { updatedSegmentTypes } from "../helpers";
import Input from "./Input";
import { useNavigate } from "react-router-dom";
import Button from "./Button";
import Label from "./Label";
import Select from "react-select";
import { IoCloseCircle, IoCloseCircleOutline } from "react-icons/io5";

const SegmentForm = ({ projectId, onClose, isSegmentFormOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [from_road, setFrom_road] = useState("");
  const [to_road, setTo_road] = useState("");
  const [type, setType] = useState("");
  const [isHovered, setIsHovered] = useState(false);

  const handleAddSegmentInfo = () => {
    const segmentData = {
      name,
      from_road,
      to_road,
      type,
    };

    if (segmentData.name.trim() !== "") {
      dispatch(addSegmentInfo(segmentData));

      setName("");
      setFrom_road("");
      setTo_road("");
      setType("");
      onClose();
      handleAddSegmentNext(projectId);
    }
  };

  const typeName = type.toLowerCase().replace(/\s+/g, "-");

  const handleAddSegmentNext = (projectId) => {
    if (typeName === "road") {
      dispatch({ type: "road/resetToInitialValues" });
      return navigate(`/road/${projectId}`);
    }

    navigate(`/add-allied-infrastructure/${projectId}/${typeName}`);
  };

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <div className="modal flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="modal-overlay fixed inset-0 bg-green-100 opacity-50"></div>

      <div
        className={`modal-content bg-white w-1/3  h-fit rounded-lg shadow-lg relative z-10 border border-gray-300
      ${isSegmentFormOpen ? "animate-slideInTop" : "animate-slideOutTop"}
      `}
      >
        <div className="flex flex-row items-center p-5 bg-gray-200">
          <h2 className="text-2xl flex flex-1 justify-start font-saira">
            Add New Segment
          </h2>
          <div className="  flex flex-row flex-1  justify-end  ">
            <div
              onClick={onClose}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {isHovered ? (
                <IoCloseCircle size={36} fill="#FF0000" />
              ) : (
                <IoCloseCircleOutline size={36} color="#FF0000" />
              )}
            </div>
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleAddSegmentInfo();
          }}
        >
          <div className="flex flex-col gap-5 p-5 font-poppins">
            <div className="flex flex-col gap-3">
              <Label>Name</Label>
              <Input
                type="text"
                placeholder="Name"
                width="w-full"
                value={name}
                required
                name="segmentname"
                onChange={(e) => setName(e.target.value)}
                className={"bg-ternary"}
              />
              <div className="flex flex-row gap-2">
                <Input
                  type="text"
                  placeholder="From RD"
                  width="w-1/2"
                  value={from_road}
                  required
                  name="from_road"
                  onChange={(e) => setFrom_road(e.target.value)}
                  className={"bg-ternary"}
                />

                <Input
                  type="text"
                  placeholder="To RD"
                  width="w-1/2"
                  value={to_road}
                  required
                  name="to_road"
                  onChange={(e) => setTo_road(e.target.value)}
                  className={"bg-ternary"}
                />
              </div>
              <Label>Choose type</Label>
              <div>
                <Select
                  className="border-gray-300 border-b-2 text-primary font-poppins"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#F1F9F8",
                    }),
                  }}
                  classNamePrefix="select"
                  isSearchable={true}
                  required={true}
                  placeholder="Select Segment Type"
                  options={updatedSegmentTypes}
                  onChange={(e) => {
                    setType(e.value);
                  }}
                />
              </div>
              <div className="flex flex-row justify-end items-center gap-5 pt-4 font-roboto">
                <Button
                  label={"Close"}
                  onClick={onClose}
                  textColor={" text-white"}
                  className="  rounded-full text-center  p-2 w-1/5  focus:outline-none"
                  bgColor="bg-gray-400"
                  hoverBg="hover:bg-gray-500"
                />

                <Button
                  label={"Next"}
                  type="submit"
                  className=" rounded-full text-white p-2 w-1/5 focus:outline-none"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SegmentForm;

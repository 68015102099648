export const bill6_estimation = [
  {
    "A": "16-07",
    "B": "Reflective Mirrors at Sharp Curves (900 mm dia) with post Complete in all respects",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-08",
    "B": "Providing and Fixing Reflective Sheet Hi-intensity Grade Including Foundation and steel Post complete in all respect",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-15",
    "B": "Providing 3 feet high RCC railing (as per the drawing in specifications) on bridges",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-16",
    "B": "Providing and fixing GI pipe railing (3 feet high) ",
    "C": "No",
    "D": "m/3row",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-17-a",
    "B": "P&E at site : RCC km stone",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-17-b",
    "B": "P&E at site : RCC 1/10th km stone.",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-17-c",
    "B": "P&E at site : RCC boundry pillar.",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-18-a",
    "B": "Road signs (not exceeding 1 m2) without reflection sheet & lettering : Supply and Fixing at site with PCC 1:3:6 upto max 3' depth",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-18-b",
    "B": "Road signs (not exceeding 1 m2) without reflection sheet & lettering : Supply to the C&W store/site of work",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-19-a",
    "B": "Road sign (any size) double pedestal 4\"x2\"x0.25\" 11' long without reflection sheet & lettering: Supply and Fixing at site with PCC 1:3:6 max 3' depth",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-19-b",
    "B": "Road sign (any size) double pedestal 4\"x2\"x0.25\" 11' long without reflection sheet & lettering: Supply to the C&W store/site of work",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-20-a",
    "B": "Traffic Road Sign Cat 1",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-20-b",
    "B": "Traffic Road Signs Category 2, Size 900 mm",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-20-c",
    "B": "Traffic Road Signs Category 3a",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-20-d",
    "B": "Traffic Road Signs Category 3b",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-21-a",
    "B": "Overhead Gantry Beam/ Information Sign for Single Carriageway",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-21-b",
    "B": "Overhead Gantry Beam/ Information Sign for Dual Carriageway",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-21-c",
    "B": "Overhead  Gantry  Beam/ Information Sign for Six Lane Carriageway",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-21-d",
    "B": "Providing and Fixing gantry of structural steel sections as per design / drawings including foundation complete",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-22-a",
    "B": "Supply and Fixing reflective sheet on MS/aluminium road signs etc including lettering : Diamond grade",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-22-b",
    "B": "Supply and Fixing reflective sheet on MS/aluminium road signs etc including lettering : High intensity grade",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-22-c",
    "B": "Supply and Fixing reflective sheet on MS/aluminium road signs etc including lettering : Engineering grade",
    "C": "No",
    "D": "m2",
    "E": "",
    "F": 1,
    "G": 1,
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ]
  },
  {
    "A": "16-23",
    "B": "Delineator Bi-directional (Diamond sheet)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-24",
    "B": "Plastic 3M Bi Direction",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-a-01",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Large, strip 146x30mm, 171 beads: Uni-direction",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-a-02",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Large, strip 146x30mm, 171 beads: Bi-direction",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-b-01",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Medium, strip 114x18mm, 74 beads: Uni-direction",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-b-02",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Medium, strip 114x18mm, 74 beads: Bi-direction",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-01",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Small, strip 75x14mm, 43 beads: Uni-directional",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-02",
    "B": "Supply and Fixing aluminium alloy road studs as per specs Small, strip 75x14mm, 43 beads: Bi-directional",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-03",
    "B": "Supply & Fixing of high Quality) 360 d Glass Road studs ( 4\" Dia ) Including fixing at site in all respect",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-04",
    "B": "Reflectorized Aluminium Pavement Stud (Raised Profile Type - Single)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-05",
    "B": "Reflectorized Aluminium Pavement Stud (Raised Profile Type - Double)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-06",
    "B": "Reflectorized Aluminium Pavement Stud (Flushed Profile Type - Single)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-07",
    "B": "Reflectorized Aluminium Pavement Stud (Flushed Profile Type - Double)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-08",
    "B": "Reflectorized Plastic Pavement Stud (Raised Profile Type - Single)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-09",
    "B": "Reflectorized Plastic Pavement Stud (Raised Profile Type - Double)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-10",
    "B": "Reflectorized Plastic Pavement Stud (Flushed Profile Type - Single)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-25-c-11",
    "B": "Reflectorized Plastic Pavement Stud (Flushed Profile Type - Double)",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-73-a",
    "B": "Providing and fixing kerb stone (12\"x18\"x6\") in cement sand mortar 1:3 in center media or round about as specified.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-73-b",
    "B": "Providing and fixing kerb stone (12\"x14\"x6\") in cement sand mortar 1:3 in center media or round about as specified.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-73-c",
    "B": "Providing and fixing kerb stone (12\"x12\"x6\") in cement sand mortar 1:3 in center media or round about as specified.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-74-a",
    "B": "Bailing out standing water by Mechanical mean.",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1,
    "G": 1,
    "H": 1,
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ]
  },
  {
    "A": "16-74-b",
    "B": "Bailing out running water by Mechanical mean.",
    "C": "No",
    "D": "Cumec",
    "E": "",
    "F": 1,
    "G": 1,
    "H": 1,
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ]
  },
  {
    "A": "16-75-a",
    "B": "Pavement marking in reflective thermoplast paint with glass beads for line 15 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-75-b",
    "B": "Pavement marking in reflective thermoplast paint with glass beads for line 20 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-75-c",
    "B": "Pavement marking in non-reflective thermoplast paint  for line 15 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-75-d",
    "B": "Pavement marking in non-reflective thermoplast paint  for line 20 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-76-a",
    "B": "Pavement marking in reflective chlorinated rubber paint with glass beads for line 15 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-76-b",
    "B": "Pavement marking in reflective chlorinated rubber paint with glass beads for line 20 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-77-a",
    "B": "Pavement marking in reflective chlorinated rubber paint for line 15 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-77-b",
    "B": "Pavement marking in reflective chlorinated rubber paint for line 20 cm width.",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-78",
    "B": "Right of Way Marker",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-88-a",
    "B": "Metal Guard Rail",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1,
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ]
  },
  {
    "A": "16-88-b",
    "B": "Metal Guard Rail End Pieces",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-88-c",
    "B": "Steel Post of Metal Guard Rail",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-89-a",
    "B": "Concrete Beam Guardrail (including reinforcement)",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1,
    "G": 1,
    "H": 1,
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ]
  },
  {
    "A": "16-89-b",
    "B": "Concrete Post For Guardrail  (Incl. Reinforcement)",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1,
    "G": 1,
    "H": 1,
    "I": 0.0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ]
  },
  {
    "A": "Sub-Total Bill 6",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [

    ]
  }
]
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaIcons } from "../components/Icons";
import ProjectList from "../components/ProjectList";
import BackendLayout from "../components/BackendLayout";
import SearchBar from "../components/SearchBar";
import FilterBtn from "../components/FilterBtn";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";
import { useDispatch } from "react-redux";
import {
  TbSquareRoundedNumber1Filled,
  TbSquareRoundedNumber2Filled,
  TbSquareRoundedNumber3Filled,
} from "react-icons/tb";

const Instruction = () => {
  const dispatch = useDispatch();
  const plusIcon = "FaPlus";
  return (
    <BackendLayout screenName="Modular Design ">
      <div className="flex flex-row items-center ">
        <Breadcrumb
          items={[
            { itemName: "Home", itemLink: "" },
            { itemName: "Projects", itemLink: "/projects" },
          ]}
        />

        {/* <div className="flex flex-grow flex-row  gap-4 items-center justify-end p-2 font-poppins">
          <Link to={"add-project"}>
            <Button
              label={"Add a New Project"}
              icon={<FaIcons iconName={plusIcon} />}
              className={"w-fit rounded-lg"}
            />
          </Link>
        </div> */}
      </div>

      {/* <div className="flex flex-row p-6 items-center">
        <SearchBar />
        <FilterBtn /> 
      </div> */}

      <div className="flex items-center justify-center">
        <b className="font-poppins font-bold text-lg mb-4 desktopSm:text-2xl desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl desktopXlg:text-6xl">
          INSTRUCTIONS FOR USE
        </b>
      </div>
      <div className="flex flex-col">
        <div className=" grid grid-flow-col gap-4 mx-4">
          <div className="bg-gray-200 shadow-md rounded-md h-[20rem] desktopMd:h-[25rem] desktopXlg:h-[28rem] flex flex-col space-y-4 px-4 ">
            <div className="py-4  ">
              <TbSquareRoundedNumber1Filled size={24} color="#012D35" className="desktopSm:w-6 desktopSm:h-6x desktopMd:w-10 desktopMd:h-10 desktopXmd:w-14 desktopXmd:h-14 desktopLg:w-18 desktopLg:h-18 desktopXlg:w-20 desktopXlg:h-20" />
            </div>
            <p className="font-poppins text-primary desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
              All the input forms in this repository are designed in a
              user-friendly format to make them an integral part of PC-1
              documents. PC-1 form (Infrastructure) can be downloaded from here.
              In all the input forms, only input fields in green color can be
              edited/selected. All the other value (uncolored) are static.
            </p>
          </div>
          <div className="bg-gray-200 shadow-md rounded-md h-[20rem] desktopMd:h-[25rem] desktopXlg:h-[28rem] flex flex-col space-y-4 px-4">
            <div className="py-4">
              <TbSquareRoundedNumber2Filled size={24} color="#012D35" className="desktopSm:w-6 desktopSm:h-6x desktopMd:w-10 desktopMd:h-10 desktopXmd:w-14 desktopXmd:h-14 desktopLg:w-18 desktopLg:h-18 desktopXlg:w-20 desktopXlg:h-20" />
            </div>
            <p className="font-poppins text-primary desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
              Certain input fields in 'Design Summary' page are also editable for
              entering project- specific information. These input fields are also
              highlighted in green color.
            </p>
          </div>
          <div className="bg-gray-200 shadow-md rounded-md h-[20rem] desktopMd:h-[25rem] desktopXlg:h-[28rem] flex flex-col space-y-4 px-4">
            <div className="py-4">
              <TbSquareRoundedNumber3Filled size={24} color="#012D35" className="desktopSm:w-6 desktopSm:h-6x desktopMd:w-10 desktopMd:h-10 desktopXmd:w-14 desktopXmd:h-14 desktopLg:w-18 desktopLg:h-18 desktopXlg:w-20 desktopXlg:h-20" />
            </div>
            <p className="font-poppins text-primary desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
              All the output pages are set for printing (where applicable).
              These pages provide necessary details about major inputs, design
              process, design output coupled with a Design Summary page,
              followed by details of quantities and costs
              for structural design of each module.
            </p>
          </div>
        </div>
      </div>

      {/* <table className="d-block p-3 m-6">
        <thead>
          <tr>
            <th className="text-center bg-green">
            </th>
          </tr>
        </thead>
        <tbody className="text-left">
          <tr>
            <td className="d-flex">
              <b className="bullet"></b>
              <span className="d-block">
                All the input forms in this repository are designed in a
                user-friendly format to make them an integral part of PC-1
                documents. PC-1 form (Infrastructure) can be downloaded from
                here. In all the input forms, only cells in blue color can be
                edited/selected. All the other value (uncolored) are static.
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex">
              <b className="bullet">2</b>
              <span className="d-block">
                Certain cells in 'Design Summary' sheet are also editable for
                entering project- specific information. These cells are also
                highlighted in blue color.
              </span>
            </td>
          </tr>
          <tr>
            <td className="d-flex">
              <b className="bullet">3</b>
              <span className="d-block">
                All the output sheets are set for printing (where applicable).
                These sheets provide necessary details about major inputs,
                design process, design output coupled with a Design Summary
                sheet, followed by details of quantities and costs (based on
                MRS- 2022) for structural design of each module.
              </span>
            </td>
          </tr>
        </tbody>
      </table> */}
    </BackendLayout>
  );
};

export default Instruction;

export const bill5_estimation = [
    {
        "A": "03-67-a",
        "B": "Structural Excavation in Common Material by mechanical means",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-67-a-i",
        "B": "Structural Excavation In Common Material ",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-67-a-ii",
        "B": "Structural Excavation In Common Material Below Water Level",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-67-b-i",
        "B": "Structural Excavation In Hard Rock Material by mechanical means requiring blasting",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-67-b-ii",
        "B": "Structural Excavation In Medium Rock Material",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-67-b-iii",
        "B": "Structural Excavation In Soft Rock Material",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-09-a",
        "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Ordinary Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-09-b",
        "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Hard Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-09-c",
        "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Very hard Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-09-d",
        "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Gravel & shingle",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-10-a",
        "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Ordinary Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-10-b",
        "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Hard Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-10-c",
        "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Very Hard Soil",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "03-10-d",
        "B": "Earth excavation in open cut 1.5m - 3m depth for drains, pipes etc & disposal : in Gravel & shingle",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-a",
        "B": "Concrete Class A1",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-b",
        "B": "Concrete Class B",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-c",
        "B": "Concrete Class C",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-d",
        "B": "Concrete Class D1",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-e",
        "B": "Concrete Class Y",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": 1000.0,
        "G": 0.0,
        "H": 0.0,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E"
        ]
    },
    {
        "A": "06-49-f",
        "B": "Lean concrete",
        "C": "No",
        "D": "m cube",
        "E": "",
        "F": NaN,
        "G": NaN,
        "H": NaN,
        "I": 0.0,
        "J": 0,
        "K": 0,
        "inputFields": [
            "E", "F", "G", "H"
        ]
    },
    {
        "A": "Sub-Total Bill 5",
        "B": NaN,
        "C": 0,
        "D": NaN,
        "E": "",
        "F": NaN,
        "G": NaN,
        "H": NaN,
        "I": NaN,
        "J": 0,
        "K": 0,
        "inputFields": [

        ]
    }
]
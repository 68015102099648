// // src/InAugurationPage.js
// import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import hero_Img from "../assets/home_banner.png";


// const InAugurationPage = () => {
//     const navigate = useNavigate();
//     const handleLaunch = () => {
//         // Replace with your website URL
//         navigate('/home');
//     };

//     return (
//        <>
//          <div className="flex items-center justify-center">
//         <div className="absolute flex flex-col w-[500px] gap-5 px-10  bg-green-900 text-center bg-opacity-60 py-10 rounded-lg border border-green-900">
//           <span className="text-4xl font-poppins font-bold text-center py-10 text-white  select-none">
//          Welcome To Khyber Pakhtunkhwa PIM Portal Opening Ceremony
//           </span>
//           <button 
//                  className=" py-3  bg-green-700 text-white font-semibold rounded-lg shadow-md hover:bg-green-900 transition duration-200"
//              >
//                  <a href='https://md.cwd.gkp.pk/home' className='px-10 text-2xl' target='_self'>Launch Website</a>
//              </button>
//         </div>
//         <img
//           className=" h-screen w-screen object-cover pointer-events-none select-none"
//           src={hero_Img}
//           alt="hero_section "
//         />
//       </div>
        
//        </>
//     );
// };

// export default InAugurationPage;
// src/InAugurationPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import hero_Img from "../assets/home_banner.png";

const InAugurationPage = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [countdown, setCountdown] = useState(10);

    const handleLaunchClick = () => {
        setIsModalOpen(true);
        setCountdown(5);
    };

    useEffect(() => {
        let timer;
        if (isModalOpen && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);
        } else if (countdown === 0) {
            // Navigate to the home page after countdown
            navigate('/home/launch');
        }
        return () => clearInterval(timer);
    }, [isModalOpen, countdown, navigate]);

    return (
        <>
            <div className="flex items-center justify-center">
                <div className="absolute flex flex-col w-[500px]  px-10 bg-green-900 text-center bg-opacity-80 py-10 rounded-lg border border-green-900">
                    <span className="text-4xl font-poppins font-bold text-center py-10 text-white select-none">
                        Welcome To Khyber Pakhtunkhwa PIM Portal Opening Ceremony
                    </span>
                    <button
                        className="py-3 bg-green-700 text-white font-bold rounded-lg shadow-md hover:bg-green-900 transition duration-200 text-xl border-2 border-green-900 select-none"
                        onClick={handleLaunchClick}
                    >
                        LAUNCH PIM PORTAL
                    </button>
                </div>
                <img
                    className="h-screen w-screen object-cover pointer-events-none select-none"
                    src={hero_Img}
                    alt="hero_section"
                />
            </div>

            {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className=" bg-green-900 border-2 border-green-950 p-5 rounded-lg text-center">
                        <h2 className="text-5xl font-bold p-3 text-white">Launching in {countdown}...</h2>
                        {countdown === 1 && <p className='text-white'>Launching PIM Potal Now!</p>}
                    </div>
                </div>
            )}
        </>
    );
};

export default InAugurationPage;

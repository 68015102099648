import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import BackendLayout from "../components/BackendLayout";
import { FaIcons, BiIcons } from "../components/Icons";
import { formatNumberWithCommas, round, segmentLabels } from "../helpers";
import { deleteProjectSegment, addSegmentSuccess } from "../store/actions";

import SegmentForm from "../components/AddSegmentForm";
import SearchBar from "../components/SearchBar";
import FilterBtn from "../components/FilterBtn";
import Button from "../components/Button";
import Actions from "../components/ActionsIcons";
import Breadcrumb from "../components/Breadcumb";
import Table from "../components/Table";
import Swal from "sweetalert2";
import { FaArrowLeft, FaLongArrowAltLeft } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";
import Modal from "../components/ui/Modal";
import Input from "../components/Input";
import ListSkeleton from "../components/ui/ListSkeleton";
import RoadTable from "../components/RoadTable";
import AbstractCostTableValues from "../components/AbstractCostTableValues";

const api_url = process.env.REACT_APP_API_URL + "/segment/";

const ProjectSegments = () => {
  const { projectId } = useParams();
  const dispatch = useDispatch();
  const [previewModal, setPreviewModal] = useState(false);
  const [abstractOfCostPreview, setAbstractOfCostPreview] = useState(false);
  const [previewSegmentType, setPreviewSegmentType] = useState({
    type: "",
    Id: "",
  });
  const [isSegmentFormOpen, setIsSegmentFormOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const user = useSelector((state) => state.user);
  const [segmentList, setSegmentList] = useState([]);
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [filterSegments, setFilterSegments] = useState([]);

  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );
  console.log("🚀 ~ ProjectSegments ~ projectData:", projectData)

  const loading = useSelector((state) => state.project.loading);

  const RoadBill1AbstractCost = projectData?.RoadBill1AbstractCost;
  const RoadBill2AbstractCost = projectData?.RoadBill2AbstractCost;
  const RoadBill3AbstractCost = projectData?.RoadBill3AbstractCost;
  const RoadBill5AbstractCost = projectData?.RoadBill5AbstractCost;
  const RoadBill6AbstractCost = projectData?.RoadBill6AbstractCost;
  const RoadBill7AbstractCost = projectData?.RoadBill7AbstractCost;

  const TBridgeSum = projectData?.AlliedTBridgeSum;
  const SlabBridgeSum = projectData?.AlliedSlabBridgeSum;
  const BoxCulvertSum = projectData?.AlliedBoxCulvertSum;
  const SlabCulvertSum = projectData?.AlliedSlabCulvertSum;
  const VentedCausewaySum = projectData?.AlliedVentedCausewaySum;
  const ClosedCausewaySum = projectData?.AlliedClosedCausewaySum;
  const ReatainingCanteliverSum = projectData?.AlliedRetainingCanteliverSum;
  const RetainingCounterfortSum = projectData?.AlliedRetainingCounterfortSum;
  const GravityInSum = projectData?.AlliedGravityInSum;
  const GravityOutSum = projectData?.AlliedGravityOutSum;


  const segmentsData = projectData?.segments;
  useEffect(() => {
    dispatch({ type: "project/initList" });
    dispatch({
      type: "project/fetchProjects",
      payload: { url: "/projects" },
    });
  }, []);

  useEffect(() => {
    // if (segmentsData?.length !== 0) {
    setSegmentList(segmentsData?.map((segment) => segment?.id));
    setFilterSegments(segmentsData);

    // }
  }, [segmentsData]);

  useEffect(() => {
    segmentFilter();
  }, [search]);

  const plusIcon = "FaPlus";
  const printIcon = "BiPrinter";

  const openSegmentForm = () => {
    setIsSegmentFormOpen(true);
  };

  const closeSegmentForm = () => {
    setIsSegmentFormOpen(false);
  };

  const handleDeleteSegment = (projectId, segmentId) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your segment has been deleted.",
          icon: "success",
          showConfirmButton: false,
          timer: 1000,
        });
        dispatch(
          deleteProjectSegment({ projectIdToDelete: projectId, segmentId })
        );
      }
    });
  };
  const handlePreviewSegment = (row, index) => {
    setTableData(segmentsData[index]);
    setPreviewModal(!previewModal);
    setPreviewSegmentType({
      type: row?.type,
      Id: row?.id,
    });
  };

  const handleAbstractPreview = () => {
    setAbstractOfCostPreview(!abstractOfCostPreview);
  };

  const printBOQsReport = async () => {
    if (segmentsData.length !== 0) {
      segmentsData.length === segmentList.length
        ? (() => {
          let url = `${api_url}create-boq-pdf?project_id=${projectId}&access-token=${user.access_token}`;
          window.open(url, "_blank");
        })()
        : (() => {
          let url = `${api_url}create-boq-pdf?project_id=${projectId}&access-token=${user.access_token
            }&segments[]=${segmentList.join("&segments[]=")}`;
          window.open(url, "_blank");
        })();
    }
  };

  const handleOnEditSegment = (row) => {
    if (row?.type === "road") {
      navigate(`/road/${projectId}/segment/${row?.id}`);
    } else {
      navigate(
        `/add-allied-infrastructure/${projectId}/${row?.type}/${row?.id}`
      );
    }
  };

  function segmentFilter() {
    if (search) {
      const filteredData = segmentsData.filter((segment) => {
        return segment.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilterSegments(filteredData);
    } else {
      setFilterSegments(segmentsData);
    }
  }

  return (
    <BackendLayout screenName={`Segments of ${projectData?.name}`}>
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="flex flex-row justify-between items-center mb-2">
            <h2 className="text-xl flex flex-1 justify-start font-saira text-primary">
              Print Project
            </h2>
            <IoCloseCircleOutline
              onClick={() => setShowModal(false)}
              size={32}
              color="#FF0000"
            />
          </div>
          <div className="flex flex-col space-y-4 ">
            <div className="flex flex-col space-y-2">
              {segmentsData?.length !== 0 &&
                segmentsData.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`space-x-2 items-center flex-row flex bg-white shadow rounded-md px-2 py-2
                      ${segmentList.includes(item?.id)
                          ? "border-2 border-secondary"
                          : "border-none"
                        }
                       transform transition-all duration-200 ease-in-out hover:scale-105 cursor-pointer`}
                      onClick={(e) => {
                        if (segmentList.includes(item?.id)) {
                          setSegmentList(
                            segmentList.filter((id) => id !== item?.id)
                          );
                        } else {
                          setSegmentList([...segmentList, item?.id]);
                        }
                      }}
                    >
                      <input
                        type="checkbox"
                        value={item?.id}
                        name={item?.name}
                        checked={segmentList.includes(item?.id)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSegmentList([...segmentList, item?.id]);
                          } else {
                            setSegmentList(
                              segmentList.filter((id) => id !== item?.id)
                            );
                          }
                        }}
                        className="h-5 w-5 rounded-full accent-primary cursor-pointer"
                      />
                      <span className="font-poppins font-medium text-lg cursor-pointer">
                        {item.name}
                      </span>
                    </div>
                  );
                })}
            </div>
            <Button
              label="Generate PDF"
              bgColor="bg-primary"
              textColor="text-white"
              className="h-10 rounded-md focus:outline-none my-2"
              onClick={(e) => printBOQsReport()}
            />
          </div>
        </Modal>
      )}
      <div className="flex flex-row items-center ">
        <Breadcrumb
          items={[
            { itemName: "Home", itemLink: "" },
            { itemName: "Projects", itemLink: "/projects" },
            { itemName: projectData?.name, itemLink: "" },
          ]}
        />

        <div className="flex flex-grow flex-row  gap-4 items-center justify-end p-2">
          {segmentsData?.length !== 0 ? (
            <Button
              label={"Print Project"}
              icon={<BiIcons iconName={printIcon} />}
              className={"w-fit"}
              onClick={() => setShowModal(true)}
            />
          ) : (
            <></>
          )}

          {segmentsData?.length !== 0 ? (
            !previewModal && <Button
              label={"Project Abstract of Cost"}
              icon={<BiIcons iconName={printIcon} />}
              className={`${abstractOfCostPreview ? "bg-green-500" : "bg-primary"
                } w-fit`}
              onClick={() => setAbstractOfCostPreview(!abstractOfCostPreview)}
            />
          ) : (
            <></>
          )}
          {isSegmentFormOpen && (
            <SegmentForm
              projectId={projectId}
              onClose={closeSegmentForm}
              isSegmentFormOpen={isSegmentFormOpen}
            />
          )}

          <Button
            label={"Add a new Segment"}
            icon={<FaIcons iconName={plusIcon} />}
            className={"w-fit"}
            onClick={openSegmentForm}
          />
        </div>
      </div>

      <div className="flex flex-row p-6 items-center">
        <SearchBar search={search} setSearch={setSearch} />
      </div>
      {previewModal ? (
        <>
          <div className="flex flex-row px-10 items-center space-x-2 mb-2 text-sm font-semibold font-poppins text-gray-500 hover:text-primary ">
            <FaLongArrowAltLeft
              size={24}
              color="gray"
              onClick={() => setPreviewModal(!previewModal)}
              className="cursor-pointer"
            />
            <p
              onClick={() => setPreviewModal(!previewModal)}
              className="cursor-pointer"
            >
              Back to Segment
            </p>
          </div>
          {previewSegmentType.type === "road" ? (
            <RoadTable roadId={previewSegmentType.Id} />
          ) : (
            <Table models={tableData} />
          )}
        </>
      ) : abstractOfCostPreview ? (
        <>
          <div className="flex flex-row px-10 items-center space-x-2 mb-2 text-sm font-semibold font-poppins text-gray-500 hover:text-primary ">
            <FaLongArrowAltLeft
              size={24}
              color="gray"
              onClick={() => setAbstractOfCostPreview(!abstractOfCostPreview)}
              className="cursor-pointer"
            />
            <p
              onClick={() => setAbstractOfCostPreview(!abstractOfCostPreview)}
              className="cursor-pointer"
            >
              Back to Segment
            </p>
          </div>
          <div className=" font-poppins text-primary p-5 flex justify-center">
            <table
              cellPadding={2}
              cellSpacing={2}
              className="border border-primary w-full"
            >
              <tbody>
                <tr className="border-2 border-black bg-primary text-white ">
                  <td className="py-2 text-center font-poppins text-md " colSpan={5}>
                    Bill of Quantities
                  </td>
                </tr>

                <tr className="border-2 border-black bg-primary text-white ">
                  <td className="py-2 text-center font-poppins text-md " colSpan={5}>
                    Abstract of Costs
                  </td>
                </tr>
                <tr>
                  <td className="text-base text-center border border-primary ">
                    Bill #
                  </td>
                  <td
                    className="text-base text-left px-10 border border-primary"
                    colSpan={2}
                  >
                    Description
                  </td>
                  <td className="text-base text-center border border-primary">
                    BoQ Cost
                  </td>
                  <td className="text-base text-center border border-primary">
                    Remarks
                  </td>
                </tr>
                <AbstractCostTableValues billNumber={1} billDescription="Earthwork" billCost={RoadBill1AbstractCost} />
                <AbstractCostTableValues billNumber={2} billDescription="Subbase &amp; Base" billCost={RoadBill2AbstractCost} />
                <AbstractCostTableValues billNumber={3} billDescription="Surface course" billCost={RoadBill3AbstractCost} />
                <AbstractCostTableValues billNumber={"4a"} billDescription="T-Beam Bridges" billCost={TBridgeSum} />
                <AbstractCostTableValues billNumber={"4b"} billDescription="Slab Bridges" billCost={SlabBridgeSum} />
                <AbstractCostTableValues billNumber={"4c"} billDescription="Vented Causeways" billCost={VentedCausewaySum} />
                <AbstractCostTableValues billNumber={"4d"} billDescription="Closed Causeways" billCost={ClosedCausewaySum} />
                <AbstractCostTableValues billNumber={"4e"} billDescription="Box Culverts" billCost={BoxCulvertSum} />
                <AbstractCostTableValues billNumber={"4f"} billDescription="Slab Culverts" billCost={SlabCulvertSum} />
                <AbstractCostTableValues billNumber={"4g"} billDescription="Retaining Walls Counterfort" billCost={RetainingCounterfortSum} />
                <AbstractCostTableValues billNumber={"4h"} billDescription="Retaining Walls Cantilever" billCost={ReatainingCanteliverSum} />
                <AbstractCostTableValues billNumber={"4i"} billDescription="Gravity Retaining Wall Sloping Inwards" billCost={GravityInSum} />
                <AbstractCostTableValues billNumber={"4j"} billDescription="Gravity Retaining Wall Sloping Outwards" billCost={GravityOutSum} />
                <AbstractCostTableValues billNumber={5} billDescription="Drainage works" billCost={RoadBill5AbstractCost} />
                <AbstractCostTableValues billNumber={6} billDescription="Ancillary works" billCost={RoadBill6AbstractCost} />
                <AbstractCostTableValues billNumber={7} billDescription="Miscellaneous" billCost={RoadBill7AbstractCost} />

                <tr>
                  <td className="text-base font-semibold py-1 px-5 border border-primary" colSpan={3} >
                    Subtotal amount of Civil Works (A):
                  </td>
                  <td className="text-base text-center border border-primary font-semibold">
                    {formatNumberWithCommas(
                      parseFloat(RoadBill1AbstractCost.replace(/[^0-9.]/g, "")) +
                      parseFloat(
                        RoadBill2AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(
                        RoadBill3AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(TBridgeSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(SlabBridgeSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(BoxCulvertSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(SlabCulvertSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(VentedCausewaySum.replace(/[^0-9.]/g, "")) +
                      parseFloat(ClosedCausewaySum.replace(/[^0-9.]/g, "")) +
                      parseFloat(RetainingCounterfortSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(ReatainingCanteliverSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(GravityInSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(GravityOutSum.replace(/[^0-9.]/g, "")) +

                      parseFloat(
                        RoadBill5AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(
                        RoadBill6AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(RoadBill7AbstractCost.replace(/[^0-9.]/g, ""))
                    )}
                  </td>
                  <td className="text-base text-center border border-primary"></td>
                </tr>

                <AbstractCostTableValues billNumber={8} billDescription="Land &amp; building compensation" billCost={""} />
                <AbstractCostTableValues billNumber={9} billDescription="Relocation of utilities" billCost={""} />
                <AbstractCostTableValues billNumber={10} billDescription="Relocation of other items (if any)" billCost={""} />
                <AbstractCostTableValues billNumber={11} billDescription="Escalation" billCost={""} />
                <AbstractCostTableValues billNumber={12} billDescription="Advertisement charges" billCost={""} />
                <AbstractCostTableValues billNumber={13} billDescription="Departmental charges" billCost={""} />
                <AbstractCostTableValues billNumber={14} billDescription="Miscellaneous non-developmental" billCost={""} />

                <tr>
                  <td
                    className="text-base font-semibold py-1 px-5 border border-primary"
                    colSpan={3}
                  >
                    Subtotal of non-developmental works (B):
                  </td>
                  <td className="text-base  text-left px-10 border border-primary"></td>
                  <td className="text-base text-center border border-primary"></td>
                </tr>
                <tr>
                  <td className="text-base font-semibold p-1 border border-primary text-center" colSpan={3} >
                    Grand total (A) + (B)
                  </td>
                  <td className="text-base text-center border border-primary font-semibold">
                    {formatNumberWithCommas(
                      parseFloat(RoadBill1AbstractCost.replace(/[^0-9.]/g, "")) +
                      parseFloat(
                        RoadBill2AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(
                        RoadBill3AbstractCost.replace(/[^0-9.]/g, "")
                      ) +

                      parseFloat(TBridgeSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(SlabBridgeSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(BoxCulvertSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(SlabCulvertSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(VentedCausewaySum.replace(/[^0-9.]/g, "")) +
                      parseFloat(ClosedCausewaySum.replace(/[^0-9.]/g, "")) +
                      parseFloat(RetainingCounterfortSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(ReatainingCanteliverSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(GravityInSum.replace(/[^0-9.]/g, "")) +
                      parseFloat(GravityOutSum.replace(/[^0-9.]/g, "")) +

                      parseFloat(
                        RoadBill5AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(
                        RoadBill6AbstractCost.replace(/[^0-9.]/g, "")
                      ) +
                      parseFloat(RoadBill7AbstractCost.replace(/[^0-9.]/g, ""))
                    )}
                  </td>
                  <td className="text-base text-center border border-primary"></td>
                </tr>
                <tr>
                  <td
                    className="text-base font-semibold p-1 border border-primary text-center"
                    colSpan={3}
                  >
                    Grand Total (in Millions)
                  </td>
                  <td className="text-base text-center border border-primary">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <span>
                        {round(
                          (parseFloat(
                            RoadBill1AbstractCost.replace(/[^0-9.]/g, "")
                          ) +
                            parseFloat(
                              RoadBill2AbstractCost.replace(/[^0-9.]/g, "")
                            ) +
                            parseFloat(
                              RoadBill3AbstractCost.replace(/[^0-9.]/g, "")
                            ) +

                            parseFloat(TBridgeSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(SlabBridgeSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(BoxCulvertSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(SlabCulvertSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(VentedCausewaySum.replace(/[^0-9.]/g, "")) +
                            parseFloat(ClosedCausewaySum.replace(/[^0-9.]/g, "")) +
                            parseFloat(RetainingCounterfortSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(ReatainingCanteliverSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(GravityInSum.replace(/[^0-9.]/g, "")) +
                            parseFloat(GravityOutSum.replace(/[^0-9.]/g, "")) +

                            parseFloat(
                              RoadBill5AbstractCost.replace(/[^0-9.]/g, "")
                            ) +
                            parseFloat(
                              RoadBill6AbstractCost.replace(/[^0-9.]/g, "")
                            ) +
                            parseFloat(
                              RoadBill7AbstractCost.replace(/[^0-9.]/g, "")
                            )) /
                          1000000,
                          3
                        )}
                      </span>
                      <span>Millions</span>
                    </div>
                  </td>
                  <td className="text-base text-center border border-primary"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </>

      ) : (
        <div className="flex flex-col p-3">
          <table className="min-w-full text-left text-sm font-light desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
            <thead className="border-b font-medium w-fit">
              <tr className="border p-2 bg-gray-300  font-bold font-saira">
                {segmentLabels?.map((column, index) => (
                  <th
                    key={index}
                    scope="col"
                    className=" p-4 font-bold select-none"
                  >
                    {column}
                  </th>
                ))}
              </tr>
            </thead>
            {filterSegments?.length !== 0 && (
              <tbody>
                {filterSegments?.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className="border-b hover:bg-gray-100 cursor-pointer font-roboto transition-all duration-300"
                  >
                    <td
                      className="p-4 px-6 text-sm desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl font-medium select-none"
                      onClick={() => {
                        handlePreviewSegment(row, rowIndex);
                      }}
                    >
                      {row?.name}
                    </td>
                    <td
                      className="p-4 px-6 text-sm desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl font-medium select-none"
                      onClick={() => {
                        handlePreviewSegment(row, rowIndex);
                      }}
                    >
                      {row.type}
                    </td>
                    <td
                      className="p-4 px-6 text-sm desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl font-medium select-none"
                      onClick={() => {
                        handlePreviewSegment(row, rowIndex);
                      }}
                    >
                      {row.from_road}-{row.to_road}
                    </td>
                    <td
                      className="p-4 px-6 text-sm desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl font-medium select-none"
                      onClick={() => {
                        handlePreviewSegment(row, rowIndex);
                      }}
                    >
                      {row.created_on ? row.created_on : "Just Now"}
                    </td>
                    <td className="p-4 text-2xl text-gray-400  ">
                      <Actions
                        hideAdd={true}
                        hidePreview={true}
                        OnDelete={() => {
                          handleDeleteSegment(projectId, row.id);
                        }}
                        deleteToolTip="Delete Segment"
                        // onPreview={() => {
                        //   handlePreviewSegment(rowIndex);
                        // }}
                        onEdit={() => {
                          handleOnEditSegment(row);
                        }}
                        editToolTip="Edit Segment"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
          {loading && <ListSkeleton />}
          {segmentsData?.length === 0 && !loading && (
            <div className="flex flex-row justify-center items-center h-full w-full my-6">
              <p className="font-poppins p-5 text-2xl">No Segments Found!</p>
            </div>
          )}
        </div>
      )}
    </BackendLayout>
  );
};

export default ProjectSegments;

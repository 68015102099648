import React from 'react'

const Seperator = ({ className }) => {

    return (
        <div className="flex justify-center items-center my-10">
            <div className={`${className} border-t-2 rounded-lg   w-4/6 `} ></div>
        </div>
    )

}

export default Seperator
import React, { useEffect, useState } from "react";
import Button from "./Button";
import { BiIcons } from "./Icons";

const api_url = process.env.REACT_APP_API_URL;

const Table = ({ models }) => {
  // const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("estimates"); // 'estimates' or 'boqs'
  const [tableData, setTableData] = useState(models?.estimates);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "boqs") {
      setTableData(models.boqs);
    } else {
      setTableData(models.estimates);
    }
  };

  useEffect(() => { }, [tableData]);

  const getSpan = (key, model) => {
    let colspan = 1;
    if (key === 0 || key === 1) {
      colspan = 12;
    }
    const eValue = model?.E || ""; // If model.E is null or undefined, default to an empty string
    const r = eValue.substring(0, 2);
    if (r[0] && r[1] && model.E !== "S. No.") {
      const first = r[0].replace(/[^a-zA-Z]+/g, "");
      const second = r[1] === ".";
      if (first && second) {
        colspan = 3;
      }
    }
    return colspan;
  };

  const getNumberSpan = (key, model) => {
    if (model.I === "Number") {
      return 3;
    }
  };

  function hanldePrintBOQDrawing() {
    if (models?.drawing) {
      let url = `${api_url}${models?.drawing}`;
      window.open(url, "_blank");
    }
  }

  return (
    <div className="px-5 text-sm ">
      <div className="modal-content">
        <div className="modal-header flex gap-8 py-2">
          <div className="flex gap-2">
            <Button
              onClick={() => handleTabClick("estimates")}
              label="Estimates"
              bgColor={
                activeTab === "estimates" ? "bg-green-500" : "bg-gray-500"
              }
              hoverBg={
                activeTab === "estimates"
                  ? "hover:bg-green-600"
                  : "hover:bg-gray-700"
              }
            />
            {models?.type !== 'road' && <Button
              onClick={() => handleTabClick("boqs")}
              label="BOQs"
              bgColor={activeTab === "boqs" ? "bg-green-500" : "bg-gray-500"}
              hoverBg={
                activeTab === "boqs"
                  ? "hover:bg-green-600"
                  : "hover:bg-gray-700"
              }
            />}
          </div>

          {models?.type !== 'road' && <Button
            onClick={hanldePrintBOQDrawing}
            label="Print Drawing"
            icon={<BiIcons iconName={"BiPrinter"} />}
          />}
        </div>
        <p className="p-2 text-2xl text-primary font-saira decoration underline font-bold">
          {activeTab.toUpperCase()}
        </p>
        <div className="modal-body">
          <table>
            <tbody>
              {tableData.map((model, key) => {
                const colspan = getSpan(key, model);
                const numberSpan = getNumberSpan(key, model);

                return (
                  <tr
                    key={key}
                    className={`border border-black  ${colspan === 12 ? "bg-primary text-white" : null
                      }`}
                  >
                    <td
                      colSpan={colspan}
                      className={`px-5 py-4 w-[12%] text-center font-poppins text-md ${key === 2 && "font-semibold"}`}
                    >
                      <>
                        {activeTab === "boqs" ? <div className="w-full">{model.D}</div> : <div className={`w-full ${key < 2 ? "text-center" : "text-left"}`}>{model.E}</div>}
                      </>
                    </td>
                    {colspan === 12 ? null : (
                      <>
                        <td className={`font-poppins border border-black  px-3 ${key === 2 && "font-semibold"}`}                        >
                          {/* {model.F} */}
                          {activeTab === "boqs" ? <div className="w-24 text-center ">{model.E}</div> : <div className=" w-20 text-center ">{model.F}</div>}

                        </td>
                        <td className={`font-poppins border border-black text-justify    p-3 ${key === 2 && "font-semibold"
                          }`}
                        >
                          {activeTab === "boqs" ? <>{model.F}</> : <div className="w-full">{model.G}</div>}
                        </td>
                        <td className={`font-poppins border border-black  p-3 text-center ${key === 2 && "font-semibold"} `}
                        >
                          {model.H}
                        </td>
                        <td
                          className={`font-poppins border border-black  p-2 text-center ${key === 2 && "font-semibold"
                            }`}
                          colSpan={numberSpan}
                        >
                          {model.I}
                        </td>
                        {model.I !== "Number" ? (
                          <>
                            <td
                              className={`border border-black font-poppins  px-3 py-2 ${key === 2 && "font-semibold"
                                }`}
                            >
                              {model.J}
                            </td>
                            {activeTab === "estimates" && (
                              <td
                                className={`border border-black font-poppins p-3`}
                              >
                                {model.K}
                              </td>
                            )}
                          </>
                        ) : null}

                        {activeTab === "estimates" && (
                          <>
                            <td
                              className={`border border-black font-poppins text-center p-2 ${key === 2 && "font-semibold"
                                }`}
                            >
                              {model.L}
                            </td>
                            <td
                              className={`border border-black font-poppins text-center p-2 ${key === 2 && "font-semibold"
                                }`}
                            >
                              {model.M}
                            </td>

                            {colspan === 3 && model.I !== "Number" ? null : (
                              <>
                                <td
                                  className={`border border-black  font-poppins text-center p-2 ${key === 2 && "font-semibold"
                                    }`}
                                >
                                  {model.N}
                                </td>
                                <td
                                  className={`border border-black  font-poppins text-center p-2 ${key === 2 && "font-semibold"
                                    }`}
                                >
                                  {model.O}
                                </td>
                                <td
                                  className={`border border-black  font-poppins p-2 ${key === 2 && "font-semibold"
                                    }`}
                                >
                                  {model.P}
                                </td>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Table;

import React, { useState } from 'react';

const DrawingInput = ({ label = "Label", onChange, onBlur, imgName, value, inputId, ...props }) => {

    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState(value || "");


    const handleFocus = () => {
        console.log("focusted")
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        console.log("blur")
        setIsFocused(inputValue.trim() !== "");
        onBlur(e);
        //handleChange(e);
    };

    const handleChange = (e) => {

        const updatedValue = e.target;

        setInputValue("updatedValue");
        onChange && onChange(updatedValue);

        setIsFocused(updatedValue.value.trim() !== "");
    };
    const changedFromOther = (value !== "" && value !== undefined);

    return (
        <div className="relative ">
            <label
                htmlFor={inputId}
                className={`transition-all flex items-center z-0 select-none text-sm desktopMd:text-lg desktopXmd:text-2xl desktopLg:text-3xl desktopXlg:text-4xl cursor-text   ${isFocused || changedFromOther ? '  -translate-y-5  text-primary desktopMd:-translate-y-9 desktopXmd:-translate-y-9 desktopLg:-translate-y-9 desktopXlg:-translate-y-10 ' : '  text-secondary '} absolute top-1 left-2`}
            >
                {label}
            </label>
            <input
                id={inputId}
                type='text'
                onFocus={handleFocus}
                // onBlur={(e) => handleBlur(e)}
                onChange={(e) => handleChange(e)}
                value={value}
                {...props}
                className="z-10 w-[70px] h-6 px-1 desktopSm:w-20  desktopMd:w-24 desktopXmd:w-32  desktopLg:w-[50%] desktopXlg:w-[60%] desktopMd:h-8 desktopXmd:h-10 desktopLg:h-12 desktopXlg:h-[70px]    flex items-center border border-secondary text-sm desktopLg:text-xl rounded-md bg-green-50 outline-none focus:outline-none focus:border-primary focus:bg-white transition-all duration-300"
            />
        </div>
    );
};

export default DrawingInput;

import React from "react";
import SubHeader from "../components/SubHeader";
import Sidebar from "../components/Sidebar";
import { useSelector } from "react-redux";

export default function BackendLayout({ children, ...props }) {
  const userInfo = useSelector((state) => state.user);

  let userName = userInfo?.name;
  if (userName == undefined) {
    userName = "- -";
  }
  const initials = userName
    .split(" ")
    .map((word) => word.slice(0, 1).toUpperCase())
    .join("")
    .slice(0, 3);

  return (
    <div className="flex h-full ">
      <Sidebar />

      <div className="flex flex-col w-full overflow-auto  ">
        <SubHeader
          screenName={props.screenName}
          userName={userName}
          userImg={initials}
        />
        {children}
      </div>
    </div>
  );
}

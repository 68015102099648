import React, { useEffect } from 'react';
import { Stepper, Step } from 'react-form-stepper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

const FormStepper = ({ children, dontShow }) => {
    const dispatch = useDispatch();
    const { activeStep } = useSelector((state) => state.misc);
    // const [activeStep, setActiveStep] = useState(0);

    const totalSteps = React.Children.count(children);

    useEffect(() => {
        dispatch({ type: "misc/setFormTotalSteps", payload: { value: totalSteps } });
    }, [dispatch]);

    const handleNext = () => {
        // setActiveStep((prevStep) => Math.min(prevStep + 1, totalSteps - 1));
        dispatch({ type: "misc/setFormStep", payload: { value: activeStep + 1 } });
    };

    const handlePrev = () => {
        // setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
        dispatch({ type: "misc/setFormStep", payload: { value: activeStep - 1 } });
    };

    const jumpToStep = (step) => {
        dispatch({ type: "misc/setFormStep", payload: { value: step } });
    };

    const { projectId } = useParams();
    const projectData = useSelector((state) =>
        state.project.projects?.find((p) => p.id === parseInt(projectId, 10))
    );


    if (!projectData) {
        return <div>Project not found</div>;
    }


    const steps = React.Children.map(children, (child, index) => ({
        title: `${index + 1}`,
        content: child.props.children,
        label: child.props.label,
        customLabel: child.props.customLabel,
    }));



    return (
        <div className='py-2'>
            {!dontShow && <div className='flex flex-row gap-3 p-2 font-roboto'>
                <div className='p-2 desktopMd:p-4 desktopXmd:p-8 text-gray-400 text-sm desktopMd:text-2xl desktopXmd:text-4xl flex-1' ><span className='p-1'>Home |</span><Link className='p-1' to={"/projects"}>Projects |</Link><Link className='p-1' to={`/projects/${projectId}`}>{projectData.name} |</Link><span className='p-1 text-secondary'>{steps[activeStep].label}</span> </div>

            </div>}
            <div className="  font-poppins">
                <div className='flex  justify-center desktopMd:w-full  desktopXmd:w-full items-center'>
                    <Stepper
                        activeStep={activeStep}
                        className={`desktopMd:w-full desktopXmd:text-2xl`}
                        styleConfig={{
                            activeBgColor: '#012D35',
                            activeTextColor: '#fff',
                            completedBgColor: "#fff",
                            completedTextColor: "#10AF60",
                            size: 50,
                            labelFontSize: 18,
                            inactiveBgColor: "#fff",
                            inactiveTextColor: "#000",

                        }}

                        connectorStyleConfig={{

                            size: 4,
                            stepSize: "40px",
                            activeColor: "#083344",
                            completedColor: "#00ff00",
                            style: "solid",


                        }}
                    >
                        {steps.map((step, index) => (
                            <Step
                                key={index}
                                onClick={() => jumpToStep(index)}
                                style={{ border: "solid", borderColor: "#10AF60", borderWidth: "1px" }}
                                label={step.label}
                            >
                                {activeStep > index ? (
                                    <span>✔</span>
                                ) : (
                                    <span>{step.title}</span>
                                )}
                            </Step>
                        ))}
                    </Stepper>
                </div>
                <div>
                    <div className="navigation-buttons flex flex-row gap-3 p-2">
                        <div className='flex flex-1 p-2 items-center font-bold text-xl px-5 desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl font-poppins'>
                            {steps[activeStep].customLabel ? steps[activeStep].customLabel : steps[activeStep].label}
                        </div>
                        {/* <div className='flex flex-grow justify-end desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl p-2 items-center '>
                            <button
                                onClick={handlePrev}
                                disabled={activeStep === 0}
                                className='p-2  cursor-pointer text-gray-400  hover:text-primary'
                            >
                                Prev
                            </button>
                            <span>|</span>
                            <button
                                onClick={handleNext}
                                disabled={activeStep === totalSteps - 1}
                                className='p-2 text-gray-400 cursor-pointer  hover:text-primary' >
                                Next
                            </button>
                        </div> */}
                    </div>
                    <div className="overflow-auto">
                        {steps[activeStep].content}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default FormStepper;

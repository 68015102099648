import React from 'react'

const Input = ({ width = "w-full", className, focusBorder = "focus:border-primary", ...props }) => {
    let styling = className;
    styling += ` ${width}`
    return (
        <input className={`p-2 focus:outline-none font-poppins text-primary ${focusBorder} border-b-2 border-gray-300  ${styling}`} {...props} />
    )
}

export default Input
import React, { useState } from "react";
import BackendLayout from "../components/BackendLayout";
import Label from "../components/Label";
import Input from "../components/Input";
import { AiIcons } from "../components/Icons";
import Button from "../components/Button";
import { IoKey } from "react-icons/io5";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { STATUS_CODE } from "../helpers";

const api_url = process.env.REACT_APP_API_URL + "/users/change-password";

const Settings = () => {
  const hideIcon = "AiFillEyeInvisible";
  const showIcon = "AiFillEye";
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confirmNewPassword: false,
  });
  const [user, setUser] = useState({
    current_password: "",
    newPassword: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user);

  function togglePassword(field) {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  }

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isValid = user.newPassword === user.password;
    if (!isValid) {
      setLoading(false);
      return Swal.fire({
        position: "top",
        icon: "error",
        toast: true,
        title: "Passwords do not match",
        showConfirmButton: false,
        timer: 1500,
      });
    }

    const formData = new FormData(e.target);
    const payloadData = Object.fromEntries(formData.entries());

    try {
      await axios.post(api_url, user).then((response) => {
        setLoading(false);
        Swal.fire({
          position: "top",
          icon: "success",
          toast: true,
          title: "Successfully Changed Password",
          showConfirmButton: false,
          timer: 1500,
        });
      });
    } catch (error) {
      console.log("🚀 ~ handleLogin ~ error:", error);
      setLoading(false);
      // Swal.fire({
      //   position: "top",
      //   icon: "error",
      //   toast: true,
      //   title:
      //     error?.code !== STATUS_CODE.ERR_NETWORK
      //       ? Object.values(error).length > 0
      //         ? Object.values(JSON.parse(error?.response?.data?.message))
      //             .map((msg) => msg)
      //             .join("\n")
      //         : JSON.stringify(error)
      //       : "Network Error!",
      //   showConfirmButton: false,
      //   timer: 1500,
      // });
    }
  };

  return (
    <BackendLayout screenName="Settings">
      <div className="flex w-full h-full items-center justify-center">
        <div className="flex flex-col bg-white rounded-md shadow w-10/12 my-6">
          <div className="flex flex-row items-center bg-primary p-3 w-full rounded-tl-md rounded-tr-md">
            <IoKey className="text-white text-2xl" />
            <p className="font-poppins text-white text-lg mx-6">
              Update User Info
            </p>
          </div>
          <form onSubmit={(e) => handleLogin(e)}>
            <div className="flex flex-col items-center gap-5 p-4">
              <div className="flex flex-col w-1/2 gap-1">
                <Label>Current Password</Label>
                <Input
                  type="password"
                  required
                  name="current_password"
                  placeholder="Current Password"
                  className="h-10 w-auto font-poppins"
                  value={user.current_password}
                  onChange={(e) => {
                    setUser((prevData) => ({
                      ...prevData,
                      current_password: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="flex flex-col gap-1 h-full w-1/2">
                <Label>Password</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type={showPassword.newPassword ? "password" : "text"}
                    required
                    name="newPassword"
                    placeholder="New Password"
                    className="h-10 font-poppins"
                    // onKeyPress={handleLoginKeyPress}
                    onChange={(e) =>
                      setUser((prevData) => ({
                        ...prevData,
                        newPassword: e.target.value,
                      }))
                    }
                  />
                  <button
                    className="flex justify-center items-center focus:outline-none text-gray-500 "
                    onClick={(e) => {
                      togglePassword("newPassword");
                    }}
                  >
                    {showPassword.newPassword ? (
                      <AiIcons iconName={hideIcon} />
                    ) : (
                      <AiIcons iconName={showIcon} />
                    )}
                  </button>
                </div>
              </div>

              <div className="flex flex-col gap-1 h-full w-1/2">
                <Label>Confirm New Password</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type={showPassword.confirmNewPassword ? "password" : "text"}
                    required
                    name="password"
                    placeholder="Confirm New Password"
                    className="h-10 font-poppins"
                    onChange={(e) => {
                      setUser((prevData) => ({
                        ...prevData,
                        password: e.target.value,
                      }));
                    }}
                  />
                  <button
                    className="flex justify-center items-center focus:outline-none text-gray-500 "
                    onClick={(e) => {
                      togglePassword("confirmNewPassword");
                    }}
                  >
                    {showPassword.confirmNewPassword ? (
                      <AiIcons iconName={hideIcon} />
                    ) : (
                      <AiIcons iconName={showIcon} />
                    )}
                  </button>
                </div>
              </div>

              <div className="w-1/2">
                <Button
                  label={"Update Password"}
                  loading={loading}
                  className={"w-full hover:bg-orange-400 rounded-lg"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </BackendLayout>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaIcons } from "../components/Icons";
import BackendLayout from "../components/BackendLayout";
import SearchBar from "../components/SearchBar";
import FilterBtn from "../components/FilterBtn";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { WeatherCondition, WeekDays, round } from "../helpers";
import Select from "react-select";
import two_Ax_Single_Bedford from "../assets/esel_truck/2-Ax_Single_Bedford.svg";
import three_Ax_Single from "../assets/esel_truck/3-Ax Single.svg";
import three_Ax_Tendem from "../assets/esel_truck/3-Ax Tandem.svg";
import four_Ax_Single from "../assets/esel_truck/4-Ax Single.svg";
import four_Ax_Single_Tendem from "../assets/esel_truck/4-Ax Single-Tandem.svg";
import four_Ax_Single_Single_Tendem from "../assets/esel_truck/4-Ax_Tandem-Single.svg";
import five_Ax_Single_Single_Tandem from "../assets/esel_truck/5-Ax Single-Single-Tandem.svg";
import five_Ax_Single_Tridem from "../assets/esel_truck/5-Ax Single-Tridem.svg";
import five_Ax_Tandem_Single_Single from "../assets/esel_truck/5-Ax Tandem-Single-Single.svg";
import five_Ax_Tandem_Tandem from "../assets/esel_truck/5-Ax Tandem-Tandem.svg";
import six_Ax_Tandem_Tridem from "../assets/esel_truck/6-Ax Tandem-Tridem.svg";
import six_Ax_Tandem_Single_Tandem from "../assets/esel_truck/6-Ax Tandem-Single-Tandem 1.svg";
import four_WD from "../assets/esel_truck/4WD.svg";
import bus from "../assets/esel_truck/Bus.svg";
import car from "../assets/esel_truck/Car.svg";
import Hiace from "../assets/esel_truck/Hiace.svg";
import Minibus from "../assets/esel_truck/Minibus.svg";
import Motorcycle from "../assets/esel_truck/Motorcycle.svg";
import Rikshaw from "../assets/esel_truck/Rikshaw.svg";
import Tractor_Trolly from "../assets/esel_truck/Tractor_Trolley.svg";

const Adt = () => {
  const adt = useSelector((state) => state.road.adt);
  const dispatch = useDispatch();
  const plusIcon = "FaPlus";
  let count = 1;
  const traffic = {
    1: { label: "Passenger cars", icon: car },
    2: { label: "Buses", icon: bus },
    3: { label: "Tractor trolley", icon: Tractor_Trolly },
    4: { label: "Pickup/Jeep/4WD", icon: four_WD },
    5: { label: "Motorcycle", icon: Motorcycle },
    6: { label: "Rickshaw", icon: Rikshaw },
    7: { label: "Van/Hiace", icon: Hiace },
    8: { label: "Minibus/Flying coach", icon: Minibus },
    9: { label: "2-Ax Single (Bedford)", icon: two_Ax_Single_Bedford },
    10: { label: "2-Ax Single (Hino/Nissan)", icon: two_Ax_Single_Bedford },
    11: { label: "3-Ax Tandem", icon: three_Ax_Tendem },
    12: { label: "3-Ax Single", icon: three_Ax_Single },
    13: { label: "4-Ax Single-Tandem", icon: four_Ax_Single_Tendem },
    14: { label: "4-Ax Tandem-Single", icon: four_Ax_Single_Single_Tendem },
    15: { label: "4-Ax Single", icon: four_Ax_Single },
    16: { label: "5-Ax Single-Tridem", icon: five_Ax_Single_Tridem },
    17: { label: "5-Ax Tandem-Tandem", icon: five_Ax_Tandem_Tandem },
    18: {
      label: "5-Ax Single-Single-Tandem",
      icon: five_Ax_Single_Single_Tandem,
    },
    19: {
      label: "5-Ax Tandem-Single-Single",
      icon: five_Ax_Tandem_Single_Single,
    },
    20: { label: "6-Ax Tandem-Tridem", icon: six_Ax_Tandem_Tridem },
    21: {
      label: "6-Ax Tandem-Single-Tandem",
      icon: six_Ax_Tandem_Single_Tandem,
    },
  };
  const handleChange = (e, inputIndex, key) => {
    const { value } = e.target;
    dispatch({ type: "road/changeAdt", payload: { value, key, inputIndex } });
  };
  return (
    <BackendLayout screenName="Modular Design ">
      <div className="flex flex-col">
        <Breadcrumb items={[{ itemName: "Home", itemLink: "" }, { itemName: "Weekly ADT Count", itemLink: "" },]}
        />
        <p className="ml-5 font-poppins font-semibold text-2xl mt-2   desktopSm:text-3xl desktopMd:text-4xl desktopXmd:text-5xl desktopLg:text-6xl desktopXlg:text-7xl">
          TRAFFIC DATA - WEEKLY COUNT SHEET
        </p>
      </div>
      <table className="border-b border-solid m-6 text-sm">
        <tbody>
          <tr className="border desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold"
            >
              Location
              <input
                value={0}
                onChange={(e) => { }}
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                type="text"
              />
            </td>
            <td
              colSpan="4"
              className="px-2 py-2 font-poppins font-semibold"
            >
              <div className="flex flex-row items-center space-x-2">
                <p className="font-poppins font-semibold">Day</p>
                <Select
                  className="border-gray-300 border-b-2 rounded-sm text-ternayTint font-poppins font-semibold w-2/4   "
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#F1F9F8",
                      fontSize: "80%",
                    }),
                  }}
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder="Day"
                  placeholderClass="desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl"
                  options={WeekDays}
                  onChange={(e) => { }}
                />
              </div>
            </td>
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold"
            >
              Direction
              <input
                value={0}
                onChange={(e) => { }}
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                type="text"
              />
            </td>
          </tr>
          <tr className="border desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold"
            >
              Date
              <input
                value={0}
                onChange={(e) => { }}
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                type="text"
              />
            </td>
            <td
              colSpan="4"
              className="space-x-2 px-2 py-2 font-poppins font-semibold"
            >
              Hour
              <input
                value={0}
                onChange={(e) => { }}
                className="mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-b-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                type="text"
              />
            </td>
          </tr>
          <tr className="border desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td
              colSpan="2"
              className="font-poppins font-semibold text-md text-center"
            >
              Weather Condition
            </td>
            <td colSpan="4" className="py-2">
              <Select
                className="border-gray-300 border-b-2 rounded-sm text-ternayTint font-poppins font-semibold w-2/4"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                    backgroundColor: "#F1F9F8",
                    fontSize: "80%",
                  }),
                }}
                classNamePrefix="select"
                isSearchable={true}
                placeholder="Weather Condition"
                options={WeatherCondition}
                onChange={(e) => { }}
              />
            </td>
          </tr>
          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td className="border-t border-r font-poppins font-semibold text-center">
              Sr/No
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Vehicle Type
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Illustration
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Monday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Tuesday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Wednesday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Thursday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Friday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Saturday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Sunday
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              Weekly Total
            </td>
            <td className="border-t border-r font-poppins font-semibold text-center">
              ADT
            </td>
          </tr>
          {/* <?php foreach ($model->traffic as $key => $traffic) { ?> */}
          {Object.entries(traffic).map(([key, entry]) => {
            const total = adt[key]
              ? Object.values(adt[key]).reduce(
                (acc, item) => parseFloat(acc) + parseFloat(item),
                0
              )
              : 0;
            const counts = adt[key] ? Object.keys(adt[key]).length : 0;
            const average = total > 0 ? round(total / counts) : 0;
            return (
              <tr key={key} className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
                <td className="py-3 px-4 border-b border-t border-r">{key}</td>
                <td
                  className="py-3 px-4 border-b border-t border-r"
                  style={{ minWidth: "150px" }}
                >
                  <label>{entry.label}</label>
                </td>
                <td
                  style={{ minWidth: "80px" }}
                  className="input-cell py-3 px-4 border-b border-t border-r"
                >
                  <img
                    style={{
                      transform:
                        key === "3" || key === "5" ? null : "scalex(-1)",
                    }}
                    src={entry.icon}
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][1] : 0}
                    onChange={(e) => handleChange(e, 1, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][2] : 0}
                    onChange={(e) => handleChange(e, 2, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][3] : 0}
                    onChange={(e) => handleChange(e, 3, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][4] : 0}
                    onChange={(e) => handleChange(e, 4, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][5] : 0}
                    onChange={(e) => handleChange(e, 5, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][6] : 0}
                    onChange={(e) => handleChange(e, 6, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td
                  style={{ maxWidth: "110px" }}
                  className="input-cell border-b border-t border-r"
                >
                  <input
                    value={adt[key] ? adt[key][7] : 0}
                    onChange={(e) => handleChange(e, 7, key)}
                    className="w-4/5 mx-2 px-1 bg-ternary bg-opacity-45 rounded-sm border-2 focus:border-secondary focus:outline-none text-ternaryTint py-1"
                    type="text"
                  />
                </td>
                <td className="py-3 px-4 border-b border-t border-r">
                  <span className="total">{total}</span>
                </td>
                <td className="py-3 px-4 border-b border-t border-r">
                  <span className="average">{average}</span>
                </td>
              </tr>
            );
          })}
          {/* <? php } ?> */}

          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
          </tr>
          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
            <td>
              <br />
            </td>
          </tr>
          <tr className="border-l desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl">
            <td colSpan="4" className="text-left">
              Collected By:
            </td>
            <td colSpan="8" className="text-left">
              Signature of concerned officer:
            </td>
          </tr>
        </tbody>
      </table>
    </BackendLayout>
  );
};

export default Adt;

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaIcons } from "../components/Icons";
import ProjectList from "../components/ProjectList";
import BackendLayout from "../components/BackendLayout";
import SearchBar from "../components/SearchBar";
import FilterBtn from "../components/FilterBtn";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const Project = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const pagination = useSelector((state) => state?.project?.pagination);

  const plusIcon = "FaPlus";
  const navigate = useNavigate();

  useEffect(() => {

    dispatch({
      type: "project/fetchProjects",
      payload: { url: "/projects", page: 1 },
    });
    Swal.fire({
      title: "Loading Projects...",
      allowEscapeKey: false,

      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
      timer: 3000,
      // timerProgressBar: true
    });
  }, []);

  // const handleProjectClick = (projectId) => {
  //   navigate(`/projects/${projectId}`);
  // };

  return (
    <BackendLayout screenName="Modular Design ">
      <div className="flex flex-row items-center ">
        <Breadcrumb
          items={[
            { itemName: "Home", itemLink: "" },
            { itemName: "Projects", itemLink: "/projects" },
          ]}
        />

        <div className="flex flex-grow flex-row  gap-4 items-center justify-end p-2 font-poppins">
          <Link to={"add-project"}>
            <Button
              label={"Add a New Project"}
              icon={<FaIcons iconName={plusIcon} />}
              className={"w-fit rounded-lg"}
            />
          </Link>
        </div>
      </div>

      <div className="flex flex-row p-6 items-center">
        <SearchBar search={search} setSearch={setSearch} />
        {/* <FilterBtn /> */}
      </div>
      <ProjectList search={search} />
    </BackendLayout>
  );
};

export default Project;

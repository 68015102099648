import React from 'react'

const CustomTableAndHeaders = ({ headers, className, width = 'w-full', height = 'h-fit', ThStyle = "font-bold", TrStyle, children }) => {
    let styling = className;
    styling += ` ${width} ${height}`;
    return (

        <div className={`${styling}`} >
            <table className={` ${width}`}>
                <thead>
                    <tr className={` ${TrStyle} font-saira`} >
                        {headers.map((column, index) => (
                            <th key={index} className={`p-2 border-r-2  ${ThStyle}`}>{column}</th>
                        ))}
                    </tr>
                </thead>
                <tbody className={`border border-gray-200 ${width} font-roboto`}>
                    {children}
                </tbody>
            </table>
        </div>
    )
}

export default CustomTableAndHeaders
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const ToolsDropdown = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const togggleDropdown = (r) =>{
      setIsDropdownOpen(r);
    }
  return (
    <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md select-none">
    <Link to="#" onClick={()=>togggleDropdown(false)} onMouseOver={()=>togggleDropdown(true)}>
      PIM Tools
      {isDropdownOpen && (
        <div onMouseOut={()=>togggleDropdown(false)} className="absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            <Link
              to="/download-doc/Standardised_Modular_Designs"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Standardised Modular Designs
            </Link>
            <Link
              to="/download-doc/Bridge_Code"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Bridge Code
            </Link>
            <Link
              to="/download-doc/Technical_Appresial"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Technical Apprasial Checklist
            </Link>
            <Link
              to="/download-doc/Material_Testing"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Materials and Qualification Testing
            </Link>
            <Link
              to="/download-doc/CRI_Guidelines_Handbook"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Climate Resilient Infrastructure (CRI) Handbook
            </Link>
            <Link
              to="/download-doc/Project_Implementation"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Project Implementation Policy
            </Link>
            <Link
              to="/download-doc/Mode_Tors"
              className="hover:text-primary active:text-primary block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Model TORs
            </Link>
          </div>
        </div>
      )}
    </Link>
  </ul>
  )
}

export default ToolsDropdown
import React from 'react'

const AbstractCostTableValues = ({ billNumber, billDescription, billCost }) => {
   
    return (
        <tr>
            <td className="text-base text-center border border-primary">

                {billNumber ?? []}

            </td>
            <td
                className="text-base  text-left px-10 border border-primary"
                colSpan={2}
            >
                {billDescription ?? []}

            </td>
            <td className="text-base text-center border border-primary">

                {billCost ?? []}

            </td>
            <td className="text-base text-center border border-primary"></td>
        </tr>

    )
}

export default AbstractCostTableValues
import React, { useEffect, useReducer, useState } from "react";
import BackendLayout from "../components/BackendLayout";
import { FiEdit2 } from "react-icons/fi";
import profile_pic from "../assets/profile_pic.png";
import { Link } from "react-router-dom";
import { BiIcons } from "../components/Icons";
import Button from "../components/Button";
import Select from "react-select";

import verified_icon from "../assets/verified_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { MRS_Years, CitiesListOfKPK, getInitialsFromName } from "../helpers";
import Modal from "../components/ui/Modal";
import Label from "../components/Label";
import Input from "../components/Input";
import axios from "axios";
import Swal from "sweetalert2";
import { IoCloseCircleOutline } from "react-icons/io5";
import { userLogin } from "../store/actions";

const api_url = process.env.REACT_APP_API_URL + "/users/";

export default function Profile() {
  const userInfo = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const editIcon = "BiEdit";
  const [showOtherDetails, setShowotherDetails] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const initials = getInitialsFromName(userInfo);
  const [state, updateState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      full_name: userInfo?.name,
      pec_number: userInfo?.pec_number,
      organization: userInfo?.organization,
      city: userInfo?.city,
      mrs_year: userInfo?.mrs_year,
    }
  );

  useEffect(() => {
    updateState({
      full_name: userInfo?.name,
      pec_number: userInfo?.pec_number,
      organization: userInfo?.organization,
      city: userInfo?.city,
      mrs_year: userInfo?.mrs_year,
    });
  }, [userInfo]);

  const handleShowOtherDetails = () => {
    return setShowotherDetails(!showOtherDetails);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo?.access_token}`,
    },
  };

  const handleUpdateUserProfile = async (e) => {
    e.preventDefault();
    await axios
      .put(
        `${api_url}${userInfo.id}`,
        {
          ...state,
          id: userInfo.id,
          email: userInfo.email,
        },
        config
      )
      .then((response) => {
        dispatch(
          userLogin({
            access_token: userInfo.access_token,
            city: response.data.city,
            email: response.data.email,
            id: response.data.id,
            mrs_year: response.data.mrs_year,
            name: response.data.name,
            organization: response.data.organization,
            pec_number: response.data.pec_number,
          })
        );
        setShowModal(false);
        Swal.fire({
          position: "top",
          icon: "success",
          toast: true,
          title: "Profile Updated Successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top",
          icon: "error",
          toast: true,
          title: JSON.stringify(error),
          showConfirmButton: false,
          timer: 1500,
        });
        console.error("Error:", error);
      });
  };
  return (
    <BackendLayout screenName="My Profile">
      {showModal && (
        <Modal onClose={() => setShowModal(false)}>
          <div className="flex flex-row justify-between items-center mb-2">
            <h2 className="text-xl flex flex-1 justify-start font-saira text-primary">
              Edit Profile
            </h2>
            <IoCloseCircleOutline
              onClick={() => setShowModal(false)}
              size={32}
              color="#FF0000"
            />
          </div>
          <div className="flex flex-col space-y-4 ">
            <div className="flex flex-row gap-1 h-full w-full ">
              <Input
                type="text"
                required
                placeholder="Full Name"
                name="full_name"
                className="h-10 text-sm text-gray-400 bg-ternary focus:outline-none focus:border-secondary rounded-sm"
                value={state?.full_name}
                onChange={(e) => updateState({ full_name: e.target.value })}
              />
            </div>
            <div className="flex flex-row gap-1 h-full w-full ">
              <Input
                type="text"
                required
                placeholder="PEC Number"
                name="pec_number"
                className="h-10 text-sm text-gray-400 bg-ternary focus:outline-none focus:border-secondary rounded-sm"
                value={state?.pec_number}
                onChange={(e) => updateState({ pec_number: e.target.value })}
              />
            </div>

            <Select
              className="border-gray-300 border-b-2 text-primary font-poppins"
              styles={{
                control: (base) => ({
                  ...base,
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "#F1F9F8",
                  fontSize: "1rem",
                }),
              }}
              classNamePrefix="select"
              placeholder="Mrs Year"
              isSearchable={true}
              options={MRS_Years}
              value={MRS_Years.find(
                (year) => year.value === state?.mrs_year?.toString()
              )}
              onChange={(e) => updateState({ mrs_year: e.value })}
            />

            <div className="flex flex-row gap-1 h-full w-full ">
              <Input
                type="text"
                required
                name="organization"
                placeholder="Organization Name"
                autoComplete="name"
                className="h-10 text-sm text-gray-400 bg-ternary focus:outline-none focus:border-secondary rounded-sm"
                value={state?.organization}
                onChange={(e) => updateState({ organization: e.target.value })}
              />
            </div>

            <Select
              className="border-gray-300 border-b-2 text-primary font-poppins"
              styles={{
                control: (base) => ({
                  ...base,
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "#F1F9F8",
                  fontSize: "1rem",
                }),
              }}
              classNamePrefix="select"
              placeholder="Select City"
              isSearchable={true}
              options={CitiesListOfKPK}
              value={CitiesListOfKPK.find((city) => city.value === state?.city)}
              onChange={(e) => updateState({ city: e.value })}
            />
            <Button
              label="Save Changes"
              bgColor="bg-primary"
              textColor="text-white"
              className="h-10 rounded-md focus:outline-none my-2"
              onClick={(e) => handleUpdateUserProfile(e)}
            />
          </div>
        </Modal>
      )}
      {showOtherDetails && (
        <div
          className={`flex  flex-row w-full p-24  gap-10 font-poppins bg-repeat-x  bg-contain bg-profile-bg  `}
        >
          <div
            className="relative flex flex-col flex-1 justify-center p-10 gap-5 bg-white border border-gray-300  rounded-lg "
            onClick={handleShowOtherDetails}
          >
            <div className="relative flex items-center justify-center">
              <div
                className={`bg-primary rounded-full flex items-center justify-center w-28 h-28   desktopMd:w-24 desktopMd:h-24 desktopXmd:w-28 desktopXmd:h-28 desktopLg:w-36 desktopLg:h-36 `}
              >
                <span className="flex items-center justify-center w-10/12 text-xl desktopSm:text-2xl  desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl text-center text-white">
                  {initials}
                </span>
              </div>
              <div className="absolute -mt-24 ml-[80px]">
                <img src={verified_icon} alt="verified_icon" width={30} />
              </div>
            </div>

            <div className="flex flex-col flex-none gap-2 p-2">
              <div className="p-1 text-center text-primary font-bold text-base flex flex-row justify-center items-center desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
                {userInfo?.name}
              </div>
              <div className="p-1 text-center text-primary text-sm desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
                {userInfo?.email}
              </div>
            </div>
          </div>

          <div className="flex flex-grow flex-col rounded-lg border bg-white border-gray-300 w-full ">
            <div className="flex flex-row bg-gray-200 p-3 w-full desktopMd:p-5 desktopXmd:p-7 desktopLg:p-10 desktopXlg:p-12 ">
              <div className="flex flex-grow p-2 text-primary font-bold  text-xl font-poppins desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
                Other Details
              </div>
              <Link
                onClick={() => setShowModal(true)}
                className="flex-1 flex justify-end items-center text-gray-500 gap-1 text-sm p-2 desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl"
              >
                <FiEdit2 /> Edit
              </Link>
            </div>
            <div className="flex flex-row px-4 font-roboto desktopMd:px-7 desktopXmd:p-10 desktopLg:p-12 desktopXlg:p-16 desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
              <div className="flex flex-col flex-1 p-2 gap-4 desktopMd:gap-6 desktopXmd:gap-8 desktopLg:gap-10 desktopXlg:gap-12">
                <div className="flex flex-col ">
                  <label className="text-slate-500 p-2">Full Name</label>
                  <span className="h-10 w-50 focus:outline-none p-2  text-primary   ">
                    {userInfo?.name}
                  </span>
                </div>
               {/* <div className="flex flex-col ">
                  <label className="text-slate-500 p-2">MRS Year</label>
                  <span className="h-10 w-50 focus:outline-none p-2  text-primary   ">
                    {userInfo?.mrs_year}
                  </span>
                </div>
                */}
                <div className="flex flex-col ">
                  <label className="text-slate-500 p-2">City</label>
                  <span className="h-10 w-50 focus:outline-none p-2  text-primary   ">
  
                    {userInfo?.city}
                  </span>
                </div>
              </div>
              <div className="flex flex-col flex-1 p-2 gap-4">
                <div className="flex flex-col ">
                  <label className="text-slate-500 p-2">PEC Number</label>
                  <span className="h-10 w-50 focus:outline-none p-2  text-primary   ">
  
                    {userInfo?.pec_number}
                  </span>
                </div>
                <div className="flex flex-col ">
                  <label className="text-slate-500 p-2">
                    Organization Name
                  </label>
                  <span className="h-10 w-50 focus:outline-none p-2  text-primary   ">
  
                    {userInfo?.organization}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!showOtherDetails && (
        <div className="flex flex-col gap-5 justify-center h-full items-center font-poppins bg-contain bg-repeat-x bg-profile-bg">
          <div
            className="flex flex-col justify-center items-center py-20 px-20 gap-10 bg-white border border-gray-300 rounded-lg"
            onClick={handleShowOtherDetails}
          >
            <div className="relative rounded-full">
              <div className="absolute ml-20 mt-0">
                <img src={verified_icon} alt="verified_icon" width={30} />
              </div>
              <div className="bg-primary rounded-full flex items-center justify-center w-28 h-28   desktopMd:w-24 desktopMd:h-24 desktopXmd:w-28 desktopXmd:h-28 desktopLg:w-36 desktopLg:h-36">
                <span className="flex items-center justify-center w-10/12 text-xl desktopSm:text-2xl  desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl text-center text-white">
                  {initials}
                </span>
              </div>
            </div>

            <div className="flex flex-col flex-none gap-5  p-2">
              <div className="p-1 text-center text-primary font-bold text-base flex flex-row justify-center items-center">
                {userInfo?.name}
              </div>
              <div className="p-1 text-center text-primary text-sm">
                {userInfo?.email}
              </div>
            </div>
          </div>
          <Button
            icon={<BiIcons iconName={editIcon} />}
            label={"Edit My Profile"}
            // onClick={(e) => handleUpdateUserProfile(e)}
            onClick={() => setShowModal(true)}
          />
        </div>
      )}
    </BackendLayout>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { formatNumberWithCommas, round, sumKValues, transformArrayToObject } from "../../helpers/index"
import { act } from "react-dom/test-utils";

export const projectSlice = createSlice({
  name: "project",
  initialState: {
    loading: false,
    created: false,
    creating: false,
    pagination: { page: 1, currentPage: 1 },
    userMessages: [],
  },

  segmentInfo: null,
  reducers: {
    fetchProjects(state, action) {
      return {
        loading: true,
        ...state,
      };
    },
    // fetchProjectSuccess(state, action) {
    //   const projects = action.payload;
    //   for (let projectId in projects) {
    //     const project = projects[projectId];
    //     for (let segmentId in project.segments) {
    //       const segment = project.segments[segmentId];
    //       if (segment.type === "road") {
    //         const bill1 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill1_estimates"));
    //         const bill2 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill2_estimates"));
    //         const bill3 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill3_estimates"));
    //         const bill5 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill5_estimates"));
    //         const bill6 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill6_estimates"));
    //         const bill7 = transformArrayToObject(segment.estimates.filter((item) => item.page_type === "bill7_estimates"));
    //         console.log(formatNumberWithCommas(round(sumKValues(bill1), 0)));
    //         return {
    //           ...state,
    //           RoadBill1AbstractCost: formatNumberWithCommas(round(sumKValues(bill1), 0)),
    //           RoadBill2AbstractCost: formatNumberWithCommas(round(sumKValues(bill2), 0)),
    //           RoadBill3AbstractCost: formatNumberWithCommas(round(sumKValues(bill3), 0)),
    //           RoadBill5AbstractCost: formatNumberWithCommas(round(sumKValues(bill5), 0)),
    //           RoadBill6AbstractCost: formatNumberWithCommas(round(sumKValues(bill6), 0)),
    //           RoadBill7AbstractCost: formatNumberWithCommas(round(sumKValues(bill7), 0)),

    //         }

    //       }
    //       else {

    //       }
    //     }
    //   }
    //   return {
    //     ...state,
    //     loading: false,
    //     created: false,
    //     projects,
    //   };
    // },

    fetchProjectSuccess(state, action) {
      const projects = action.payload;
      let updatedProjects = [];

      for (let projectId in projects) {
        const project = projects[projectId];
        let bill1Sum = 0;
        let bill2Sum = 0;
        let bill3Sum = 0;
        let bill5Sum = 0;
        let bill6Sum = 0;
        let bill7Sum = 0;
        let AlliedTBridgeSum = 0;
        let AlliedSlabBridgeSum = 0;
        let AlliedVentedCausewaySum = 0;
        let AlliedClosedCausewaySum = 0;
        let AlliedBoxCulvertSum = 0;
        let AlliedSlabCulvertSum = 0;
        let AlliedGravityInSum = 0;
        let AlliedGravityOutSum = 0;
        let AlliedRetainingCounterfortSum = 0;
        let AlliedRetainingCanteliverSum = 0;



        for (let segmentId in project?.segments) {
          const segment = project?.segments[segmentId];
          if (segment.type === "road") {
            const bill1 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill1_estimates"));
            const bill2 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill2_estimates"));
            const bill3 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill3_estimates"));
            const bill5 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill5_estimates"));
            const bill6 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill6_estimates"));
            const bill7 = transformArrayToObject(segment?.estimates.filter((item) => item.page_type === "bill7_estimates"));

            bill1Sum += sumKValues(bill1);
            bill2Sum += sumKValues(bill2);
            bill3Sum += sumKValues(bill3);
            bill5Sum += sumKValues(bill5);
            bill6Sum += sumKValues(bill6);
            bill7Sum += sumKValues(bill7);

          }
          else {
            if (segment.type === "gravity-retaining-wall-sloping-inwards") {
              let boqSumIndex = segment.boqs.length - 3;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedGravityInSum += numericValue;
            }

            else if (segment.type === "gravity-retaining-wall-sloping-outwards") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedGravityOutSum += numericValue;
            }

            else if (segment.type === "t-beam-bridges") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedTBridgeSum += numericValue;
            }

            else if (segment.type === "slab-bridges") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedSlabBridgeSum += numericValue;
            }

            else if (segment.type === "box-culverts") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedBoxCulvertSum += numericValue;
            }

            else if (segment.type === "slab-culverts") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedSlabCulvertSum += numericValue;
            }


            else if (segment.type === "vented-causeways") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedVentedCausewaySum += numericValue;
            }


            else if (segment.type === "closed-causeways") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedClosedCausewaySum += numericValue;
            }


            else if (segment.type === "cantilever-retaining-walls") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedRetainingCanteliverSum += numericValue;
            }
            else if (segment.type === "counterfort-retaining-walls") {
              let boqSumIndex = segment.boqs.length - 2;
              let cleanString = segment.boqs[boqSumIndex].J.replace(/[^0-9.]/g, '');
              let numericValue = parseFloat(cleanString);
              AlliedRetainingCounterfortSum += numericValue;
            }

          }
        }

        const updatedProject = {
          ...project,
          RoadBill1AbstractCost: formatNumberWithCommas(round(bill1Sum, 0)),
          RoadBill2AbstractCost: formatNumberWithCommas(round(bill2Sum, 0)),
          RoadBill3AbstractCost: formatNumberWithCommas(round(bill3Sum, 0)),
          RoadBill5AbstractCost: formatNumberWithCommas(round(bill5Sum, 0)),
          RoadBill6AbstractCost: formatNumberWithCommas(round(bill6Sum, 0)),
          RoadBill7AbstractCost: formatNumberWithCommas(round(bill7Sum, 0)),
          AlliedTBridgeSum: formatNumberWithCommas(round(AlliedTBridgeSum, 0)),
          AlliedSlabBridgeSum: formatNumberWithCommas(round(AlliedSlabBridgeSum, 0)),
          AlliedBoxCulvertSum: formatNumberWithCommas(round(AlliedBoxCulvertSum, 0)),
          AlliedSlabCulvertSum: formatNumberWithCommas(round(AlliedSlabCulvertSum, 0)),
          AlliedVentedCausewaySum: formatNumberWithCommas(round(AlliedVentedCausewaySum, 0)),
          AlliedClosedCausewaySum: formatNumberWithCommas(round(AlliedClosedCausewaySum, 0)),
          AlliedRetainingCanteliverSum: formatNumberWithCommas(round(AlliedRetainingCanteliverSum, 0)),
          AlliedRetainingCounterfortSum: formatNumberWithCommas(round(AlliedRetainingCounterfortSum, 0)),
          AlliedGravityInSum: formatNumberWithCommas(round(AlliedGravityInSum, 0)),
          AlliedGravityOutSum: formatNumberWithCommas(round(AlliedGravityOutSum, 0)),

        };

        updatedProjects.push(updatedProject);
      }

      return {
        ...state,
        loading: false,
        created: false,
        projects: updatedProjects,
      };
    },

    addProject(state, action) {
      // const newProject = action.payload.projectData;
      return {
        ...state,
        loading: true,
        creating: true,
        created: false,
      };
    },
    addProjectSuccess(state, action) {
      const updatedProject = [...state.projects, action.payload.response];
      return {
        ...state,
        creating: false,
        loading: false,
        created: true,
        projects: updatedProject,
      };
    },

    updateProject(state, action) {
      return {
        ...state,
        loading: true,
        creating: true,
        created: false,
      };

    },

    updateProjectSuccess(state, action) {

      return {
        ...state,
        creating: false,
        loading: false,
        created: true,

      };
    },
    deleteProject(state, action) {
      return {
        ...state,
      };
    },

    deleteProjectSuccess(state, action) {
      return {
        ...state,
        projects: state.projects.filter(
          (i) => i.id !== action.payload.projectId
        ),
      };
    },

    addSegmentInfo(state, action) {
      return {
        ...state,
        created: false,
        segmentInfo: action.payload,
      };
    },

    addSegment(state, action) {
      // const { projectId, segmentInputData } = action.payload;
      // const segmentData = {
      //   ...state.segmentInfo,
      //   ...segmentInputData,
      // };

      // const updatedProjects = state.projects.map((project) => {
      //   if (project.id === parseInt(projectId, 10)) {
      //     return {
      //       ...project,
      //       segments: [...project.segments, segmentData],
      //     };
      //   }
      //   return project;
      // });

      return {
        ...state,
        //segmentInfo: null,
        // projects: updatedProjects,
        loading: true,
        created: false,
        creating: true,
      };
    },


    addSegmentSuccess(state, action) {
      const { response } = action.payload;
      const updateProject = state.projects.map((project) => {
        if (project.id === parseInt(response.project_id)) {
          return {
            ...project,
            segments: [...project.segments, response],
          };
        }
        return project;
      });

      return {
        ...state,
        projects: updateProject,
        loading: false,
        created: true,
        creating: false,
      };
    },

    updateSegment(state, action) {

      return {
        ...state,
        loading: true,
        created: false,
        creating: true,
      };
    },

    updateSegmentSuccess(state, action) {
      const { response } = action.payload;
      const updatedProjects = state.projects.map((project) => {
        if (project.id === parseInt(response.project_id)) {
          const updatedSegments = project.segments.map((segment) => {
            if (segment.id === response.id) {
              // Replace the old segment with the updated segment
              return response;
            }
            return segment;
          });

          // Return the project with updated segments
          return {
            ...project,
            segments: updatedSegments,
          };
        }
        return project;
      });

      return {
        ...state,
        projects: updatedProjects,
        loading: false,
        created: true,
        creating: false,
      };
    },

    deleteProjectSegment(state, action) {
      return {
        ...state,
      };
    },

    deleteProjectSegmentSuccess(state, action) {
      const { projectIdToDelete, segmentId } = action.payload;

      const updatedProjects = state.projects.map((project) => {
        if (project.id === parseInt(projectIdToDelete)) {
          return {
            ...project,
            segments: project.segments.filter(
              (segment) => segment.id !== segmentId
            ),
          };
        }
        return project;
      });

      return {
        ...state,
        projects: updatedProjects,
      };
    },
    setPagination(state, action) {
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ["page"]: action.payload["x-pagination-current-page"],
          ["currentPage"]: action.payload["x-pagination-current-page"],
          ["pageCount"]: action.payload["x-pagination-page-count"],
          ["perPage"]: action.payload["x-pagination-per-page"],
          ["totalCount"]: action.payload["x-pagination-total-count"],
        },
      };
    },
    setUserMessage(state, action) {
      return {
        ...state,
        userMessages: [...state.userMessages, action.payload],
      };
    },
    setErrors(state, action) {
      return {
        ...state,
        creating: false,
        created: false,
        loading: false,
        error: action.payload,
      };
    },
    initList(state, action) {
      return {
        ...state,
        created: false,
      };
    }
  },
});

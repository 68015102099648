import React, { useEffect, useReducer } from "react";
import Button from "../../components/Button";
import Selector from "../../components/Selector";
import Input from "../../components/Input";
import BackendLayout from "../../components/BackendLayout";
import Heading from "../../components/Heading";
import Breadcrumb from "../../components/Breadcumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Abutment_Material, MRS_Years, NO_OF_LANES, handleAddSegment, handleUpdateSegment, totalHeightFoundationDepths } from "../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";

const SlabCulvert = () => {
  const { projectId, segmentId } = useParams();

  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      scourDepth: "",
      highFloodLevelAboveBedLevel: "",
      noOfLanes: "",
      spanLengthOfBridge: "",
      totalHeightFoundationDepth: "",
      abutmentMaterial: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );
  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const created = useSelector((state) => state.project.created);
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "SlabCulvert",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "SlabCulvert",
      projectId,
      // segmentInformation,
      segmentId

    });
    // console.log(demoData);
  };

  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Slab-Culvert", itemLink: "" },
        ]}
      />

      <Heading item={"Slab Culvert Module"} className={"px-8 py-5"} />

      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Scour Depth (m)
              </label>

              <Input
                type="text"
                name="scourDepth"
                required
                className="  text-base"
                placeholder="0.00"
                value={formState?.scourDepth}
                onChange={(e) =>
                  updateFormState({ scourDepth: e.target.value })
                }
              />
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                High Flood Level above Bed Level (m)
              </label>

              <Input
                type="text"
                name="highFloodLevelAboveBedLevel"
                required
                className="  text-base"
                placeholder="0.00"
                value={formState?.highFloodLevelAboveBedLevel}
                onChange={(e) =>
                  updateFormState({
                    highFloodLevelAboveBedLevel: e.target.value,
                  })
                }
              />
              {formState?.highFloodLevelAboveBedLevel && (parseInt(formState?.highFloodLevelAboveBedLevel) + parseInt(formState?.scourDepth) + 1) > 6 ? <span className="text-red-500">The Design is not applicable</span> : formState?.highFloodLevelAboveBedLevel && <span className="text-green-500">&#10004;</span>}

            </div>
          </div>
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">No. of Lanes</label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select No. of Lanes"
                name="noOfLanes"
                required
                isSearchable={true}
                value={NO_OF_LANES.find(
                  (city) => city.value === formState.noOfLanes
                )}
                options={NO_OF_LANES}
                onChange={(e) => {
                  updateFormState({ noOfLanes: e.value });
                }}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Span Length of Bridge (m)(1-6)
              </label>
              <Input
                type="number"
                name="spanLengthOfBridge"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                min={1}
                max={6}
                value={formState?.spanLengthOfBridge}
                onChange={(e) =>
                  updateFormState({ spanLengthOfBridge: e.target.value })
                }
              />
              {parseInt(formState?.spanLengthOfBridge) < 1 || parseInt(formState?.spanLengthOfBridge) > 6 ? <span className="text-red-500">The values shall be between 1 and 6</span> : formState?.spanLengthOfBridge && <span className="text-green-500">&#10004;</span>}
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Total Height I/C Foundation Depth (m)
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Total Height Foundation Depths"
                name="totalHeightFoundationDepth"
                required
                isSearchable={true}
                value={totalHeightFoundationDepths.find(
                  (height) => height.value === formState?.totalHeightFoundationDepth
                )}
                options={totalHeightFoundationDepths}
                onChange={(e) => {
                  updateFormState({ totalHeightFoundationDepth: e.value });
                }}
              />
              {/* <Select
                type="text"
                name="totalHeightFoundationDepth"
                required
                className="text-lg  "
                placeholder="0.00"
                value={formState?.totalHeightFoundationDepth}
                onChange={(e) =>
                  updateFormState({
                    totalHeightFoundationDepth: e.target.value,
                  })
                }
              /> */}
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Abutment Material
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Abutment Material"
                name="abutmentMaterial"
                required
                isSearchable={true}
                value={Abutment_Material.find(
                  (city) => city.value === formState?.abutmentMaterial
                )}
                options={Abutment_Material}
                onChange={(e) => {
                  updateFormState({ abutmentMaterial: e.value });
                }}
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                MRS Area/Location/Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({
                    MRSAreaLocationCostFactor: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>

              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />
          {
            (parseInt(formState?.highFloodLevelAboveBedLevel) + parseInt(formState?.scourDepth) + 1) > 6 ||
              parseInt(formState?.spanLengthOfBridge) < 1 || parseInt(formState?.spanLengthOfBridge) > 6 ?
              <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} className={"opacity-20 cursor-not-allowed"} disabled type={"Submit"} />
              :
              <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
          }
        </div>
      </form>
    </BackendLayout>
  );
};

export default SlabCulvert;

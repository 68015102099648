import React from "react";
import BackendLayout from "../components/BackendLayout";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";
import { FaIcons } from "../components/Icons";
import { Link } from "react-router-dom";
import SearchBar from "../components/SearchBar";
import wideningOfCurve from "../assets/wideningOfCurve.png";
export default function WideningOfCurve() {
  const plusIcon = "FaPlus";
  return (
    <BackendLayout screenName="Modular Design ">
      <div className="flex flex-row items-center ">
        <Breadcrumb
          items={[
            { itemName: "Home", itemLink: "" },
            { itemName: "Widening Of Curve", itemLink: "" },
          ]}
        />

        {/* <div className="flex flex-grow flex-row  gap-4 items-center justify-end p-2 font-poppins">
          <Link to={"add-project"}>
            <Button
              label={"Add a New Project"}
              icon={<FaIcons iconName={plusIcon} />}
              className={"w-fit rounded-lg"}
            />
          </Link>
        </div> */}
      </div>

      {/* <div className="flex flex-row p-6 items-center"> */}
      {/* <SearchBar /> */}
      {/* <FilterBtn /> */}
      {/* </div> */}

      <div className="flex mx-4 my-2">
        <img src={wideningOfCurve} className="w-[80%] h-[80%]" />
      </div>
    </BackendLayout>
  );
}

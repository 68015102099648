import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { getClient } from "./client";
import Swal from "sweetalert2";
import { generalActionAsync } from "./general";

export function* getBaseUrl() {
  return process.env.REACT_APP_API_URL;
}
export function* appendUrlParams(url, params) {
  for (let i in params) {
    if (Array.isArray(params[i])) {
      for (let j in params[i]) {
        url.searchParams.append(`${i}[]`, params[i][j]);
      }
    } else {
      url.searchParams.append(i, params[i]);
    }
  }
  return url;
}

export function* getData(url, params = {}) {
  const baseUrl = yield getBaseUrl();
  const userSelector = (state) => state.user;
  let user = yield select(userSelector);
  const client = getClient(user);
  const mutatedURL = `${baseUrl}${url}`;
  const fullUrl = yield appendUrlParams(new URL(mutatedURL), params);
  return yield client.get(fullUrl).then((response) => {
    return response;
  });
}

export function* getProjectData(url, params = {}) {
  const projectsResponse = yield getData(url, params);
  const projects = projectsResponse?.data;
  // const updatedParams = {
  //       "x-pagination-current-page": e.selected + 1,
  //       "x-pagination-current-page":pagination.currentPage,
  //       "x-pagination-page-count":pagination.pageCount,
  //       "x-pagination-per-page":pagination.perPage,
  //       "x-pagination-total-count":pagination.totalCount
  // }
  yield put({ type: "project/fetchProjectSuccess", payload: projects });
  yield put({
    type: "project/setPagination",
    payload: projectsResponse?.headers,
  });
}

export function* postProject(url, payload) {
  try {
    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    const response = yield client
      .post(`${baseUrl}${url}`, payload)
      .then((response) => {
        Swal.fire({
          position: "top-end",
          title: "Sucess!",
          text: "Projects added Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        return response;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Unable to add project",
          icon: "error",
          confirmButtonText: "OK",
        });
        console.log(error);
      });
    yield put({
      type: "project/addProjectSuccess",
      payload: {
        response: response.data,
        created: true,
      },
    });
  } catch (error) { }
}

export function* updateProject(url, payload) {
  try {

    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    yield client
      .put(`${baseUrl}${url}`, payload)
      .then((response) => {
        Swal.fire({
          position: "top-end",
          title: "Sucess!",
          text: "Projects updated Successfully",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
        return response;
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Unable to update project",
          icon: "error",
          confirmButtonText: "OK",
        });
      });
    yield put({
      type: "project/updateProjectSuccess",
      payload: {

        created: true,
      },
    });
  } catch (error) {
    yield console.log(error)
  }
}

export function* addSegment(url, payload) {
  try {
    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    const response = yield client
      .post(`${baseUrl}${url}`, payload)
      .then((res) => res);
    yield put({
      type: "project/addSegmentSuccess",
      payload: {
        response: response.data,
      },
    });
    yield put({
      type: "project/setUserMessages",
      payload: {
        ...payload,
        response: response,
        message: "Record Created Successfully!",
        created: true,
      },
    });
  } catch (error) {
    yield put({
      type: "project/setUserMessages",
      payload: {
        ...payload,
        ...error,
      },
    });
    yield put({
      type: "project/setErrors",
      payload: {
        ...error,
      },
    });
  }
}

export function* updateSegment(url, payload) {
  try {
    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    const response = yield client
      .put(`${baseUrl}${url}`, payload)
      .then((res) => res);
    yield put({
      type: "project/updateSegmentSuccess",
      payload: {
        response: response.data,
      },
    });
    yield put({
      type: "project/setUserMessages",
      payload: {
        ...payload,
        response: response,
        message: "Record updated Successfully!",
        created: true,
      },
    });
  } catch (error) {
    yield put({
      type: "project/setUserMessages",
      payload: {
        ...payload,
        ...error,
      },
    });
    yield put({
      type: "project/setErrors",
      payload: {
        ...error,
      },
    });
  }
}

export function* deleteRecord(url, projectId) {
  try {
    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    yield client.delete(`${baseUrl}${url}`);
    yield put({
      type: "project/deleteProjectSuccess",
      payload: { projectId: projectId },
    });
    return true;
  } catch (error) {
    return console.log(error);
  }
}
export function* deleteSegment(url, { projectIdToDelete, segmentId }) {
  try {
    const baseUrl = yield getBaseUrl();
    const userSelector = (state) => state.user;
    let user = yield select(userSelector);
    const client = getClient(user);
    yield client.delete(`${baseUrl}${url}`);
    yield put({
      type: "project/deleteProjectSegmentSuccess",
      payload: { segmentId, projectIdToDelete },
    });
    return true;
  } catch (error) {
    return console.log(error);
  }
}

export function* binSetter(url) {
  const baseUrl = yield getBaseUrl();
  const modelSelector = (state) => state.road.identifyParams;
  const userSelector = (state) => state.user;
  yield put({ type: "road/binSetter" });
  yield put({ type: "road/identifyBin" });

  let identifyparameter = yield select(modelSelector);
  let user = yield select(userSelector);
  const client = getClient(user);
  const urlBase = `${baseUrl}${url}`;
  const fullUrl = yield appendUrlParams(new URL(urlBase), identifyparameter);
  if (
    identifyparameter.tl == undefined ||
    identifyparameter.sg == undefined ||
    identifyparameter.sb == undefined ||
    identifyparameter.bs == undefined
  ) {
    //stay silent
  } else {
    const identifeidModule = yield client.post(fullUrl).then((response) => {
      return response;
    });
    yield put({ type: "road/setRoadModule", payload: identifeidModule.data });
  }
}

export function* getMrs(url, year, item_code) {
  const baseUrl = yield getBaseUrl();
  const userSelector = (state) => state.user;
  let user = yield select(userSelector);
  const client = getClient(user);
  const urlBase = `${baseUrl}${url}`;
  const fullUrl = yield appendUrlParams(new URL(urlBase), { year, item_code });
  const mrsData = yield client.get(fullUrl).then((response) => {
    return response;
  });
  if (Array.isArray(item_code)) {
    const payload = []
    for (let i in item_code) {
      payload.push(
        {
          item_code: item_code[i],
          price: mrsData.data[item_code[i]]?.item_price,
        }
      )
    }
    yield put({
      type: "road/setMrsYearPriceArray",
      payload,
    })
  } else {
    yield put({
      type: "road/setMrsYearPrice",
      payload: { item_code, price: mrsData.data[item_code]?.item_price },
    });
  }
}

export function* watcherSaga() {
  yield takeEvery("project/fetchProjects", function* (action) {
    yield getProjectData(action.payload.url, action.params);
  });

  yield takeEvery("project/addProject", function* (action) {
    yield postProject(action.payload.url, action.payload.projectData);
  });

  yield takeEvery("project/updateProject", function* (action) {
    yield updateProject(action.payload.url, action.payload.projectData);
  });

  yield takeEvery("project/deleteProject", function* (action) {
    const { url, projectId } = action.payload;
    yield deleteRecord(url, projectId);
  });

  yield takeEvery("project/deleteProjectSegment", function* (action) {
    const { projectIdToDelete, segmentId } = action.payload;
    yield deleteSegment("/segments/" + segmentId, {
      segmentId,
      projectIdToDelete,
    });
  });

  yield takeEvery("project/addSegment", function* ({ payload }) {
    const { url, ...payloadData } = payload;
    yield addSegment(url, payloadData);
  });

  yield takeEvery("project/updateSegment", function* ({ payload }) {
    const { url, ...payloadData } = payload;
    yield updateSegment(url, payloadData);
  });

  yield takeEvery("road/modelChanged", function* ({ payload }) {
    const { url } = payload;
    yield binSetter(url);
  });

  yield takeEvery("road/getMrs", function* (action) {
    const { url, year, itemCode } = action.payload;
    yield getMrs(url, year, itemCode);
  });

  yield takeEvery("project/setUserMessages", generalActionAsync);
}

import React, { useEffect, useReducer } from "react";
import BackendLayout from "../../../components/BackendLayout";
import Heading from "../../../components/Heading";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { MRS_Years, bearingCapacity, handleAddSegment, handleUpdateSegment } from "../../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";

const CounterfortRetainingWall = () => {
  const { projectId, segmentId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      bearingCapacity: "",
      numberOfRetaingWallSegments: "",
      lengthOfRetainingWall: "",
      heightOfWallAboveGL: "",
      depthOfFoundation: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );

  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  const created = useSelector((state) => state.project.created);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "CounterfortRetainingWall",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "CounterfortRetainingWall",
      projectId,
      // segmentInformation,
      segmentId

    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Counterfort-Retaining-Wall", itemLink: "" },
        ]}
      />

      <Heading
        item={"Counterfort Reataining Wall Module"}
        className={"px-8 py-5"}
      />
      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Bearing Capacity
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Bearing Capacity"
                name="bearingCapacity"
                required
                value={bearingCapacity.find(
                  (f) => f.value === formState?.bearingCapacity
                )}
                options={bearingCapacity}
                onChange={(e) => updateFormState({ bearingCapacity: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Number Of Retaing Wall Segments (Greater than or equal to 1)
              </label>
              <Input
                type="text"
                name="numberOfRetaingWallSegments"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                value={formState?.numberOfRetaingWallSegments}
                onChange={(e) =>
                  updateFormState({
                    numberOfRetaingWallSegments: e.target.value,
                  })
                }
              />
              {parseInt(formState?.numberOfRetaingWallSegments) < 1 ? (
                <span className="text-red-500">
                  Select any value greater than or equal to 1
                </span>
              ) : (
                formState?.numberOfRetaingWallSegments && <span className="text-green-500">&#10004;</span>
              )}
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Length of Retaining Wall (m 1-10)
              </label>
              <Input
                type="text"
                name="lengthOfRetainingWall"
                required
                className="text-lg "
                value={formState?.lengthOfRetainingWall}
                onChange={(e) =>
                  updateFormState({ lengthOfRetainingWall: e.target.value })
                }
                max={10}
              />
              {
                parseInt(formState?.lengthOfRetainingWall) > 10 ?
                  (<span className="text-red-500">  Select a value less than 10</span>)
                  :
                  (formState?.lengthOfRetainingWall && <span className="text-green-500">&#10004;</span>)
              }
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Depth of Foundation, Dt (m) (Greater than or equal to 1)
              </label>
              <Input
                type="text"
                name="depthOfFoundation"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                value={formState?.depthOfFoundation}
                onChange={(e) =>
                  updateFormState({ depthOfFoundation: e.target.value })
                }
              />
              {parseInt(formState?.formState?.depthOfFoundation) < 1 ? (
                <span className="text-red-500">
                  Select any value greater than or equal to 1
                </span>
              ) : (
                formState?.formState?.depthOfFoundation && <span className="text-green-500">&#10004;</span>
              )}
            </div>

          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                {` Height of Wall above GL, h (m ${isNaN(5 - parseInt(formState?.depthOfFoundation)) ? 0 : 5 - parseInt(formState?.depthOfFoundation)} - ${isNaN(12 - parseInt(formState?.depthOfFoundation)) ? 0 : 12 - parseInt(formState?.depthOfFoundation)} )`}

              </label>
              <Input
                type="text"
                name="heightOfWallAboveGL"
                required
                className="text-lg "
                value={formState?.heightOfWallAboveGL}
                onChange={(e) =>
                  updateFormState({ heightOfWallAboveGL: e.target.value })
                }
              />
              {parseInt(formState?.heightOfWallAboveGL) > 12 - parseInt(formState?.depthOfFoundation) ||
                parseInt(formState?.heightOfWallAboveGL) < 5 - parseInt(formState?.depthOfFoundation) ?
                (<span className="text-red-500">Select a value between {5 - parseInt(formState?.depthOfFoundation)} and {12 - parseInt(formState?.depthOfFoundation)}
                </span>
                ) :
                (
                  formState?.heightOfWallAboveGL && <span className="text-green-500">&#10004;</span>
                )}
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Mrs Area Location Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({ MRSAreaLocationCostFactor: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>
              {/* <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="MRS Year"
                name="year"
                required
                value={MRS_Years.find((f) => f.value === formState?.year)}
                options={MRS_Years}
                onChange={(e) => updateFormState({ year: e.value })}
              /> */}
              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}

              />
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <Input type="hidden" />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          {parseInt(formState?.numberOfRetaingWallSegments) < 1 ||
            parseInt(formState?.heightOfWallAboveGL) < (5 - parseInt(formState?.depthOfFoundation)) ||
            parseInt(formState?.heightOfWallAboveGL) > (12 - parseInt(formState?.depthOfFoundation)) ||
            parseInt(formState?.formState?.depthOfFoundation) < 1 ? (
            <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} className={"disabled opacity-20 cursor-not-allowed"} disabled />
          ) : (
            <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
          )}
        </div>
      </form>
    </BackendLayout>
  );
};

export default CounterfortRetainingWall;

import React, { useEffect, useReducer } from "react";
import BackendLayout from "../../../components/BackendLayout";
import Heading from "../../../components/Heading";
import Selector from "../../../components/Selector";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcumb";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MRS_Years, NO_OF_LANES, VentedCausewayPipeDiameters, handleAddSegment, handleUpdateSegment } from "../../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";

const VentedCauseway = () => {
  const { projectId, segmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      numberOfLongSegments: "",
      internalDiameterOfPipe: "",
      noOfLanesOfRoad: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );

  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );

  const created = useSelector((state) => state.project.created);
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);

  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId)
  );

  // const currentEditSegmentInformation = {

  //   name: currentEditedSegment.name,
  //   project_id: currentEditedSegment.project_id,
  //   to_road: currentEditedSegment.to_road,
  //   from_road: currentEditedSegment.from_road,
  //   type: currentEditedSegment.type,

  // }



  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      if (
        currentEditedSegment?.inputs[currentEditedSegment?.inputs?.length - 1]
          ?.input_value.length > 0
      )
        updateFormState({
          ...JSON.parse(
            currentEditedSegment?.inputs[
              currentEditedSegment?.inputs?.length - 1
            ]?.input_value
          ),
        });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "VentedCauseway",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "VentedCauseway",
      projectId,
      // currentEditSegmentInformation,
      segmentId

    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Vented-Causeway", itemLink: "" },
        ]}
      />
      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <Heading item={"Vented Causeway Module"} className={"px-8 py-5"} />

        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
              No. of 6m Long Segments
              </label>
              <Input
                type="text"
                name="numberOfLongSegments"
                required
                className="text-lg "
                value={formState?.numberOfLongSegments}
                onChange={(e) => updateFormState({ numberOfLongSegments: e.target.value })}
              />
              {
                parseInt(formState?.numberOfLongSegments) < 1 ?
                  (<span className="text-red-500">  Select a value greater than or equal to 1</span>)
                  :
                  (formState?.numberOfLongSegments && <span className="text-green-500">&#10004;</span>)
              }
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Internal Diameter of Pipe (mm)
              </label>

              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Internal Pipe Diameter"
                name="internalDiameterOfPipe"
                required
                value={VentedCausewayPipeDiameters.find(
                  (f) => f.value === formState?.internalDiameterOfPipe
                )}
                options={VentedCausewayPipeDiameters}
                onChange={(e) => updateFormState({ internalDiameterOfPipe: e.value })}
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                No. of Lanes Of Road
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="No of Lanes"
                name="noOfLanes"
                required
                value={NO_OF_LANES.find(
                  (f) => f.value === formState?.noOfLanes
                )}
                options={NO_OF_LANES}
                onChange={(e) => updateFormState({ noOfLanes: e.value })}
              />
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Mrs Area Location Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({ MRSAreaLocationCostFactor: e.target.value })
                }
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>
              {/* <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select MRS Year"
                name="year"
                required
                isSearchable={true}
                value={MRS_Years.find(
                  (city) => city.value === projectData?.mrs_year?.toString()
                )}
                options={MRS_Years}
              // onChange={(e) => updateFormState({ year: e.value })}
              /> */}
              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}

              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <Input type="hidden" />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          {
            parseInt(formState?.numberOfLongSegments) < 1 ?
              <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} disabled className={"disabled opacity-20 cursor-not-allowed"} />
              : <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
          }
        </div>
      </form>
    </BackendLayout>
  );
};

export default VentedCauseway;

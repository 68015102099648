import React, { useEffect, useState } from "react";
import login_img from "../assets/bg_login.svg";
import logo from "../assets/logo.png";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { AiIcons } from "../components/Icons";
import Input from "../components/Input";
import Button from "../components/Button";
import Label from "../components/Label";
import { userLogin } from "../store/actions";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

const api_url = process.env.REACT_APP_API_URL + "/site/login";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hideIcon = "AiFillEyeInvisible";
  const showIcon = "AiFillEye";
  const currentUser = useSelector((state) => state?.user);
  const [showPassword, setShowPassword] = useState(false);
  const [enterPress, setEnterPress] = useState("password");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    if (currentUser) {
      navigate("/projects");
    }
  }, []);

  const togglePassword = (e) => {
    e.preventDefault();
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  const handleLogin = async (e) => {
    setLoading(true);
    e.preventDefault();
    await axios
      .post(api_url, user)
      .then((response) => {
        if (response.data.access_token) {
          dispatch(userLogin(response.data));
          setLoading(false);
          return navigate("/projects");
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("🚀 ~ handleLogin ~ error:", error);
        setLoading(false);
        Swal.fire({
          position: "top",
          icon: "error",
          toast: true,
          title:
            Object.values(error).length > 0
              ? Object.values(JSON.parse(error?.response?.data?.message))
                  .map((msg) => msg).join("\n")
              : JSON.stringify(error),
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  function handleLoginKeyPress(e) {
    if (e.key === "Enter") {
      if (!enterPress) {
        setShowPassword(!showPassword);
        setEnterPress(true);
      } else {
        handleLogin(e);
      }
    }
  }

  return (
    <form>
      <div className=" flex flex-row justify-center items-center bg-gray-100 h-screen p-20 ">
        <div className=" flex flex-row justify-center  bg-white  m-10 h-fit w-full rounded-lg">
          <div className="col-md-8 flex justify-center items-center  w-full ">
            <img src={login_img} className="h-fit w-full" alt="login_img" />
          </div>

          <div className="col-md-4 h-full w-full flex flex-col justify-center items-center p-10  ">
            <img src={logo} alt="logo" className="h-20 w-20" />
            <span className="pt-2 font-bold font-poppins">Welcome Back!</span>

            <div className="mt-10 flex flex-col gap-1 h-full w-4/5">
              <Label>Email</Label>
              <Input
                type="email"
                required
                name="useremail"
                placeholder="Email"
                className="h-10 w-50 font-poppins"
                onChange={(e) =>
                  setUser((prevData) => ({
                    ...prevData,
                    email: e.target.value,
                  }))
                }
              />
            </div>

            <div className="mt-10 flex flex-col gap-1 h-full w-4/5">
              <Label>Password</Label>
              <div className="flex flex-row gap-1 h-full w-full ">
                <Input
                  type={showPassword ? "text" : "password"}
                  required
                  name="userpassword"
                  placeholder="Password"
                  className="h-10 font-poppins"
                  onKeyPress={handleLoginKeyPress}
                  onChange={(e) =>
                    setUser((prevData) => ({
                      ...prevData,
                      password: e.target.value,
                    }))
                  }
                />
                <button
                  className="flex justify-center items-center focus:outline-none text-gray-500  "
                  onClick={(e) => {
                    togglePassword(e);
                  }}
                >
                  {showPassword ? (
                    <AiIcons iconName={hideIcon} />
                  ) : (
                    <AiIcons iconName={showIcon} />
                  )}
                </button>
              </div>
            </div>

            <div className="w-1/2 mt-10 ">
              {/* <NavLink to={"/projects"}> <Button label={"Login"} className={"w-full hover:bg-orange-400 rounded-lg"} type="submit" /></NavLink> */}
              <Button
                label={"Login"}
                loading={loading}
                className={"w-full hover:bg-orange-400 rounded-lg"}
                type="submit"
                onClick={(e) => {
                  handleLogin(e);
                }}
              />
            </div>

            <div className="w-1/2 mt-10 text-center">
              <p>
                <span className="text-primary font-thin font-poppins">
                  <p>Don't have an account? </p>
                  <NavLink className={"hover:text-secondary"} to={"/signup"}>
                    Signup
                  </NavLink>
                </span>
              </p>
              {/* <p>or</p>
              <p>
                Continue as a {" "}
                <span className="text-primary font-thin font-poppins">
                  <NavLink to={"/"}>Guest</NavLink>
                </span>{" "}
              </p> */}
            </div>
            <hr></hr>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;

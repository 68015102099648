import { createSlice } from "@reduxjs/toolkit";



export const miscSlice = createSlice({
    name: "misc",
    initialState: { activeStep: 0, totalSteps: 0 },
    reducers: {
        setFormStep(state, action) {
            return {
                ...state,
                activeStep: action.payload.value
            }
        },
        setFormTotalSteps(state, action) {
            return {
                ...state,
                totalSteps: action.payload.value
            }
        }
    },
})
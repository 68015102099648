import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import Seperator from '../Seperator';
import Input from '../Input';
import Heading from "../Heading";
import Button from '../Button';

import EstimationInputs from '../EstimationInputs';
import { formatNumberWithCommas, handleAddRoadSegment, handleUpdateRoadSegment, round, sumKValues, transformArrayToObject } from '../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import RoadStepAction from './RoadStepAction';
import { type } from '@testing-library/user-event/dist/type';

const BillEstimationSection = () => {

    const sideHeadingsStyle = "text-sm text-gray-400 hover:ease-in-out transition hover:-translate-y-1 hover:scale-110 duration-300 delay-50 hover:text-primary hover:right-8 hover:font-bold cursor-pointer underline";

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { projectId, segmentId } = useParams();
    const { loading } = useSelector((state) => state.project);
    const created = useSelector((state) => state.project.created);
    const segmentInformation = useSelector((state) => state.project?.segmentInfo);
    const mrsPrices = useSelector((state) => state.road?.mrs);
    const projectData = useSelector((state) =>
        state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
    );

    const currentEditedSegment = projectData?.segments?.find(
        (s) => s.id === parseInt(segmentId, 10)
    );


    const
        {
            cut, fill, compaction_ng, clearing_and_grubbing, subgrade,
            bill1_estimates_A, bill1_estimates_B, bill1_estimates_C, bill1_estimates_D, bill2_estimates, bill3_estimates, bill5_estimates, bill6_estimates, bill7_estimates,
            binIndexes,
            roadMaterial,
            thicknessSection,
            drawingInputValues,
            roadFormInputs
        } = useSelector((state) => state.road);


    const roadTasks = { ...cut, ...fill, ...compaction_ng, ...clearing_and_grubbing, ...subgrade };




    useEffect(() => {
        if (created) {
            navigate(`/projects/${projectId}`);
        }
        updateAllQuantities();
        if (segmentId) {
            const bill1OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill1_estimates"));
            const bill2OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill2_estimates"));
            const bill3OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill3_estimates"));
            const bill5OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill5_estimates"));
            const bill6OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill6_estimates"));
            const bill7OnEdit = transformArrayToObject(currentEditedSegment?.estimates.filter((item) => item.page_type === "bill7_estimates"));
            updateAllEstimates("bill1_estimates_B", bill1_estimates_B, bill1OnEdit);
            updateAllEstimates("bill1_estimates_C", bill1_estimates_C, bill1OnEdit);
            updateAllEstimates("bill1_estimates_D", bill1_estimates_D, bill1OnEdit);
            updateAllEstimates("bill2_estimates", bill2_estimates, bill2OnEdit);
            updateAllEstimates("bill3_estimates", bill3_estimates, bill3OnEdit);
            updateAllEstimates("bill5_estimates", bill5_estimates, bill5OnEdit);
            updateAllEstimates("bill6_estimates", bill6_estimates, bill6OnEdit);
            updateAllEstimates("bill7_estimates", bill7_estimates, bill7OnEdit);
        }

    }, [])

    useEffect(() => {
        if (created) {
            navigate(`/projects/${projectId}`);
        }

    }, [created, segmentInformation])

    const updateAllQuantities = () => {
        //comapre key name roadTasks with bill1_estimates and update I of bill1_estimates with the 'D' of roadTasks
        const updatedBill = {};
        Object.entries(bill1_estimates_A).forEach(([key, item]) => {
            // console.log(isNaN(parseFloat(roadTasks[key]?.C)) ? 0 : parseFloat(roadTasks[key]?.C));
            if (roadTasks[key]) {

                updatedBill[key] = {

                    ...item,

                    I: roadTasks[key]?.D > 0 ? roadTasks[key]?.D : 0,

                    J: parseFloat(mrsPrices[key]),

                    C: roadTasks[key]?.D > 0 ? "Yes" : "No",

                    K: parseFloat(roadTasks[key]?.D) > 0 ? parseFloat(roadTasks[key]?.D) * parseFloat(mrsPrices[key]) : 0,

                    included: roadTasks[key]?.D > 0 ? true : false,

                    mustInclude: roadTasks[key]?.D > 0 ? true : false
                }

            }
            else updatedBill[key] = item;
        })
        dispatch({ type: "road/setBillEstimates", payload: { billName: "bill1_estimates_A", billData: updatedBill } });
    }

    const updateAllEstimates = (bill, billData, billonEditData) => {
        //comapre key name roadTasks with bill1_estimates and update I of bill1_estimates with the 'D' of roadTasks
        const updatedBill = {};
        Object.entries(billData).forEach(([key, item]) => {
            Object.entries(billonEditData).forEach(([keyonEdit, itemonEdit]) => {
                if (key === keyonEdit) {
                    if (bill !== "bill7_estimates") {
                        updatedBill[key] = {

                            ...item,
                            E: parseFloat(itemonEdit?.E),
                            F: parseFloat(itemonEdit?.F),
                            G: parseFloat(itemonEdit?.G),
                            H: parseFloat(itemonEdit?.H),
                            I: parseFloat(itemonEdit?.I),
                            J: parseFloat(mrsPrices[key]),
                            C: itemonEdit?.I > 0 ? "Yes" : "No",
                            K: parseFloat(itemonEdit?.I) > 0 ? parseFloat(itemonEdit?.I) * parseFloat(mrsPrices[key]) : 0,

                            included: itemonEdit?.I > 0 ? true : false,

                            // mustInclude: itemonEdit?.I > 0 ? true : false
                        }
                    }
                    else {
                        updatedBill[key] = {

                            ...item,

                            E: parseFloat(itemonEdit?.E),
                            F: parseFloat(itemonEdit?.F),
                            G: parseFloat(itemonEdit?.G),
                            H: parseFloat(itemonEdit?.H),
                            I: parseFloat(itemonEdit?.I),
                            J: parseFloat(itemonEdit?.J),
                            C: itemonEdit?.I > 0 ? "Yes" : "No",
                            K: parseFloat(itemonEdit?.I) > 0 ? parseFloat(itemonEdit?.I) * parseFloat(itemonEdit?.J) : 0,

                            included: itemonEdit?.I > 0 ? true : false,

                            // mustInclude: itemonEdit?.I > 0 ? true : false
                        }
                    }
                }

            })
            // console.log(isNaN(parseFloat(roadTasks[key]?.C)) ? 0 : parseFloat(roadTasks[key]?.C));


        })
        dispatch({ type: "road/setBillEstimates", payload: { billName: bill, billData: updatedBill } });
    }



    const handleAdd = (e) => {
        const bill1_estimates = { ...bill1_estimates_A, ...bill1_estimates_B, ...bill1_estimates_C, ...bill1_estimates_D };
        const roadPayload = {
            binIndexes,
            roadMaterial,
            thicknessSection,
            drawingInputValues,
            clearing_and_grubbing,
            roadFormInputs,
            compaction_ng,
            cut,
            fill,
            subgrade,
            bill1_estimates,
            bill2_estimates,
            bill3_estimates,
            bill5_estimates,
            bill6_estimates,
            bill7_estimates
        }

        handleAddRoadSegment(e, dispatch, { module: "road", projectId, segmentInformation, roadPayload });
    }

    const handleUpdate = (e) => {
        const bill1_estimates = { ...bill1_estimates_A, ...bill1_estimates_B, ...bill1_estimates_C, ...bill1_estimates_D };
        // console.log(bill1_estimates);

        const roadPayload = {
            binIndexes,
            roadMaterial,
            thicknessSection,
            drawingInputValues,
            clearing_and_grubbing,
            roadFormInputs,
            compaction_ng,
            cut,
            fill,
            subgrade,
            bill1_estimates,
            bill2_estimates,
            bill3_estimates,
            bill5_estimates,
            bill6_estimates,
            bill7_estimates,
        }
        handleUpdateRoadSegment(e, dispatch, { module: "road", projectId, segmentInformation, segmentId, roadPayload });
    }


    return (
        <>

            <div className='flex flex-col gap-2  font-poppins' >
                <div className='w-full text-center text-primary'>
                    <span className={"text-center font-poppins text-xl px-2  "} >Select the applicable MRS item from the drop down to add to the project</span>
                </div>
                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"Bill 1 Estimation: Earth Work (A)"} id='bill1' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1   px-10" >

                        <EstimationInputs data={bill1_estimates_A} bill={"bill1_estimates_A"} >
                            <div className='flex flex-row p-2  border-primary border-b font-semibold'>
                                <div className="text-primary w-[250px] flex flex-row justify-center items-center ">MRs Codes</div>
                                <div className="text-primary w-[220px] flex flex-row justify-center items-center ">Quantity</div>
                                <div className="text-primary w-[220px] flex flex-row justify-center items-center ">Unit Rate</div>
                                <div className="text-primary w-[220px] flex flex-row justify-center items-center ">Amount (PKR)</div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2 border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary w-[250px] flex flex-row justify-center items-center ">Sub Total A</div>
                            <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                            <div className="text-primary w-52 flex flex-row justify-center items-center ">-</div>
                            <div className="text-primary w-52 flex flex-row justify-center items-center ">{formatNumberWithCommas(round(sumKValues(bill1_estimates_A)), 0)}</div>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 1 Estimation: Earth Work (B)"} id='bill1' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >

                        <EstimationInputs data={bill1_estimates_B} bill={"bill1_estimates_B"} >

                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total B</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill1_estimates_B), 0))}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 1 Estimation: Earth Work (C)"} id='bill1' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill1_estimates_C} bill={"bill1_estimates_C"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total C</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill1_estimates_C), 0))}</div>
                            </div>
                        </div>

                    </div>


                </div>
                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 1 Estimation: Earth Work (D)"} id='bill1' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill1_estimates_D} bill={"bill1_estimates_D"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 text-center">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total D</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill1_estimates_D), 0))}</div>
                            </div>
                        </div>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-bold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Total Bill 1 (A+B+C+D)</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(((sumKValues(bill1_estimates_A)) + sumKValues(bill1_estimates_B) + sumKValues(bill1_estimates_C) + sumKValues(bill1_estimates_D)), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />

                </div>

                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 2 Estimation Total: Subbase & Base"} id='bill2' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill2_estimates} bill={"bill2_estimates"} >

                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total Bill 2</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill2_estimates), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />

                </div>
                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 3 Estimation: Surface Course"} id='bill3' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill3_estimates} bill={"bill3_estimates"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total Bill 3</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill3_estimates), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />

                </div>

                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"Bill 5 Estimation: Drainage Works"} id='bill5' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill5_estimates} bill={"bill5_estimates"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total Bill 5</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill5_estimates), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />
                </div>

                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"Bill 6 Estimation: Ancillary Works"} id='bill6' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill6_estimates} bill={"bill6_estimates"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center  w-36">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 text-center ">Unit Rate (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total Bill 6</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill6_estimates), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />
                </div>

                <div className="flex flex-col flex-grow gap-4 p-2" >
                    <Heading item={"  Bill 7 Estimation: Miscellaneous"} id='bill7' className={"px-2 mx-10"} />
                    <div className="flex flex-col flex-1  gap-5 px-10" >
                        <EstimationInputs data={bill7_estimates} bill={"bill7_estimates"} >
                            <div className='flex flex-row p-2  border-primary border-b gap-3 font-semibold '>
                                <div className="text-primary flex flex-row justify-center items-center w-[30%] ">MRs Codes</div>
                                <div className='flex flex-row w-full gap-2'>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Number</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 text-center  ">Unit Rate <br /> (PKR)</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-20 ">Length</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Width</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Height</div>
                                    <div className="text-primary  flex flex-row justify-center items-center w-36 ">Amount<br />(PKR)</div>
                                </div>
                            </div>
                        </EstimationInputs>
                        <div className='flex flex-row p-2  border-primary border-t gap-2 font-semibold'>
                            <div className="text-primary flex flex-row justify-center items-center w-[350px] ">Sub-Total Bill 7</div>
                            <div className='flex flex-row w-full '>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center  w-[180px]">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[170px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[85px] ">-</div>
                                <div className="text-primary  flex flex-row justify-center items-center w-[150px] ">Rs. {formatNumberWithCommas(round(sumKValues(bill7_estimates), 0))}</div>
                            </div>
                        </div>
                    </div>
                    <Seperator />
                </div>

                <div className='flex flex-col gap-2 justify-center items-center fixed right-5 transition-all duration-300'>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill1'>Bill 1</a></p>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill2'>Bill 2</a></p>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill3'>Bill 3</a></p>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill5'>Bill 5</a></p>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill6'>Bill 6</a></p>
                    <p className={`${sideHeadingsStyle}`}><a href='#bill7'>Bill 7</a></p>
                </div>

            </div>
            <div className='flex justify-end p-2 mr-4'>
                <RoadStepAction projectId={projectId} />
                {loading ? <p className='pt-2 mr-4 pr-4'>Submiting...</p> : <Button onClick={segmentId ? handleUpdate : handleAdd} label={`${segmentId ? "Update" : "Submit"}`} className={"py-2 mr-4"} />}
            </div>
        </>
    )
}

export default BillEstimationSection


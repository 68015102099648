import React, { useEffect } from "react";
import FormStepper from "../../components/FormStepper";
import BackendLayout from "../../components/BackendLayout";
import RoadModuleSection from "../../components/road_module_forms/RoadModuleSection";
import DrawingSection from "../../components/road_module_forms/DrawingSection";
import RoadClearingGrubbingSection from "../../components/road_module_forms/RoadClearingGrubbingSection";
import CompactionOfNgSection from "../../components/road_module_forms/CompactionOfNgSection";
import CutSection from "../../components/road_module_forms/CutSection";
import FillSection from "../../components/road_module_forms/FillSection";
import SubgradeSection from "../../components/road_module_forms/SubgradeSection";
import BillEstimationSection from "../../components/road_module_forms/BillEstimationSection";
import { useDispatch } from "react-redux";

const StepsForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: "misc/setFormStep", payload: { value: 0 } });
    return () => {
      dispatch({ type: "misc/setFormStep", payload: { value: 0 } });
      dispatch({ type: "misc/setFormTotalSteps", payload: { value: 0 } });
    };
  }, []);

  return (
    <BackendLayout screenName="Modular Design">
      <FormStepper>
        <div label="Road Module Section">

          <RoadModuleSection />
        </div>
        <div label="Section Drawings" customLabel="Geometric Design Details">
          <DrawingSection />
        </div>
        <div label="Road Clearing and Grubbing">
          <RoadClearingGrubbingSection />
        </div>
        <div label="Compaction of NG">
          <CompactionOfNgSection />
        </div>
        <div label="Cut">
          <CutSection />
        </div>
        <div label="Fill" customLabel="Embankment (Fill)">
          <FillSection />
        </div>
        <div label="Subgrade">
          <SubgradeSection />
        </div>
        <div label="Bill Estimations">
          <BillEstimationSection />
        </div>
      </FormStepper>
    </BackendLayout>
  );
};

export default StepsForm;

import React, { useEffect, useReducer } from "react";
import Heading from "../../components/Heading";
import BackendLayout from "../../components/BackendLayout";
import Input from "../../components/Input";
import {
  FOUNDATION_TYPE,
  MRS_Years,
  NO_OF_LANES,
  NO_OF_SPAN,
  SKEW_ANGLE,
  SOIL_Type,
  handleAddSegment,
  handleUpdateSegment,
} from "../../helpers";
import Button from "../../components/Button";
import Breadcrumb from "../../components/Breadcumb";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { CitiesListOfKPK } from "../../helpers";
import Select from "react-select";
import Swal from "sweetalert2";

const TBridgeModule = () => {
  const { projectId, segmentId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      seismicZone: "",
      scourDepth: "",
      spanLength: "",
      abutmentHeight: "",
      soilType: "",
      foundationType: "",
      noOfLanes: "",
      noOfSpans: "",
      skewAngle: "",
      pierHeightFromBedLevel: "",
      highFloodLevelAboveBedLevel: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );

  const segmentInformation = useSelector((state) => state.project?.segmentInfo);

  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );

  const created = useSelector((state) => state.project.created);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleAddSegment(e, dispatch, {
      module: "TBeamBridge",
      projectId,
      segmentInformation,
    });
    // console.log(demoData);
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "TBeamBridge",
      projectId,
      // segmentInformation,
      segmentId,
    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "T-Bridge", itemLink: "" },
        ]}
      />
      <Heading item={"T-Bridge Module"} className={"px-8 py-5"} />
      <form
        onSubmit={(e) => {
          {
            segmentId ? handleUpdate(e) : handleAdd(e);
          }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md"> Seismic Zone</label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Seismic Zone"
                name="seismicZone"
                required
                isSearchable={true}
                value={CitiesListOfKPK.find(
                  (city) => city.value === projectData.location
                )}
                options={CitiesListOfKPK}
                onChange={(e) => {
                  currentEditedSegment &&
                    updateFormState({ seismicZone: e.value });
                }}
              />
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className=" text-segmentLabel text-md">
                Scour Depth (m)
              </label>
              <Input
                type="number"
                name="scourDepth"
                required
                min={1}
                className="text-base"
                placeholder="2.00"
                value={formState?.scourDepth}
                onChange={(e) =>
                  updateFormState({ scourDepth: e.target.value })
                }
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                High Flood Level above Bed Level (m)
              </label>
              <Input
                type="text"
                name="highFloodLevelAboveBedLevel"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                value={formState?.highFloodLevelAboveBedLevel}
                onChange={(e) =>
                  updateFormState({
                    highFloodLevelAboveBedLevel: e.target.value,
                  })
                }
              />
              {parseInt(formState?.highFloodLevelAboveBedLevel) +
                parseInt(formState?.scourDepth) +
                1 >
                12 ? (
                <span className="text-red-500">
                  The Design is not applicable
                </span>
              ) : (
                formState?.highFloodLevelAboveBedLevel && (
                  <span className="text-green-500">
                    Modular design is applicable
                  </span>
                )
              )}
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Span Length of Bridge (m) - 10 to 20
              </label>
              <Input
                type="text"
                name="spanLength"
                min={10}
                max={20}
                required
                className="text-lg  "
                value={formState?.spanLength}
                onChange={(e) =>
                  updateFormState({ spanLength: e.target.value })
                }
              />
              {parseInt(formState?.spanLength) < 10 ||
                parseInt(formState?.spanLength) > 20 ? (
                <span className="text-red-500">
                  Select value between 10 and 20
                </span>
              ) : (
                formState?.highFloodLevelAboveBedLevel && (
                  <span className="text-green-500">&#10004; </span>
                )
              )}
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Pier Height from Bed Level (m)
                {/* {formState?.pierHeightFromBedLevel <
                  parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ? (
                  <span>
                    {`(Greater than equal to ${parseInt(formState?.highFloodLevelAboveBedLevel) + 1})`}
                  </span>
                ) : parseInt(formState?.pierHeightFromBedLevel) >
                  12 - parseInt(formState?.scourDepth) ? (
                  <span>
                    {`(Less than equal to ${12 - parseInt(formState?.scourDepth)})`}
                  </span>
                ) : parseInt(formState?.pierHeightFromBedLevel) < 2 ? (
                  <span> Greater than equal to 2</span>
                ) : (
                  <span>
                    {` (
                    ${parseInt(formState?.highFloodLevelAboveBedLevel) + 1} 
                    to  ${12 - parseInt(formState?.scourDepth)})`}
                  </span>
                )} */}
              </label>
              <Input
                type="text"
                name="pierHeightFromBedLevel"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                min={3}
                max={10}
                value={formState?.pierHeightFromBedLevel}
                onChange={(e) =>
                  updateFormState({ pierHeightFromBedLevel: e.target.value })
                }
              />
              {/* 
              {formState?.pierHeightFromBedLevel <
                parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ? (<span className="text-red-500">{`The height shall be greater than or equal to ${parseInt(formState?.highFloodLevelAboveBedLevel) + 1}`}</span>)
                : parseInt(formState?.pierHeightFromBedLevel) > 12 - parseInt(formState?.scourDepth) ?
                  (<span className="text-red-500">{`The height shall be less than or equal to ${12 - parseInt(formState?.scourDepth)} `} </span>
                  ) : parseInt(formState?.pierHeightFromBedLevel) < 2 ? (<span className="text-red-500">The height shall be greater than or equal to 2                  </span>
                  ) :
                    (<span className="text-green-500"> &#10004;</span>)} */}
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Abutment Height from Bed Level (m)
                {/* {formState?.abutmentHeight < parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ? (
                  <span>{` (Greater than equal to ${parseInt(formState?.highFloodLevelAboveBedLevel) + 1})`}                  </span>

                ) :
                  parseInt(formState?.abutmentHeight) > 12 - parseInt(formState?.scourDepth) ? (
                    <span>{`Less than equal to ${12 - parseInt(formState?.scourDepth)}  `}</span>

                  ) :
                    parseInt(formState?.abutmentHeight) < 2 ? (<span> Greater than equal to 2</span>
                    ) :
                      (<span>{`(${parseInt(formState?.highFloodLevelAboveBedLevel) + 1} to ${12 - parseInt(formState?.scourDepth)})`}                      </span>
                      )} */}
              </label>
              <Input
                type="text"
                name="abutmentHeight"
                min={3}
                max={10}
                required
                className="text-lg bg-white"
                value={formState?.abutmentHeight}
                onChange={(e) =>
                  updateFormState({ abutmentHeight: e.target.value })
                }
              />
              {/* {formState?.abutmentHeight <
                parseInt(formState?.highFloodLevelAboveBedLevel) + 1 ? (
                <span className="text-red-500">
                  {` The height shall be greater than or equal to ${parseInt(formState?.highFloodLevelAboveBedLevel) + 1}`}
                </span>
              ) : parseInt(formState?.abutmentHeight) >
                12 - parseInt(formState?.scourDepth) ? (
                <span className="text-red-500">
                  {`The height shall be less than or equal to ${12 - parseInt(formState?.scourDepth)}`}
                </span>
              ) : parseInt(formState?.abutmentHeight) < 2 ? (
                <span className="text-red-500">
                  The height shall be greater than or equal to 2
                </span>
              ) : (
                <span className="text-green-500"> &#10004;</span>
              )} */}
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">Soil Type</label>

              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Soil Type"
                name="soilType"
                required
                value={SOIL_Type.find(
                  (soil) => soil.value === formState?.soilType
                )}
                options={SOIL_Type}
                onChange={(e) => updateFormState({ soilType: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Foundation Type
              </label>

              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Foundation Type"
                name="foundationType"
                required
                value={FOUNDATION_TYPE.find(
                  (f) => f.value === formState?.foundationType
                )}
                options={FOUNDATION_TYPE}
                onChange={(e) => updateFormState({ foundationType: e.value })}
              />
              {
                formState?.soilType === "C" && (formState?.foundationType && (<span className="text-green-500">&#10004;</span>))
              }
              {formState?.soilType === "D" &&
                (parseInt(formState?.abutmentHeight) >
                  6 - parseInt(formState?.scourDepth) ? (
                  formState?.foundationType === "Shallow" ? (
                    <span className="text-red-500">
                      Modular Design is not applicable. Select deep foundation
                    </span>
                  ) : (
                    formState?.foundationType && (
                      <span className="text-green-500">&#10004;</span>
                    )
                  )
                ) : (
                  formState?.foundationType && (
                    <span className="text-green-500">&#10004;</span>
                  )
                ))}
            </div>
            {/* =IF($C$17="D",IF($C$16>6-$C$12,IF(C18="shallow","Modular Design is not applicable. Select deep foundation","Select Deep or Shallow")),"Select Deep or Shallow") */}
            {/* C17 = Soil type, C16 = ABT height, C12 = SCOUR depth, C18 = Foundation type */}
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                No. of Lanes - 1 or 2
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="No of Lanes"
                name="noOfLanes"
                required
                value={NO_OF_LANES.find(
                  (f) => f.value === formState?.noOfLanes
                )}
                options={NO_OF_LANES}
                onChange={(e) => updateFormState({ noOfLanes: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                No. of Spans - 1 to 6
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="No of Spans"
                name="noOfSpans"
                required
                value={NO_OF_SPAN.find((f) => f.value === formState?.noOfSpans)}
                options={NO_OF_SPAN}
                onChange={(e) => updateFormState({ noOfSpans: e.value })}
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Skew Angle (degree)
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Skew Angle"
                name="skewAngle"
                required
                value={SKEW_ANGLE.find((f) => f.value === formState?.skewAngle)}
                options={SKEW_ANGLE}
                onChange={(e) => updateFormState({ skewAngle: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                MRS Area/Location/Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({ MRSAreaLocationCostFactor: e.target.value })
                }
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md empty:hidden" hidden>
                MRS Year
              </label>

              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <Input type="hidden" />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center p-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          {parseInt(formState?.highFloodLevelAboveBedLevel) +
            parseInt(formState?.scourDepth) +
            1 >
            12 ? (
            <Button
              label={` ${segmentId ? "Update Segment" : "Save & Submit"}`}
              className={"opacity-50 cursor-not-allowed"}
              type={"Submit"}
              disabled
            />
          ) : (
            <Button
              label={` ${segmentId ? "Update Segment" : "Add to project"}`}
              type={"Submit"}
            />
          )}
        </div>
      </form>
    </BackendLayout>
  );
};

export default TBridgeModule;

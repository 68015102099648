import React from "react";
import * as AntIcons from "react-icons/ai";
import * as FontAwesome from "react-icons/fa";
import * as Io5 from "react-icons/io5";
import * as Remix from "react-icons/ri";
import * as BoxIcons from "react-icons/bi";


export const AiIcons = ({ iconName }) => {
    if (!iconName) {
        return null;
    }
    const icon = React.createElement(AntIcons[iconName]);
    return <React.Fragment>{icon}</React.Fragment>;
};

export const FaIcons = ({ iconName }) => {
    if (!iconName) {
        return null;
    }
    const icon = React.createElement(FontAwesome[iconName]);
    return <React.Fragment>{icon}</React.Fragment>;
};

export const Io5Icons = ({ iconName }) => {
    if (!iconName) {
        return null;
    }
    const icon = React.createElement(Io5[iconName]);
    return <React.Fragment>{icon}</React.Fragment>;
};

export const RemixIcons = ({ iconName }) => {
    if (!iconName) {
        return null;
    }
    const icon = React.createElement(Remix[iconName]);
    return <React.Fragment>{icon}</React.Fragment>;
};

export const BiIcons = ({ iconName }) => {
    if (!iconName) {
        return null;
    }
    const icon = React.createElement(BoxIcons[iconName]);
    return <React.Fragment>{icon}</React.Fragment>;
};
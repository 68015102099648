import React from 'react';

const SelectorWithValues = ({ options, values, onChange, className, label, ...props }) => {
    return (
        <select
            className={`w-full p-2 flex items-center  border-gray-300 border-b-2 text-base text-primary font-poppins focus:outline-none ${className}`}
            onChange={(e) => onChange && onChange(e.target)}
            {...props}

        >
            <option value={null} >
                {label ? label : "Choose"}
            </option>
            {options.map((optionValue, index) => (
                <option key={index} value={values[index]}>
                    {optionValue}
                </option>
            ))}
        </select>
    );
};

export default SelectorWithValues;

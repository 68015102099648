import Swal from "sweetalert2";
import { POSITION } from "../../helpers/constants";
import { STATUS_CODE } from "../../helpers";
// import { getError } from "../../util/util";

export function* generalActionAsync({ payload = {} }) {
  const notify = ({
    type = "success",
    message = "Successfull!",
    position = POSITION.TOP_CENTER,
  }) => {
    Swal.fire({
      position: position,
      icon: type,
      toast: true,
      title: message,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  // updated = false,
  const { response = {}, created = false, code = "", message = "" } = payload;
  if (created) {
    yield notify({ message });
  }

  if (code === STATUS_CODE.ERR_BAD_RESPONSE || code === STATUS_CODE.TypeError) {
    let errorMessage = message
      ? message
      : "Something went wrong, please try again";

    if (response?.status > 204) {
      errorMessage = response?.data?.message;
      console.log(response?.data);
      // errorMessage = getError(response?.data?.message);
    }

    if (response?.status === 401) {
      errorMessage = "Session was expired, please login again";
      window.location = "/login";
    }

    notify({
      message: errorMessage,
      type: "error",
    });
  }
}

import React from 'react'

const Heading = ({ item, id, className }) => {
    return (
        <div className={`flex flex-1  items-center font-bold text-xl  font-poppins ${className}`} id={id}>
            {item}
        </div>
    )
}

export default Heading
// store.js
import { combineReducers } from 'redux';
import { projectSlice, userSlice, roadSlice, miscSlice } from './reducers';
import { configureStore, } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { watcherSaga } from './saga';
const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'modular-frontend-root',
    storage,
    blacklist: ['road']

};

const rootReducer = combineReducers({
    project: projectSlice.reducer,
    user: userSlice.reducer,
    road: roadSlice.reducer,
    misc: miscSlice.reducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);



const middlewares = [logger, sagaMiddleware];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares,
});
sagaMiddleware.run(watcherSaga);
export const persistor = persistStore(store);

import React, { useState } from 'react';
import img1 from "../../assets/drawing_images/Drawing_Img_1.png";
import img2 from "../../assets/drawing_images/Drawing_Img_2.png";
import img3 from "../../assets/drawing_images/Drawing_Img_3.png";
import DrawingInput from '../DrawingInput';
import Label from "../Label";
import Button from "../Button";
import { useDispatch, useSelector } from 'react-redux';
import RoadStepAction from './RoadStepAction';
import { useParams } from 'react-router-dom';
export const sections = [
    { name: "img1", inputNames: ['wrs', 'pgl', 'rsis', 'rshs', 'lwr', 'rcs', 'lhash', 'wls', 'lsis', 'lshs', 'lwl', 'lcs'] },
    { name: "img2", inputNames: ['wrs', 'pgl', 'rsis', 'rshs', 'lwr', 'rcs', 'lhash', 'wls', 'lsis', 'lshs', 'lwl', 'lcs'] },
    { name: "img3", inputNames: ['wrs', 'pgl', 'rsis', 'rshs', 'lwr', 'rcs', 'lhash', 'wls', 'lsis', 'lshs', 'lwl', 'lcs'] },
];
const assignName = (section, index) => {
    return `${sections[section].name}_${sections[section].inputNames[index]}`;
}

const DrawingSection = () => {
    const dispatch = useDispatch();
    let { projectId, segmentId } = useParams();
    const drawingInputValues = useSelector(state => state.road.drawingInputValues);
    const keysObject = sections.reduce((acc, { name, inputNames }) => {

        const keys = {};
        inputNames.forEach(i => {
            keys[name + "_" + i] = "";
        });
        acc[name] = keys;
        return acc;
    }, {});

    //handleChange lets user enter value into selected inputs
    const handleChange = ({ value, name }) => {

        const selectedInputValue = updateSelectedInputValue({ value, name })
        const { updatedInputValue } = selectedInputValue;
        dispatch({ type: "road/setDrawingInputValues", payload: updatedInputValue });
    };


    //     //updateSelectedInputValue is used to navigate to index of input value to be updated in inputValues objects of relevant Image object
    const updateSelectedInputValue = ({ value, name }) => {

        const nameParts = name.split("_");
        const imageName = nameParts[0];
        const inputName = nameParts[1];
        const updatedInputValue = {
            ...drawingInputValues,
            [imageName]: {
                ...drawingInputValues[imageName],
                [name]: value
            }
        };
        // handleCopyFromImg1();
        return { value, name, inputName, imageName, updatedInputValue };
    }

    //Todo
    function handleCopyFromImg1() {
        let newDrawingInputValues = { ...drawingInputValues };

        Object.keys(drawingInputValues.img1).forEach(inputKey => {
            const inputData = drawingInputValues.img1[inputKey];
            const newInputKey = inputKey.replace("img1", "img2");
            if (inputData !== "") {
                newDrawingInputValues = { ...newDrawingInputValues, img2: { ...newDrawingInputValues.img2, [newInputKey]: inputData } }
            }
        });
        dispatch({ type: "road/setDrawingInputValues", payload: newDrawingInputValues });
    }

    //Todo
    function handleCopyFromImg2() {
        let newDrawingInputValues = { ...drawingInputValues };

        Object.keys(drawingInputValues.img2).forEach(inputKey => {
            const inputData = drawingInputValues.img2[inputKey];
            const newInputKey = inputKey.replace("img2", "img3");
            if (inputData !== "") {
                newDrawingInputValues = { ...newDrawingInputValues, img3: { ...newDrawingInputValues.img3, [newInputKey]: inputData } }
            }
        });
        dispatch({ type: "road/setDrawingInputValues", payload: newDrawingInputValues });
    }
    //updateNotSelectedInputFields is used to updated all related input field for first time only, it takes image name of whose input was updated
    //then uses that Imagename to compare other imageNames in sections array with empty input fields 
    const updateNotSelectedInputFields = ({ value, inputName, imageName, updatedInputValue }) => {

        const valuesToBeUpdated = sections.filter(i => i.name.includes(imageName) !== true);

        valuesToBeUpdated.map(i => {

            const otherSectionImageName = i.name;

            const otherSectionInputName = otherSectionImageName + "_" + inputName;

            const otherSectionInputValue = updatedInputValue[otherSectionImageName];

            const isEmpty = otherSectionInputValue[otherSectionInputName] === "" || otherSectionInputValue[otherSectionInputName] === undefined;

            if (isEmpty) {
                updatedInputValue[otherSectionImageName][otherSectionInputName] = value;
            }
        });

        return updatedInputValue;
    };

    return (
        <div>
            <p className='px-7 py-2'>Typically fill showing various elements</p>
            <div className='flex flex-col gap-5 items-center py-2 '>
                <div className='relative w-11/12 shadow-lg border-gray-200 border' >

                    <span className='absolute ml-[75%] mt-[6.8%] desktopSm:ml-[75.5%] desktopSm:mt-[7%] desktopMd:ml-[76%] desktopMd:mt-[7%] desktopXmd:ml-[76%] desktopXmd:mt-[7.3%] '>
                        <DrawingInput inputId={assignName(0, 0)} label='WRS' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 0)]} imgName={sections[0].name} required name={assignName(0, 0)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[57%] mt-[4%] desktopSm:ml-[57.5%] desktopSm:mt-[4.2%] desktopMd:ml-[57%] desktopMd:mt-[4.5%] desktopXmd:ml-[57%] desktopXmd:mt-[4.5%] '>
                        <DrawingInput inputId={assignName(0, 1)} label='PGL' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 1)]} imgName={sections[0].name} required name={assignName(0, 1)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[90%] mt-[11%] desktopSm:ml-[90%] desktopSm:mt-[11.2%] desktopMd:ml-[91%] desktopMd:mt-[10.8%] desktopXmd:ml-[90.5%] desktopXmd:mt-[11.3%] '>
                        <DrawingInput inputId={assignName(0, 2)} label='RSIS' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 2)]} imgName={sections[0].name} required name={assignName(0, 2)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[72.5%] mt-[11.5%] desktopSm:ml-[73.2%] desktopSm:mt-[11.5%] desktopMd:ml-[73.5%] desktopMd:mt-[11.5%] desktopXmd:ml-[73.5%] desktopXmd:mt-[11.6%]  '>
                        <DrawingInput inputId={assignName(0, 3)} label='RSHS%' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 3)]} imgName={sections[0].name} required name={assignName(0, 3)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[60.2%] mt-[9.3%] desktopSm:ml-[60%] desktopSm:mt-[9.5%] desktopMd:ml-[60%] desktopMd:mt-[9.5%] desktopXmd:ml-[60%] desktopXmd:mt-[9.5%]  '>
                        <DrawingInput inputId={assignName(0, 4)} label='LWR' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 4)]} imgName={sections[0].name} required name={assignName(0, 4)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[52.5%] mt-[11.3%] desktopSm:ml-[52.8%] desktopSm:mt-[11.3%] desktopMd:ml-[52.8%] desktopMd:mt-[11.5%] desktopXmd:ml-[52.5%] desktopXmd:mt-[11.6%] '>
                        <DrawingInput inputId={assignName(0, 5)} label='RCS%' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 5)]} imgName={sections[0].name} required name={assignName(0, 5)} onChange={handleChange} />
                    </span>


                    <span className='absolute ml-[20%] mt-0 desktopSm:ml-[20%] desktopSm:mt-0 desktopMd:ml-[20%] desktopMd:mt-0 desktopXmd:ml-[20%] desktopXmd:mt-0  '>
                        <DrawingInput inputId={assignName(0, 6)} label='L#' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 6)]} imgName={sections[0].name} required name={assignName(0, 6)} onChange={handleChange} />
                    </span>

                    <span className='absolute  ml-[20%] mt-[7%] desktopSm:ml-[20%] desktopSm:mt-[7%] desktopMd:ml-[20%] desktopMd:mt-[7%] desktopXmd:ml-[20%] desktopXmd:mt-[7%]  '>
                        <DrawingInput inputId={assignName(0, 7)} label='WLS' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 7)]} imgName={sections[0].name} required name={assignName(0, 7)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[5%] mt-[10.5%] desktopSm:ml-[5%] desktopSm:mt-[10.8%] desktopMd:ml-[5%] desktopMd:mt-[10.8%] desktopXmd:ml-[5%] desktopXmd:mt-[10.8%]  '>
                        <DrawingInput inputId={assignName(0, 8)} label='LSIS' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 8)]} imgName={sections[0].name} required name={assignName(0, 8)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[22%] mt-[11.5%] desktopSm:ml-[22%] desktopSm:mt-[11.8%] desktopMd:ml-[22%] desktopMd:mt-[11.8%] desktopXmd:ml-[22%] desktopXmd:mt-[11.8%] '>
                        <DrawingInput inputId={assignName(0, 9)} label='LSHS%' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 9)]} imgName={sections[0].name} required name={assignName(0, 9)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[35.3%] mt-[9.3%] desktopSm:ml-[35.3%] desktopSm:mt-[9.5%] desktopMd:ml-[35.5%] desktopMd:mt-[9.5%] desktopXmd:ml-[37.5%] desktopXmd:mt-[9.5%]'>
                        <DrawingInput inputId={assignName(0, 10)} label='LWL' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 10)]} imgName={sections[0].name} required name={assignName(0, 10)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[44.2%] mt-[11.3%] desktopSm:ml-[44.4%] desktopSm:mt-[11.3%] desktopMd:ml-[44.4%] desktopMd:mt-[11.5%] desktopXmd:ml-[44.5%] desktopXmd:mt-[11.5%]'>
                        <DrawingInput inputId={assignName(0, 11)} label='LCS%' value={drawingInputValues[sections[0].name] && drawingInputValues[sections[0].name][assignName(0, 11)]} imgName={sections[0].name} required name={assignName(0, 11)} onChange={handleChange} />
                    </span>
                    <span className='text-primary font-roboto absolute underline underline-offset-1 ml-[1%] mt-[1%] desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl text-lg'>Image-1</span>

                    <img src={img1} alt='darwing img 1' className='w-full' />

                </div>

                <div className='  relative w-11/12 shadow-lg border-gray-200 border'>
                    <span className='text-primary font-roboto absolute underline underline-offset-1 ml-[1%] mt-[1%] text-lg desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl '>Image-2</span>
                    <Button className='border-gray-200 flex flex-row items-center gap-2 absolute w-fit ml-[80%] mt-[1%] ' onClick={handleCopyFromImg1} label={<Label className={"text-white font-roboto cursor-pointer"}>Copy From Image-1</Label>} />

                    <span className=' absolute ml-[78%] mt-[6.8%] desktopSm:ml-[78%] desktopSm:mt-[7%] desktopMd:ml-[78%] desktopMd:mt-[7%] desktopXmd:ml-[78%] desktopXmd:mt-[7.3%] '>
                        <DrawingInput inputId={assignName(1, 0)} label='WRS' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 0)]} required name={assignName(1, 0)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[58%] mt-[3.5%] desktopSm:ml-[58%] desktopSm:mt-[3.8%] desktopMd:ml-[58%] desktopMd:mt-[3.8%] desktopXmd:ml-[58%] desktopXmd:mt-[3.8%] '>
                        <DrawingInput inputId={assignName(1, 1)} label='PGL' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 1)]} required name={assignName(1, 1)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[92.2%] mt-[12%] desktopSm:ml-[92.2%] desktopSm:mt-[12.6%] desktopMd:ml-[92.3%] desktopMd:mt-[12.8%] desktopXmd:ml-[94%] desktopXmd:mt-[12.8%] '>
                        <DrawingInput inputId={assignName(1, 2)} label='RSIS' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 2)]} required name={assignName(1, 2)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[76%] mt-[13%] desktopSm:ml-[76.2%] desktopSm:mt-[13.2%] desktopMd:ml-[76.5%] desktopMd:mt-[13.2%] desktopXmd:ml-[76.5%] desktopXmd:mt-[13.2%]  '>
                        <DrawingInput inputId={assignName(1, 3)} label='RSHS%' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 3)]} required name={assignName(1, 3)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[60.2%] mt-[9.3%] desktopSm:ml-[60%] desktopSm:mt-[9.5%] desktopMd:ml-[60%] desktopMd:mt-[9.5%] desktopXmd:ml-[60%] desktopXmd:mt-[9.5%]    '>
                        <DrawingInput inputId={assignName(1, 4)} label='LWR' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 4)]} required name={assignName(1, 4)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[60.2%] mt-[13%] desktopSm:ml-[60%] desktopSm:mt-[13%] desktopMd:ml-[60.4%] desktopMd:mt-[13%] desktopXmd:ml-[60.5%] desktopXmd:mt-[13%] '>
                        <DrawingInput inputId={assignName(1, 5)} label='RCS%' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 5)]} required name={assignName(1, 5)} onChange={handleChange} />
                    </span>


                    <span className=' absolute ml-[18.5%] mt-0 desktopSm:ml-[20%] desktopSm:mt-0 desktopMd:ml-[20%] desktopMd:mt-0 desktopXmd:ml-[20%] desktopXmd:mt-0  '>
                        <DrawingInput inputId={assignName(1, 6)} label='L#' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 6)]} required name={assignName(1, 6)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[18.5%] mt-[7%] desktopSm:ml-[20%] desktopSm:mt-[7%] desktopMd:ml-[20%] desktopMd:mt-[7%] desktopXmd:ml-[20%] desktopXmd:mt-[7%]  '>
                        <DrawingInput inputId={assignName(1, 7)} label='WLS' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 7)]} required name={assignName(1, 7)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[3.3%] mt-[10.5%] desktopSm:ml-[3.5%] desktopSm:mt-[10.5%] desktopMd:ml-[3.5%] desktopMd:mt-[10.5%] desktopXmd:ml-[3.5%] desktopXmd:mt-[10.5%] '>
                        <DrawingInput inputId={assignName(1, 8)} label='LSIS' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 8)]} required name={assignName(1, 8)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[16%] mt-[11%] desktopSm:ml-[16.4%] desktopSm:mt-[11.4%] desktopMd:ml-[16.5%] desktopMd:mt-[11.5%] desktopXmd:ml-[17%] desktopXmd:mt-[11.3%]'>
                        <DrawingInput inputId={assignName(1, 9)} label='LSHS%' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 9)]} required name={assignName(1, 9)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[35.3%] mt-[9.3%] desktopSm:ml-[35.3%] desktopSm:mt-[9.5%] desktopMd:ml-[35.5%] desktopMd:mt-[9.5%] desktopXmd:ml-[37.5%] desktopXmd:mt-[9.5%] '>
                        <DrawingInput inputId={assignName(1, 10)} label='LWL' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 10)]} required name={assignName(1, 10)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[36.5%] mt-[12.3%] desktopSm:ml-[36.4%] desktopSm:mt-[12.3%] desktopMd:ml-[36.6%] desktopMd:mt-[12.2%] desktopXmd:ml-[37.2%] desktopXmd:mt-[12.2%] '>
                        <DrawingInput inputId={assignName(1, 11)} label='LCS%' value={drawingInputValues[sections[1].name] && drawingInputValues[sections[1].name][assignName(1, 11)]} required name={assignName(1, 11)} onChange={handleChange} />
                    </span>

                    <img src={img2} alt='darwing img 2' className='w-full' />
                </div>

                <div className='  relative w-11/12 shadow-lg border-gray-200 border'>

                    <span className='absolute ml-[73%] mt-[7.2%] desktopSm:ml-[73%] desktopSm:mt-[7.3%] desktopMd:ml-[73%] desktopMd:mt-[7.3%] desktopXmd:ml-[73.2%] desktopXmd:mt-[7.3%] '>
                        <DrawingInput inputId={assignName(2, 0)} label='WRS' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 0)]} required name={assignName(2, 0)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[34.7%] mt-[4%] desktopSm:ml-[35%] desktopSm:mt-[4.2%] desktopMd:ml-[35%] desktopMd:mt-[4.2%] desktopXmd:ml-[35.2%] desktopXmd:mt-[4.2%]  '>
                        <DrawingInput inputId={assignName(2, 1)} label='PGL' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 1)]} required name={assignName(2, 1)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[88%] mt-[10.3%] desktopSm:ml-[88%] desktopSm:mt-[10.6%] desktopMd:ml-[88%] desktopMd:mt-[10.8%] desktopXmd:ml-[88%] desktopXmd:mt-[10.8%] '>
                        <DrawingInput inputId={assignName(2, 2)} label='RSIS' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 2)]} required name={assignName(2, 2)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[75%] mt-[11.5%] desktopSm:ml-[75.2%] desktopSm:mt-[11.5%] desktopMd:ml-[75.5%] desktopMd:mt-[11.5%] desktopXmd:ml-[75.5%] desktopXmd:mt-[11.5%]   '>
                        <DrawingInput inputId={assignName(2, 3)} label='RSHS%' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 3)]} required name={assignName(2, 3)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[56%] mt-[9.2%] desktopSm:ml-[56%] desktopSm:mt-[9.5%] desktopMd:ml-[56%] desktopMd:mt-[9.5%] desktopXmd:ml-[56%] desktopXmd:mt-[9.5%]'>
                        <DrawingInput inputId={assignName(2, 4)} label='LWR' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 4)]} required name={assignName(2, 4)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[56%] mt-[12.5%] desktopSm:ml-[56%] desktopSm:mt-[12.5%] desktopMd:ml-[56%] desktopMd:mt-[12.5%] desktopXmd:ml-[56%] desktopXmd:mt-[12.5%] '>
                        <DrawingInput inputId={assignName(2, 5)} label='RCS%' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 5)]} required name={assignName(2, 5)} onChange={handleChange} />
                    </span>


                    <span className='absolute ml-[16%] mt-0 desktopSm:ml-[16%] desktopSm:mt-0 desktopMd:ml-[16%] desktopMd:mt-0 desktopXmd:ml-[16%] desktopXmd:mt-0 '>
                        <DrawingInput inputId={assignName(2, 6)} label='L#' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 6)]} required name={assignName(2, 6)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[16%] mt-[7%] desktopSm:ml-[16%] desktopSm:mt-[7.2%] desktopMd:ml-[16%] desktopMd:mt-[7%] desktopXmd:ml-[16%] desktopXmd:mt-[7%] '>
                        <DrawingInput inputId={assignName(2, 7)} label='WLS' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 7)]} required name={assignName(2, 7)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[1%] mt-[12.2%] desktopSm:ml-[1%] desktopSm:mt-[12.5%] desktopMd:ml-[1.2%] desktopMd:mt-[12.5%] desktopXmd:ml-[1.5%] desktopXmd:mt-[12.5%]  '>
                        <DrawingInput inputId={assignName(2, 8)} label='LSIS' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 8)]} required name={assignName(2, 8)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[17%] mt-[13%] desktopSm:ml-[17%] desktopSm:mt-[13%] desktopMd:ml-[17%] desktopMd:mt-[13%] desktopXmd:ml-[17%] desktopXmd:mt-[13.3%] '>
                        <DrawingInput inputId={assignName(2, 9)} label='LSHS%' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 9)]} required name={assignName(2, 9)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[34%] mt-[9.3%] desktopSm:ml-[34%] desktopSm:mt-[9.5%] desktopMd:ml-[34%] desktopMd:mt-[9.5%] desktopXmd:ml-[34%] desktopXmd:mt-[9.5%]  '>
                        <DrawingInput inputId={assignName(2, 10)} label='LWL' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 10)]} required name={assignName(2, 10)} onChange={handleChange} />
                    </span>

                    <span className='absolute ml-[32.5%] mt-[12.8%] desktopSm:ml-[32.5%] desktopSm:mt-[12.8%] desktopMd:ml-[32.5%] desktopMd:mt-[12.8%] desktopXmd:ml-[32.6%] desktopXmd:mt-[12.9%]'>
                        <DrawingInput inputId={assignName(2, 11)} label='LCS%' value={drawingInputValues[sections[2].name] && drawingInputValues[sections[2].name][assignName(2, 11)]} required name={assignName(2, 11)} onChange={handleChange} />
                    </span>
                    <span className='text-primary font-roboto absolute underline underline-offset-8 decoration-2  ml-[1%] mt-[1%] desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl desktopXlg:text-5xl text-lg'>Image-3</span>
                    {/* <span className='flex flex-row items-center gap-2 absolute w-fit ml-[80%] mt-[1%] underline decoration-2 underline-offset-4' onClick={handleCopyFromImg2}><Label className={"text-red-600 font-roboto cursor-pointer"}>Copy From Image-2</Label></span> */}
                    <Button className='border-gray-200 flex flex-row items-center gap-2 absolute w-fit ml-[80%] mt-[1%]' onClick={handleCopyFromImg2} label={<Label className={"text-white font-roboto cursor-pointer"}>Copy From Image-2</Label>} />


                    <img src={img3} alt='darwing img 3' className='w-full' />
                </div>
            </div>
            <div className='flex justify-end px-12 py-5 '>
                <RoadStepAction projectId={projectId} />
            </div>
        </div>
    );
}

export default DrawingSection
import React, { useState } from "react";
import { FaIcons, Io5Icons, RemixIcons } from "./Icons";
import { Link, useNavigate } from "react-router-dom";
import profile_pic from "../assets/profile_pic.png";
import { useDispatch } from "react-redux";
import { userLogout } from "../store/actions";
import { IoIosArrowDropdownCircle } from "react-icons/io";

export default function SubHeader({ ...props }) {
  const [isprofilePic, setIsProfilePic] = useState(false);
  const [isDropDownVisible, setIsDropDownVisible] = useState(false);
  const arrowDropDown = "RiArrowDropDownLine";
  const toggleDropDown = () => {
    setIsDropDownVisible(!isDropDownVisible);
  };


  return (
    <div className="flex flex-row items-center p-4 desktopSm:p-5 desktopMd:p-6 desktopXmd:p-7 desktopLg:p-8 bg-white border-gray-100  border-b-2">
      <div className="flex flex-row flex-1 h-full w-fit items-center px-5">
        <p className="text-primary font-bold text-xl desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl font-poppins">
          {props.screenName}
        </p>
      </div>

      <div className="flex flex-row flex-1 justify-end items-center gap-2  relative px-5 font-poppins font-medium">
        <div
          className="flex flex-row items-center hover:cursor-pointer text-md desktopSm:text-2xl destopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl"
          onClick={toggleDropDown}
        >
          {props.userName}
          <RemixIcons
            iconName={arrowDropDown}
            className="text-2xl desktopMd:text-3xl
          desktopXmd:text-4xl desktopLg:text-5xl"
          />
          <div
            className={`${isDropDownVisible ? "block" : "hidden"
              } absolute mt-48 -ml-10 z-20 `}
          >
            <ProfileDropDown />
          </div>
        </div>
        {isprofilePic ? (
          <div className=" rounded-full flex items-center justify-center  h-full">
            <img
              className="text-center text-white w-10 rounded-full"
              src={profile_pic}
              alt="profile pic"
            />
          </div>
        ) : (
          <div className="bg-primary rounded-full flex items-center justify-center w-10 h-10   desktopMd:w-24 desktopMd:h-24 desktopXmd:w-28 desktopXmd:h-28 desktopLg:w-36 desktopLg:h-36">
            <span className="flex items-center justify-center w-10/12 text-xl desktopSm:text-2xl  desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl text-center text-white">
              {props.userImg}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export const ProfileDropDown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userIcon = "FaRegUser";
  const settingsIcon = "IoSettingsOutline";
  const logoutIcon = "IoExitOutline";

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(userLogout());
    return navigate("/home");
  };

  return (
    <div className="flex flex-col bg-white p-2 border border-label rounded font-poppins">
      <ul className=" text-label  flex flex-col  text-sm w-full  ">
        <li className="hover:bg-gray-100 hover:text-primary">
          <Link
            to={"/profile"}
            className="flex flex-row items-center gap-2 p-2"
          >
            {" "}
            <FaIcons
              iconName={userIcon}
              className=" desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl"
            />{" "}
            <span className="p-1">My Profile</span>
          </Link>
        </li>
        <li className="hover:bg-gray-100 hover:text-primary">
          <Link to={"/settings"} className="flex flex-row items-center gap-2 p-2">
            {" "}
            <Io5Icons
              iconName={settingsIcon}
              className="   desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl"
            />{" "}
            <span> Settings</span>
          </Link>
        </li>
        <li className="hover:bg-gray-100 hover:text-primary">
          <Link
            onClick={(e) => {
              handleLogout(e);
            }}
            className="flex flex-row items-center gap-2 p-2"
          >
            {" "}
            <Io5Icons
              iconName={logoutIcon}
              className="   desktopMd:text-3xl desktopXmd:text-4xl desktopLg:text-5xl"
            />{" "}
            <span>Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

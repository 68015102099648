import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "userData",
  initialState: null,
  reducers: {
    userLogin(state, action) {
      return action.payload;
    },
    userLogout(state) {
      return null;
    },
  },
});

import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Card = ({ label = "card", icon, lightIcon, className, navigate }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`flex flex-col justify-center items-center  font-poppins bg-white text-sm  text-primary text-base
                            w-52 h-52  rounded-lg border-gray-200 border shadow-lg shadow-black hover:shadow-secondary
                             hover:bg-primary hover:text-white hover:ease-in-out transition hover:-translate-y-10
                             duration-300 delay-50 hover:rounded-lg hover:border-primary cursor-pointer ${className} `}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={navigate ?? null}
    >
      <>
        {icon ? (
          <>
            {isHovered ? (

              <img src={lightIcon} className={"pointer-events-none select-none"} />
            ) : (
              <img src={icon} className="text-primary pointer-events-none select-none" />
            )}
          </>
        ) : (
          <p>Icon not provided</p>
        )}
      </>
      <div className="text-center p-2">
        {isHovered ? (
          <span className="font-poppins font-bold text-white">{label}</span>
        ) : (
          <span className="font-poppins font-bold text-primary">{label}</span>
        )}
      </div>
    </div>
  );
};

export default Card;

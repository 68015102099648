import React, { useEffect, useState } from "react";
import {
  FaCloudDownloadAlt,
  FaImage,
  FaRegFilePdf,
} from "react-icons/fa";
import { BsFiletypeDocx } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import Footer from "../components/Footer";
import download_bg from "../assets/download_bg.png";
import DownloadScreenHeader from "../components/DownloadScreenHeader";
const DownloadDocPage = () => {
  const { name } = useParams();
  useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  })
  const folderLinks = {
    Standardised_Modular_Designs: Standardised_Modular_Designs,
    Bridge_Code: Bridge_Code,
    Technical_Appresial: Technical_Appresial,
    Mode_Tors: Mode_Tors,
    Material_Testing: Material_Testing,
    Project_Implementation: Project_Implementation,
    CRI_Guidelines_Handbook: CRI_Guidelines_Handbook
  };

  const fileTypes = {
    pdf: <FaRegFilePdf color="red" size={24} />,
    docx: <BsFiletypeDocx color="#0693e3" size={24} />,
    jpeg: <FaImage color="#263238" size={24} />,
  };

  const headingName = {
    Standardised_Modular_Designs: "Standardised Modular Designs",
    Bridge_Code: "Bridge Code",
    Technical_Appresial: "Technical Apprasial Checklist",
    Mode_Tors: "Terms of Reference (TORs)",
    Material_Testing: "Materials and Qualification Testing",
    Project_Implementation: "Project Implementation Policy",
    CRI_Guidelines_Handbook: "Climate Resilient Infrastructure (CRI) Handbook",
  };

  return (
    <>
      <DownloadScreenHeader />
      <div className="flex">
        <img
          src={download_bg}
          alt="download_bg"
          className="w-full h-[60vh] object-cover"
        />
      </div>

      <div className="flex h-screen flex-col w-full items-center -mt-16 overflow-auto ">
        <div className=" w-4/5 flex flex-col bg-white rounded-md shadow px-4 py-4">
          <text className="text-center text-2xl font-poppins font-bold text-primary mb-2">
            {headingName[name]}
          </text>
          <div className="text-primary font-poppins p-4 text-justify">
            {content[name]}
          </div>
          {folderLinks[name].map((item, index) => {
            return (
              <div className="w-full flex flex-row items-center my-1 bg-gray-100 rounded-md py-4 px-4 justify-between">
                <div className="flex flex-row items-center gap-6">
                  {fileTypes[item.fileType]}
                  <text className=" text-lg font-poppins text-primary">
                    {item.name}
                  </text>
                </div>
                <Link to={item.link} target="_blank" download>
                  <FaCloudDownloadAlt color="#263238" size={24} />
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default DownloadDocPage;
const content = {
  Standardised_Modular_Designs: <React.Fragment>
    <div className="flex flex-col justify-center items-center gap-12">
    <div>
    <p>o	Small roads and bridges infrastructure projects (that cost less than Rs500 million) are usually managed by Khyber Pakhtunkhwa’s Communications and Works (C&W) Department without hiring consultants. To allow engineers to prepare Planning Commission Forms (PC-Is) quickly and accurately, this tool provides standardised modular designs for small roads, bridges, and allied infrastructure. After inputting design parameters and select the appropriate year of the market rates system (MRS), the user will receive detailed cost estimates and engineer drawings.</p>
    </div>
    <Link to="/login"><button className="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded">Click to Use PIM Portal</button></Link>
    </div>
  </React.Fragment>,
  Technical_Appresial: <React.Fragment>
    <p>The Technical Appraisal Checklist has been developed to address inconsistencies in the appraisal process of PC-Is. This checklist aligns with the PC-I proforma and the Manual for Development Projects prepared by the Planning Commission of Pakistan. It ensures that all required technical parameters, including geotechnical investigations, structural designs, climate resilience, are incorporated into the design of a project. The checklist will allow appraisers to assess projects with a common yardstick and help engineers to ensure their PC-Is will pass the appraisal process.</p>
  </React.Fragment>,
  Mode_Tors: <React.Fragment>
    <p>Model Terms of Reference (TORs) for bridges, roads, and buildings have been developed to assist engineers prepare project TORs. Using these TORs as a template, engineers can ensure effective project planning and execution and avoid cost overruns and delays.</p>
    </React.Fragment>,
  Material_Testing: <React.Fragment>
    <p>To ensure that the design and execution of projects produce reliable infrastructure, material and qualification testing should be conducted. Materials testing involves assessing the strength and durability of the components used in infrastructure, while qualification testing evaluates the design and manufacturing process of projects. The Planning and Development Department has developed guidelines for materials and qualification testing, as provided below.</p>
    </React.Fragment>,
  Bridge_Code: <React.Fragment>
    <p>Although several building codes were developed after the 2005 earthquake, no code regarding highway bridges existed. To ensure the safety and durability of bridges, the KP government has signed a contract to adopt an American bridge code called the AASHTO LRFD Bridge Design Specifications. The code has been enhanced to better fit local requirements by incorporating features like live load models, seismic provisions, and flood safety measures.</p>
    </React.Fragment>,
  CRI_Guidelines_Handbook: <React.Fragment>
    <p>Amid the global climate change crisis, Pakistan’s infrastructure requires robust, climate-resilient design. The Planning and Development Department has developed a comprehensive handbook that provides detailed guidelines on designing and building CRI. The handbook covers climate resilience in five domains, i.e. technical, environmental, social, economic and governance. Moreover, checklists for each infrastructure category are provided to allow engineers to incorporate essential aspects of climate resilience in their project designs.</p>
     </React.Fragment>,
  Project_Implementation: <React.Fragment>
    <p>The Government of Khyber Pakhtunkhwa (KP) has developed a guiding implementation policy for all approved projects. This policy includes guidelines on developing planning commission forms (PC-Is), financial and procurement management, and monitoring and evaluation of projects. Consulting this policy will allow engineers to plan and execute efficient, successful projects.</p>
    </React.Fragment>,
}

const Standardised_Modular_Designs = [];
const Bridge_Code = [
  {
    name: "KP Bridge Code Draft",
    link: "https://drive.google.com/uc?export=download&id=1IqTsIQkuyfh-rgnfKzDJOvw5Y0M6-7bo",
    fileType: "pdf",
  }
];

const Technical_Appresial = [
  {
    name: "Technical Appraisal Checklist.",
    link: "https://drive.google.com/uc?export=download&id=1hP2vg1ogvMNoP5Dn9uJnY66lL7uFRIs3",
    fileType: "pdf",
  },
];

const Mode_Tors = [
  {
    name: "Guidelines for Budget Allocations.",
    link: "https://drive.google.com/uc?export=download&id=1COCpbyu6ekvHS5enDevZElgfevXSjWHx",
    fileType: "docx",
  },
  {
    name: "Model TORs for Buildings.",
    link: "https://docs.google.com/uc?export=download&id=1TUueyJaUcG2Ud3WR_NchxTu53_efO1gL",
    fileType: "docx",
  },
  {
    name: "Model TORs for Bridges.",
    link: "https://docs.google.com/uc?export=download&id=1UBiqJ7K-92J47_XmZabqoYgoiSzKzp5b",
    fileType: "docx",
  },
  {
    name: "Model TORs for Roads.",
    link: "https://docs.google.com/uc?export=download&id=1WsFWwaVcDo_ZMg6DPTAMEYJyDz_V39nG",
    fileType: "docx",
  },
];

export const Material_Testing = [
  {
    name: "Materials Testing Policy.",
    link: "https://drive.google.com/uc?export=download&id=1AcgSgEl5ehUKYlNoh00VRR13Cc99WO2F",
    fileType: "docx",
  },
  {
    name: "Qualification Resting Protocols.",
    link: "https://drive.google.com/uc?export=download&id=1XhkNm6RKgjwczB0E3OYWpLTWatX00hew",
    fileType: "docx",
  },
];

export const Project_Implementation = [
  {
    name: "Project Implementation Policy",
    link: "https://drive.google.com/uc?export=download&id=1ks5FPEaYZZG2xt4WfurbMwsWQ4jXnws0",
    fileType: "pdf",
  }
];
export const CRI_Guidelines_Handbook = [
  {
    name: "CRI Guidelines Handbook",
    link: "https://drive.google.com/file/d/1nh_Ucjx_xqk80Y3GRi-gLqjF7VHZky4R/view",
    fileType: "pdf",
  }
]

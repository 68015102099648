import React from "react";
import logo from "../assets/logo-bg-white.png";
import SubHeader from "./SubHeader";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <div className="bg-primary text-white h-screen w-fit flex flex-col gap-8 p-2">
      <Link
        className=" font-bold p-2 flex flex-row items-center  gap-4"
        to={"/home"}
      >
        <img
          src={logo}
          alt="logo"
          className="h-20 w-20 desktopSm:w-22 desktopSm:h-22 desktopMd:w-28 desktopMd:h-28 desktopXmd:h-32 desktopXmd:w-32"
        />
        <p className="font-bold text-sm w-1/2 desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl">
          Communications & Work Departments
        </p>
      </Link>

      <div className="py-2 px-4 flex flex-col gap-4 desktopMd:gap-8 desktopXmd:gap-12 font-poppins">
        <p className="text-secondary text-base desktopMd:text-2xl desktopXmd:text-4xl desktopLg:text-5xl font-bold">
          Projects
        </p>
        <ul className="flex flex-col desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-4xl desktopMd:gap-4 desktopXmd:gap-6 desktopLg:gap-8 desktopXlg:gap-12 pl-3 text-sm  text-gray-400">
          {/* <li className="py-2 hover:text-secondary"><Link to="/projects">Dashboard</Link></li> */}
          <li className="py-2 hover:text-secondary">
            <Link to="/projects">Projects</Link>
          </li>
          <li className="py-2 hover:text-secondary">
            <Link to="/instructions">Instruction for Use</Link>
          </li>
        </ul>
        <p className="text-secondary pt-5  text-base desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl font-bold">
          Supporting Documents
        </p>
        <ul className="flex flex-col desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-4xl desktopMd:gap-4 desktopXmd:gap-6 desktopLg:gap-8 desktopXlg:gap-12  pl-3 text-gray-400 text-sm">
          <li className="py-2 hover:text-secondary">
            <Link to="/adt">Weekly ADT Count</Link>
          </li>
          <li className="py-2 hover:text-secondary">
            <Link to="/esal">ESAL Calculator</Link>
          </li>
          <li className="py-2 hover:text-secondary">
            <Link to="/widening-of-curve">Widening of Curve</Link>
          </li>
          <li className="py-2 hover:text-secondary">
            <Link to="/super-elevation-run-off">Superelevation Runoff</Link>
          </li>
          {/* <li className="py-2 hover:text-secondary">Support</li> */}
        </ul>
      </div>
    </div>
  );
}

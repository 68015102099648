export const subgrade = [

    {
        "A": "03-71-a",
        "B": "subgrade preparation in earth cut ; mod. Aashto 95%",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-71-b",
        "B": "subgrade preparation in rock cut ; mod. Aashto 95%",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-71-c-i",
        "B": "subgrade preparation on existing road ; mod. Aashto 95%",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-71-c-ii",
        "B": "subgrade preparation in existing road without any fill ; mod. Aashto 95%",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    }


]
import React, { useEffect, useState } from "react";
import Input from "../Input";
import CustomTableAndHeader from "../CustomTableAndHeaders";
import { Road_Module_Section_Headers } from "../../helpers";
import { FaIcons } from "../Icons";
import Button from "../Button";
import Heading from "../Heading";
import SelectorWithValues from "../SelectorWithValues";
import { useDispatch, useSelector } from "react-redux";
import { round } from "../../helpers";
import { bill1_estimation } from "../../helpers/road/bill1_estimation";
import { bill2_estimation } from "../../helpers/road/bill2_estimation";
import { bill3_estimation } from "../../helpers/road/bill3_estimation";
import { bill5_estimation } from "../../helpers/road/bill5_estimation";
import { bill6_estimation } from "../../helpers/road/bill6_estimation";
import { bill7_estimation } from "../../helpers/road/bill7_estimation";

import { clearing_and_grubbing } from "../../helpers/road/clearing_and_grubbing";
import { compaction_ng } from "../../helpers/road/compaction_ng";
import { cut } from "../../helpers/road/cut";
import { fill } from "../../helpers/road/fill";
import { subgrade } from "../../helpers/road/subgrade";
import { useParams } from "react-router-dom";
import RoadStepAction from "./RoadStepAction";

const binValues = [
  "50000",
  "150000",
  "500000",
  "2000000",
  "7000000",
  "10000000",
];
const totalAcPrice = 18691.45;
const subBaseMaterialOptions = [
  "16-04-a: Granular Sub Base Course using Pit Run Gravel",
  "16-04-b: Granular Sub Base Using Crushed Stone Aggregate",
];

const RoadModuleSection = () => {

  let { projectId, segmentId } = useParams();

  const [isSideFormOpen, setIsSideFormOpen] = useState(false);

  const [base, setBase] = useState({
    name: null,
    value: null,
  });

  const [subBase, setSubBase] = useState({
    name: null,
    value: null,
  });

  const [thicknessSN, setThicknessSN] = useState({
    acSn: 0,
    abSn: 0,
    asbSn: 0,
    totalSn: 0,
    totalCostSN: 0,
  });

  const dispatch = useDispatch();

  const moduleSelector = useSelector((state) => state.road?.roadModule);
  const mrsPrices = useSelector((state) => state.road?.mrs);
  const roadParameters = useSelector((state) => state.road?.parameters);
  const thicknessValues = useSelector((state) => state.road?.thicknessSection);
  const modelData = useSelector((state) => state.road?.model);
  const binIndexes = useSelector((state) => state.road.binIndexes);
  const roadMaterial = useSelector((state) => state.road.roadMaterial);
  const cutObject = useSelector((state) => state.road?.cut);
  const compactionNgObj = useSelector((state) => state.road?.compaction_ng);
  const fillObj = useSelector((state) => state.road?.fill);
  const subgradeObj = useSelector((state) => state.road?.subgrade);
  const model = useSelector((state) => state.road.model);

  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );

  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );



  const filterObj = {
    binIndexes: "binIndexes",
    roadMaterial: "roadMaterial",
    thicknessSection: "thicknessSection",
    drawingInputValues: "drawingInputValues",
    clearing_and_grubbing: "clearing_and_grubbing",
    roadFormInputs: "roadFormInputs",
    compaction_ng: "compaction_ng",
    cut: "cut",
    fill: "fill",
    subgrade: "subgrade",

  };





  // const estimatesData = [{}];
  useEffect(() => {
    if (segmentId) {
      initForm();
    }
  }, [segmentId, projectData]);

  useEffect(() => {
    getMrsCodes();
  }, []);


  useEffect(() => {
    calcDesignOutput();
  }, [base, subBase, roadParameters]);

  useEffect(() => {
    setSubBase({
      name: `${roadMaterial?.subBaseMaterial}_text`,
      value: roadMaterial?.subBaseMaterial,
    });
    setBase({
      name: `${roadMaterial?.baseMaterial}_text`,
      value: roadMaterial?.baseMaterial,
    });
    dispatch({
      type: "road/updateParameters",
      payload: { name: "R", value: roadParameters.R },
    });
  }, [modelData, binIndexes, roadMaterial]);

  useEffect(() => {
    dispatch({ type: "road/initModel" });

    dispatch({
      type: "road/getMrs",
      payload: {
        url: "/mrs/search",
        year: `${projectData?.mrs_year}`,
        itemCode: [
          "16-04-a",
          "16-04-b",
          "16-05-a",
          "16-05-b",
          "03-62",
          "03-65",
        ],
      },
    });

    const bill1A = transformArrayToObject(bill1_estimation.filter((item) => item.Section === "A"));
    const bill1B = transformArrayToObject(bill1_estimation.filter((item) => item.Section === "B"));
    const bill1C = transformArrayToObject(bill1_estimation.filter((item) => item.Section === "C"));
    const bill1D = transformArrayToObject(bill1_estimation.filter((item) => item.Section === "D"));
    const bill2 = transformArrayToObject(bill2_estimation);
    const bill3 = transformArrayToObject(bill3_estimation);
    const bill5 = transformArrayToObject(bill5_estimation);
    const bill6 = transformArrayToObject(bill6_estimation);
    const bill7 = transformArrayToObject(bill7_estimation);



    const clearingAndGrubbing = transformArrayToObject(clearing_and_grubbing);
    const compactionNg = segmentId ? compactionNgObj : transformArrayToObject(compaction_ng);
    const Cut = segmentId ? cutObject : transformArrayToObject(cut);
    const Fill = segmentId ? fillObj : transformArrayToObject(fill);
    const subGrade = segmentId ? subgradeObj : transformArrayToObject(subgrade);

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill1_estimates_A", billData: bill1A },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill1_estimates_B", billData: bill1B },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill1_estimates_C", billData: bill1C },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill1_estimates_D", billData: bill1D },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill2_estimates", billData: bill2 },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill3_estimates", billData: bill3 },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill5_estimates", billData: bill5 },
    });
    // dispatch({ type: "road/deleteBillEstimation", payload: { billName: "bill5_estimates" } });


    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill6_estimates", billData: bill6 },
    });
    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "bill7_estimates", billData: bill7 },
    });
    // }

    dispatch({
      type: "road/setBillEstimates",
      payload: {
        billName: "clearing_and_grubbing",
        billData: clearingAndGrubbing,
      },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "compaction_ng", billData: compactionNg },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "cut", billData: Cut },
    });

    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "fill", billData: Fill },
    });
    dispatch({
      type: "road/setBillEstimates",
      payload: { billName: "subgrade", billData: subGrade },
    });

    // console.log(bill1_estimation);

    setThicknessSN({
      acSn: 0,
      abSn: 0,
      asbSn: 0,
      totalSn: 0,
      totalCostSN: 0,
    });
  }, []);

  const initForm = () => {
    const formInputs = {};
    currentEditedSegment?.inputs?.map((input) => {
      if (filterObj[input?.input_name]) {
        formInputs[input?.input_name] = JSON.parse(input?.input_value);
      }
    });



    dispatch({
      type: "road/setEditInitFormInputs",
      payload: { ...formInputs },
    });

    const targetEsalValues = {
      name: "esalBin",
      value: formInputs.binIndexes.esalBin,

    };


    hanldeOnESALBinChange(targetEsalValues);

  };

  const getMrsCodes = () => {
    //get all attribute values of 'A' from bill1_estimation
    const bill1attributes = bill1_estimation
      .filter((item) => !item.A.includes("Sub") && !item.A.includes("code"))
      .map((item) => item.A);
    const bill2attributes = bill2_estimation
      .filter((item) => !item.A.includes("Sub") && !item.A.includes("code"))
      .map((item) => item.A);
    const bill3attributes = bill3_estimation
      .filter((item) => !item.A.includes("Sub") && !item.A.includes("code"))
      .map((item) => item.A);
    const bill5attributes = bill5_estimation
      .filter((item) => !item.A.includes("Sub") && !item.A.includes("code"))
      .map((item) => item.A);
    const bill6attributes = bill6_estimation
      .filter((item) => !item.A.includes("Sub") && !item.A.includes("code"))
      .map((item) => item.A);


    const allBills = [
      ...bill1attributes,
      ...bill2attributes,
      ...bill3attributes,
      ...bill5attributes,
      ...bill6attributes,
    ];

    dispatch({
      type: "road/getMrs",
      payload: {
        url: "/mrs/search",
        year: `${projectData?.mrs_year}`,
        itemCode: allBills,
      },
    });


  };



  const transformArrayToObject = (array) => {
    const transformedObject = {};

    array.forEach((item) => {
      const key = item.A;
      if (key) {
        transformedObject[key] = item;
        // delete transformedObject[key].inputFields;
      }
    });

    return transformedObject;
  };



  const handleSideForm = () => {
    setIsSideFormOpen(!isSideFormOpen);
  };

  const handleThicknessChange = (selectedItem) => {
    const { name, value } = selectedItem;
    dispatch({
      type: "road/updateThickness",
      payload: {
        name,
        value,
      },
    });
    calcDesignOutput(name, parseFloat(value));
  };

  const hanldeOnESALBinChange = (selectedItem) => {
    const { name, value } = selectedItem;
    dispatch({
      type: "road/modelChanged",
      payload: {
        name,
        value,
        url: "/site/identify-road",
      },
    });
    calcDesignOutput(name, value);
  };

  const handleRoadMaterial = (fname, { name, value }) => {
    if (fname === "subBaseMaterial") setSubBase({ name, value });
    else setBase({ name, value });
    dispatch({
      type: "road/roadMaterialChanged",
      payload: {
        name: fname,
        value,
        text: name,
      },
    });
  };

  // //Road Module Calculate Total SN,Cost
  // const calcDesignOutput = (name = null, value = null) => {
  //   const binCeiling = model.getBinEsalCeiling(modelData.selectedEsalBin);
  //   const thickness = model.thickness(binCeiling);

  //   //inches thickness
  //   let acThickness = thickness.ac;
  //   let abThickness = thickness.base;
  //   let asbThickness = thicknessValues.asb;
  //   if (name === "ac") {
  //     console.log("🚀 ~ calcDesignOutput ~ name:", name, value);
  //     acThickness = value;
  //   }

  //   if (name === "ab") {
  //     abThickness = value;
  //   }

  //   if (name === "asb") {
  //     asbThickness = value;
  //   }

  //   //meter thickness
  //   const actm = round(parseFloat(acThickness) * 0.0254, 3);
  //   const abtm = round(parseFloat(abThickness) * 0.0254, 3);
  //   const asbtm = round(parseFloat(asbThickness) * 0.0254, 3);

  //   // debugger;
  //   const w1 = parseFloat(3.7);
  //   const w2 = parseFloat(w1 + parseFloat(actm)).toFixed(3);
  //   const w3 = parseFloat(parseFloat(w2) + parseFloat(abtm)).toFixed(3);
  //   const w4 = parseFloat(parseFloat(w3) + parseFloat(asbtm * 2));

  //   //volume
  //   const actv = round(
  //     parseFloat((parseFloat(w1) + parseFloat(w2)) / 2) * parseFloat(actm),
  //     4
  //   );
  //   const abtv = round(
  //     parseFloat((parseFloat(w2) + parseFloat(w3)) / 2) * parseFloat(abtm),
  //     3
  //   );
  //   const asbtv = round(
  //     parseFloat((parseFloat(w3) + parseFloat(w4)) / 2) * parseFloat(asbtm),
  //     3
  //   );

  //   //price
  //   const actp = totalAcPrice;
  //   if (base.value != null && subBase.value != null) {
  //     const abtp = parseFloat(base.value);
  //     const asbtp = parseFloat(subBase.value);
  //     const actppkm = parseFloat(actp * actv * 1000);
  //     const abtppkm = parseFloat(abtp * abtv * 1000);
  //     const asbtppkm = parseFloat(asbtp * asbtv * 1000);

  //     //sn
  //     // debugger;
  //     const dma1 = parseFloat(roadParameters.dma1);
  //     const dma2 = parseFloat(roadParameters.dma2);
  //     const dma3 = parseFloat(roadParameters.dma3);

  //     const actsn = round(dma1 * parseFloat(acThickness), 2);
  //     const abtsn = round(dma2 * parseFloat(abThickness), 2);
  //     const asbtsn = round(dma3 * parseFloat(asbThickness), 2);
  //     console.log("🚀 ~ 238 calcDesignOutput ~ acThickness:", acThickness);

  //     const dmsn = parseFloat(1.12);
  //     const providedSn = round(
  //       parseFloat(actsn) + parseFloat(abtsn) + parseFloat(asbtsn),
  //       2
  //     );

  //     //cost
  //     const totalCost =
  //       round(parseFloat(actppkm)) +
  //       round(parseFloat(abtppkm)) +
  //       round(parseFloat(asbtppkm));
  //     if (!isNaN(providedSn) && !isNaN(totalCost)) {
  //       setThicknessSN({
  //         acSn: parseFloat(actsn),
  //         abSn: parseFloat(abtsn),
  //         asbSn: parseFloat(asbtsn),
  //         totalSn: parseFloat(providedSn),
  //         totalCostSN: round(totalCost),
  //       });
  //     }
  //   }
  // };

  const calcDesignOutput = (name = null, value = null) => {
    const binCeiling = model.getBinEsalCeiling(modelData.selectedEsalBin);
    const thickness = model.thickness(binCeiling);
    let thicknessAsb = thicknessValues.asb;
    // Adjust thickness values if specified
    if (name === "ac") {
      thickness.ac = value;
    } if (name === "ab") {
      thickness.base = value;
    } if (name === "asb") {
      thicknessAsb = value;
    }

    // Convert thickness to meters
    const acThicknessM = thickness.ac * 0.0254;
    const abThicknessM = thickness.base * 0.0254;
    const asbThicknessM = thicknessAsb * 0.0254;

    // Calculate widths
    const w1 = 3.7;
    const w2 = w1 + acThicknessM;
    const w3 = w2 + abThicknessM;
    const w4 = w3 + (asbThicknessM * 2);

    // Calculate volumes
    const actv = ((w1 + w2) / 2) * acThicknessM;
    const abtv = ((w2 + w3) / 2) * abThicknessM;
    const asbtv = ((w3 + w4) / 2) * asbThicknessM;

    // Calculate prices
    const actp = totalAcPrice;
    const abtp = parseFloat(base.value);
    const asbtp = parseFloat(subBase.value);
    const actppkm = actp * actv * 1000;
    const abtppkm = abtp * abtv * 1000;
    const asbtppkm = asbtp * asbtv * 1000;

    // Calculate SN
    const dma1 = parseFloat(roadParameters.dma1);
    const dma2 = parseFloat(roadParameters.dma2);
    const dma3 = parseFloat(roadParameters.dma3);
    const actsn = round(dma1 * thickness.ac, 2);
    const abtsn = round(dma2 * thickness.base, 2);
    const asbtsn = round(dma3 * thicknessAsb, 2);
    const providedSn = isNaN(round(actsn + abtsn + asbtsn, 2)) ? 0 : round(actsn + abtsn + asbtsn, 2);

    // Calculate total cost
    const totalCost = isNaN(round(actppkm + abtppkm + asbtppkm)) ? 0 : round(actppkm + abtppkm + asbtppkm);

    // Update state with calculated values
    if (!isNaN(providedSn) || !isNaN(totalCost)) {
      setThicknessSN({
        acSn: parseFloat(actsn),
        abSn: parseFloat(abtsn),
        asbSn: parseFloat(asbtsn),
        totalSn: parseFloat(providedSn),
        totalCostSN: round(totalCost),
      });
    }
  };


  const rightArrow = "FaAngleDoubleRight";
  const leftArrow = "FaAngleDoubleLeft";

  return (
    <div>
      <div className="flex flex-row">
        <div className="bg-primary  text-white  text-xl rounded-l-lg cursor-pointer font-normal fixed right-1 px-1 ">
          {isSideFormOpen ? (
            <div className="py-10">
              <FaIcons iconName={rightArrow} />
              <SideForm onClose={handleSideForm} />
            </div>
          ) : (
            <div onClick={handleSideForm} className="py-10">
              <FaIcons iconName={leftArrow} />
            </div>
          )}
        </div>
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins text-base">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                Subgrade Elastic Modulus- (MR) (psi)
              </label>
              <Input
                type="text"
                name="subgradePsi"
                className="text-base disabled:bg-red-100"
                placeholder="MR psi"
                disabled={binIndexes?.subgradeCbr ? true : false}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.subgradePsi : null}
              // label={"Choose Subgrade Psi"}
              // options={["1500", "6000", "10500", "15000", "20558", "29618", "39102"]}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">Subgrade CBR (%)</label>
              <Input
                type="text"
                name="subgradeCbr"
                className="text-lg  disabled:bg-red-100"
                disabled={binIndexes?.subgradePsi ? true : false}
                // label={"Choose Subgrade %"}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.subgradeCbr : null}
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                SubBase Elastic Modulus- (MR) (psi)
              </label>

              <Input
                type="text"
                name="subbasePsi"
                required
                className="text-base disabled:bg-red-100"
                disabled={binIndexes?.subbaseCbr ? true : false}
                placeholder="MR psi"
                // options={["15000", "16000", "17000", "18000", "19000", "20000", "21000"]}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.subbasePsi : null}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">Sub Base CBR (%)</label>
              <Input
                type="text"
                name="subbaseCbr"
                required
                className="text-lg bg-white disabled:bg-red-100"
                placeholder="sub Base CBR"
                disabled={binIndexes?.subbasePsi ? true : false}
                // options={["30", "35", "40", "50", "70", "85", "100"]}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.subbaseCbr : null}
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                Base Course Modulus- (MR) (psi)
              </label>

              <Input
                type="text"
                name="basePsi"
                required
                className="text-base disabled:bg-red-100"
                placeholder="MR psi"
                disabled={binIndexes?.baseCbr ? true : false}
                // options={["28500", "29250", "30000"]}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.basePsi : null}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                Base Course CBR (%)
              </label>
              <Input
                type="text"
                name="baseCbr"
                required
                className="text-lg bg-white disabled:bg-red-100"
                placeholder="Base Course CBR"
                label={"Choose Base Course CBR"}
                disabled={binIndexes?.basePsi ? true : false}
                // options={["80", "90", "100", "101"]}
                onChange={(e) => {
                  hanldeOnESALBinChange(e.target);
                }}
                value={binIndexes ? binIndexes.baseCbr : null}
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                Modular Design EASL Bin
              </label>
              <SelectorWithValues
                name="esalBin"
                required
                className="text-base"
                label={"Choose Modular Design ESALs Bin"}
                onChange={(e) => {
                  const targetValues = {
                    name: [e.name],
                    value: binValues[e.selectedIndex - 1],
                  };
                  hanldeOnESALBinChange(targetValues);
                }}
                options={[
                  "Bin # 1 (Upto 0.05 Million ESALs)",
                  "Bin # 2 (0.051-0.15 Million ESALs)",
                  "Bin # 3 (0.151-0.50 Million ESALs)",
                  "Bin # 4 (0.501-2.00 Million ESALs)",
                  "Bin # 5 (2.001-7.00 Million ESALs)",
                  "Bin # 6 (7.001-10.00 Million ESALs)",
                ]}
                values={binValues}
                value={binIndexes ? binIndexes.esalBin : null}
              />
            </div>
          </div>
          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">Sub-Base Material</label>
              <SelectorWithValues
                required
                name="subBaseMaterial"
                className="text-lg bg-white"
                placeholder="Sub-Base Course Material"
                options={subBaseMaterialOptions}
                values={[mrsPrices["16-04-a"], mrsPrices["16-04-b"]]}
                onChange={(e) => {
                  const targetData = {
                    name: e.options[e.options.selectedIndex].textContent,
                    value: e.value,
                  };
                  handleRoadMaterial("subBaseMaterial", targetData);
                }}
                value={roadMaterial ? roadMaterial.subBaseMaterial : null}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-gray-400 text-md">
                Base Course Material
              </label>
              <SelectorWithValues
                type="text"
                name="baseCourseMaterial"
                required
                className="text-lg bg-white"
                placeholder="Base Course Material"
                options={[
                  "16-05-a: Aggregate Base",
                  "16-05-b: Water Bound Macadam",
                ]}
                values={[mrsPrices["16-05-a"], mrsPrices["16-05-b"]]}
                onChange={(e) => {
                  const targetData = {
                    name: e.options[e.options.selectedIndex].textContent,
                    value: e.value,
                  };
                  handleRoadMaterial("baseMaterial", targetData);
                }}
                value={roadMaterial ? roadMaterial.baseMaterial : null}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 p-6">
        <Heading item={"Thickness Section"} />
        <div className="flex flex-col">
          <CustomTableAndHeader
            headers={Road_Module_Section_Headers}
            className={"bg-primary text-start text-white rounded-t-lg"}
            ThStyle={"border-none  p-4"}
          >
            <tr className=" bg-white text-black border-b-2 border-gray-200 font-roboto">
              <td className={"p-4 w-fit"}>AC</td>
              <td className={"p-4 w-fit"}>
                <Input
                  type="number"
                  name="ac"
                  min={0}
                  required
                  className="text-sm p-3 w-full bg-green-100 text-primary  border-gray-200 border"
                  placeholder="Enter Thickness"
                  onChange={(e) => {
                    handleThicknessChange(e.target);
                  }}
                  value={thicknessValues ? (isNaN(thicknessValues.ac) ? 0 : thicknessValues.ac) : 0}
                />
              </td>
              <td className={"p-4 w-fit"}>{totalAcPrice}</td>
              <td className={"p-4 w-fit"}>
                {thicknessSN.acSn ? (isNaN(thicknessSN.acSn) ? 0 : thicknessSN.acSn) : 0}
              </td>
              <td className={"p-4 w-fit  text-center"} rowSpan={4}>
                {roadParameters.sn}
              </td>
            </tr>

            <tr className=" bg-white text-black border-b-2 border-gray-200">
              <td className={"p-4 w-fit"}>
                {roadMaterial["subBaseMaterial_text"]
                  ? roadMaterial["subBaseMaterial_text"]
                  : "Base Material"}
              </td>
              <td className={"p-4 w-fit"}>
                <Input
                  type="number"
                  min={0}
                  name="ab"
                  required
                  className="text-sm p-3 w-full bg-green-100 text-primary  border-gray-200 border"
                  placeholder="Enter Thickness"
                  onChange={(e) => {
                    handleThicknessChange(e.target);
                  }}
                  value={thicknessValues ? (isNaN(thicknessValues.ab) ? 0 : thicknessValues.ab) : 0}
                />
              </td>
              <td className={"p-4 w-fit"}>{base.value ? base.value : 0}</td>
              <td className={"p-4 w-fit"}>
                {thicknessSN.abSn ? isNaN(thicknessSN.abSn) ? 0 : thicknessSN.abSn : 0}
              </td>
            </tr>

            <tr className=" bg-white text-black border-b-2 border-gray-200">
              <td className={"p-4 w-fit"}>
                {roadMaterial["baseMaterial_text"]
                  ? roadMaterial["baseMaterial_text"]
                  : "Sub Base Material"}
              </td>
              <td className={"p-4 w-fit"}>
                <Input
                  type="number"
                  name="asb"
                  min={0}
                  required
                  className="text-sm p-3 w-full bg-green-100 text-primary  border-gray-200 border"
                  placeholder="Enter Thickness"
                  onChange={(e) => {
                    handleThicknessChange(e.target);
                  }}
                  value={thicknessValues ? (isNaN(thicknessValues.asb) ? 0 : thicknessValues.asb) : 0}
                />
              </td>
              <td className={"p-4 w-fit"}>
                {subBase.value ? subBase.value : 0}
              </td>
              <td className={"p-4 w-fit "}>
                {thicknessSN.asbSn ? (isNaN(thicknessSN.asbSn) ? 0 : thicknessSN.asbSn) : 0}
              </td>
            </tr>

            <tr className=" bg-white text-black border-b-2 border-gray-200">
              <td className={"p-4 w-fit"}>Total Per Km Cost and Provided Sn</td>
              <td className={"p-4 w-fit"}>
                {/* <Input
                                    type="text"
                                    name="totalCost"
                                    required
                                    className="text-sm p-3 w-full bg-green-100 text-primary  border-gray-200 border"
                                    placeholder="Enter Thickness"
                                /> */}
              </td>
              <td className={`p-4 w-fit `}>{thicknessSN.totalCostSN}</td>
              <td className={`p-4 w-fit ${thicknessSN.totalCostSN >= roadParameters.sn ? 'bg-green-500' : 'bg-red-500'} `}>{thicknessSN.totalSn}</td>
            </tr>
          </CustomTableAndHeader>
        </div>
      </div>
      <div className="text-gray-400">
        <p className="px-2 text-center text-sm">
          {moduleSelector.nomenclature}
        </p>
      </div>
      <div className="mr-4 mb-4">
        <RoadStepAction projectId={projectId} />
      </div>
    </div>
  );
};

export default RoadModuleSection;

const SideForm = ({ onClose }) => {
  const dispatch = useDispatch();
  const roadParameters = useSelector((state) => state.road?.parameters);

  const handleChangeParameters = (selectedItem) => {
    const { name, value } = selectedItem;
    dispatch({
      type: "road/updateParameters",
      payload: {
        name,
        value,
      },
    });
  };

  const resetToDefault = (e) => {
    e.preventDefault();
    dispatch({ type: "road/resetParameters" });
  };

  return (
    <div className="modal flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none cursor-default">
      <div className="modal-overlay fixed inset-0 bg-green-100 opacity-50"></div>

      <div className="modal-content bg-white px-6 text-black w-4/6 h-fit rounded-lg shadow-lg relative z-10 left-20 border border-gray-300">
        <div className="flex flex-col gap-2">
          <div className="flex flex-row justify-end py-2">
            <Button
              label={"X"}
              onClick={onClose}
              hoverBg="hover:bg-red-600"
              className={" bg-red-500  hover:text-white text-xl rounded-full"}
            />
          </div>

          <p className=" text-base text-red-400 font-saira">
            These values have been fixed as per given standards, however if you
            are confident and thoroughly reviewed ASHTO standards. Then you can
            change these values at your own risk<sup>*</sup>
          </p>

          <form>
            <div className="flex flex-col gap-8 py-2 flex-grow font-poppins ">
              <div className="flex flex-row gap-12 ">
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Realiability R,(%)
                  </label>
                  <Input
                    type="text"
                    name="R"
                    required
                    className="text-base"
                    placeholder="Realiability R,%"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.R : null}
                  />
                </div>

                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Overall Std Deviation(So),-
                  </label>

                  <Input
                    type="text"
                    name="So"
                    required
                    className=" text-base"
                    placeholder="overall Std Deviation"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.So : null}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-12">
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Initial Serviceability Index(Pi),-
                  </label>
                  <Input
                    type="text"
                    name="Pi"
                    required
                    className="text-base"
                    placeholder="Initial Serviceability Index"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.Pi : null}
                  />
                </div>
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Final Serviceability Index(Pt),-
                  </label>
                  <Input
                    type="text"
                    name="Pt"
                    required
                    className="text-base bg-white"
                    placeholder="Final Serviceability Index(Pt)"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.Pt : null}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-12">
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Elastic Modulus of AC(Eac),psi
                  </label>
                  <Input
                    type="text"
                    name="Eac"
                    required
                    className="text-base bg-white"
                    placeholder="Elastic Modulus of AC"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.Eac : null}
                  />
                </div>
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Drainage Coeff, For AC Course(m1),-
                  </label>
                  <Input
                    type="text"
                    name="m1"
                    required
                    className="text-base bg-white"
                    placeholder="Drainage Coeff for AC"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.m1 : null}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-12">
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Drainage Coeff, For Base Course(m2),-
                  </label>
                  <Input
                    type="text"
                    name="m2"
                    required
                    className="text-base"
                    placeholder="Drainage Coeff for Base"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.m2 : null}
                  />
                </div>
                <div className="flex flex-col flex-1 gap-1">
                  <label className="text-gray-400 text-lg">
                    Drainage Coeff, For Subbase Course(m3),-
                  </label>
                  <Input
                    type="text"
                    name="m3"
                    required
                    className="text-base"
                    placeholder="Drainage Coeff for Subbase"
                    onChange={(e) => {
                      handleChangeParameters(e.target);
                    }}
                    value={roadParameters ? roadParameters.m3 : null}
                  />
                </div>
              </div>
              <div className="flex flex-row justify-end flex-1 items-center gap-5">
                {/* <button className="text-primary underline-offset-2 underline decoration-4 text-xl p-2 hover:text-cyan-700 font-poppins">Reset to Default</button> */}
                <Button
                  label={"Reset to Default"}
                  className={
                    "w-fit bg-white  underline underline-offset-2 text-xl decoration-4  "
                  }
                  textColor={"text-primary "}
                  onClick={(e) => {
                    resetToDefault(e);
                  }}
                />
                <Button onClick={onClose} label={"Save Values"} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

import React from "react";

const Modal = ({ onClose, children }) => {
  return (
    <div className="modal flex items-center justify-center fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="modal-overlay fixed inset-0 bg-green-100 opacity-50"></div>

      <div className="modal-content bg-white w-1/3 max-h-[60vh] rounded-lg shadow-lg relative z-10 px-4 py-4 overflow-auto">
        {children}
      </div>
    </div>
  );
};

export default Modal;

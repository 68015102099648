import React from 'react'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const RoadStepAction = ({ disabled = false, projectId }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { activeStep, totalSteps } = useSelector((state) => state.misc);

    const changeStep = (type) => {
        if (disabled && type === 'next') return;
        if (type === 'next') {
            dispatch({ type: "misc/setFormStep", payload: { value: activeStep + 1 } });
        } else {
            dispatch({ type: "misc/setFormStep", payload: { value: activeStep - 1 } });
        }
    }

    return (
        <div className="flex flex-row justify-end items-center">

            <Button onClick={() => { navigate(`/projects/${projectId}`) }} label={"Cancel"} className={'mr-2 bg-red-500 '} hoverBg='hover:bg-red-700' />

            {activeStep !== 0 && <Button onClick={() => changeStep('prev')} label={"Back"} className={'mr-2'} />}

            {activeStep !== totalSteps - 1 && <>
                <Button onClick={() => changeStep('next')} disabled={disabled === true ? "disabled" : false} className={disabled ? "disabled:opacity-25" : null} label={"Proceed"} />
            </>}
        </div>
    )
}

export default RoadStepAction
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addProject, updateProject } from "../store/actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackendLayout from "../components/BackendLayout";
import Selector from "../components/Selector";
import { Cities, MRS_Years } from "../helpers";
import Input from "../components/Input";
import Breadcrumb from "../components/Breadcumb";
import Button from "../components/Button";
import Label from "../components/Label";
import { CitiesListOfKPK } from "../helpers";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

// const api_url = "http://modular-api.local:8080/projects";
// const apiUrl = process.env.REACT_APP_API_URL;

const AddProjectForm = () => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.user);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const created = useSelector((state) => state.project.created);

  const breadcrumbItems = [
    { itemName: "Home", itemLink: "/projects" },
    { itemName: `${projectId ? "Edit-Project" : "Add-Project"}`, itemLink: "" },
  ];

  const editProjectData = useSelector((state) =>
    state.project?.projects?.find((p) => p?.id === parseInt(projectId, 10))
  );

  const [projectData, setProjectData] = useState({
    name: "",
    location: "",
    psdp_or_apd_no: "",
    road_length: "",
    mrs_year: "",
  });

  useEffect(() => {
    if (projectId) {
      setProjectData({
        name: editProjectData.name,
        location: editProjectData.location,
        psdp_or_apd_no: editProjectData.psdp_or_apd_no,
        road_length: editProjectData.road_length,
        mrs_year: editProjectData.mrs_year.toString(),
      });
    }
  }, []);

  useEffect(() => {
    if (created) {
      Swal.close();
      handleProjectNext();
    }
    Swal.close();
  }, [created]);

  const handleProjectNext = () => {
    navigate("/projects");
  };

  const handleAddProject = async (e) => {
    Swal.fire({
      title: "Create a New Project",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    e.preventDefault();
    if (projectData.name.trim() !== "") {
      dispatch({
        type: "project/addProject",
        payload: { url: "/projects", projectData },
      });
    }
  };

  const handleUpdateProject = async (e) => {
    Swal.fire({
      title: "Updating Project",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    e.preventDefault();
    if (projectData.name.trim() !== "") {
      dispatch({
        type: "project/updateProject",
        payload: { url: `/projects/${projectId}`, projectData },
      });
    }
  };
  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb items={breadcrumbItems} />
      <form
        onSubmit={(e) =>
          projectId ? handleUpdateProject(e) : handleAddProject(e)
        }
      >
        <div className="p-4">
          <div className="flex flex-col gap-8 p-5 font-poppins">
            <div className="flex flex-row gap-5">
              <div className="flex flex-col flex-1 gap-1">
                <Label>Name</Label>
                <Input
                  type="text"
                  name="project_name"
                  required
                  className="text-base "
                  placeholder="Project Name"
                  value={projectData.name}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) =>
                    setProjectData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col flex-1 gap-1">
                <Label>Location</Label>
                <Select
                  className="border-gray-300 border-b-2 text-primary font-poppins"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                    }),
                  }}
                  required={true}
                  classNamePrefix="select"
                  placeholder="Select Location"
                  name="location"
                  isSearchable={true}
                  options={CitiesListOfKPK}
                  value={CitiesListOfKPK.find(
                    (city) => city.value === projectData.location
                  )}
                  onChange={(selectedValue) => {
                    setProjectData((prevData) => ({
                      ...prevData,
                      location: selectedValue.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex flex-row gap-5">
              <div className="flex flex-col flex-1 gap-1">
                <Label>PSDP/ADP No:</Label>
                <Input
                  type="text"
                  name="psdp_adp_no"
                  // required
                  className="text-base"
                  placeholder="PSDP/ADP Number"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  value={projectData.psdp_or_apd_no}
                  onChange={(e) =>
                    setProjectData((prevData) => ({
                      ...prevData,
                      psdp_or_apd_no: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col flex-1 gap-1">
                <Label>Total Length of Road (Kms):</Label>
                <Input
                  type="text"
                  name="road_length"
                  required
                  className="text-base"
                  placeholder="Total length of Road (Kms)"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  value={projectData.road_length}
                  onChange={(e) =>
                    setProjectData((prevData) => ({
                      ...prevData,
                      road_length: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {projectId ? <div className="flex flex-row gap-5"></div> : <div className="flex flex-row gap-5">
              <div className="flex flex-col flex-1 gap-1">
                <Label>MRS Year</Label>
                <Select
                  className="border-gray-300 border-b-2 text-primary font-poppins"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                    }),
                  }}
                  required={true}
                  classNamePrefix="select"
                  placeholder="Choose MRS Year"
                  name="mrs_year"
                  isSearchable={true}
                  options={MRS_Years}

                  value={MRS_Years.find(
                    (year) => year.value === projectData?.mrs_year
                  )}

                  onChange={(e) => {
                    setProjectData((prevData) => ({
                      ...prevData,
                      mrs_year: e.value,
                    }));
                  }}
                />
              </div>
              <div className="flex flex-col flex-1 gap-1"></div>
            </div>}
          </div>
          <div className="flex flex-row justify-end items-center gap-5 pt-4">
            <Link to={"/projects"} className="w-1/5">
              <Button
                label={"Cancel"}
                className={
                  "bg-red-500 w-full  border-2 text-center "
                }
                textColor={"text-white border-primary"}

                hoverBg="hover:bg-red-600"
              />
            </Link>

            <Button
              label={projectId ? "Update Project" : "Save and Proceed"}
              type="submit"
              className="w-1/5"
            />
          </div>
        </div>
      </form>
    </BackendLayout>
  );
};

export default AddProjectForm;

import React from 'react';
import { Link } from 'react-router-dom';
import arrow_icon from "../assets/info_cards/arrow_icon.svg";

const InfoCard = ({ ImageSource, heading = "Heading", text = "Text", LTR, linkName = "View Details", linkAddress ="/login" }) => {
    return (
        <div className={`flex ${LTR ? "flex-row-reverse" : "flex-row"} gap-5 justify-center px-10 `}>

            <div className={`w-1/2 flex ${LTR ? "justify-start" : "justify-end"} items-center`}>
                <img src={ImageSource} className='w-full h-4/6' alt='project_point' />
            </div>

            <div className='flex flex-col justify-center gap-4  w-1/2'>
                <p className='text-primary text-2xl font-poppins w-fit'>{heading}</p>
                <p className='w-9/12 text-primary font-medium font-roboto my-4 leading-6'>{text}</p>
                <Link className='w-fit text-sm text-secondary font-poppins flex gap-2 hover:ease-in-out duration-300 hover:translate-x-3 delay-150 hover:font-bold' to={linkAddress}>{linkName} <img src={arrow_icon} alt='arrow' className='w-6' /> </Link>
            </div>

        </div>
    )
}

export default InfoCard
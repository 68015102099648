import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { projectLabels } from "../helpers";
import Actions from "./ActionsIcons";
import { deleteProject } from "../store/actions";
import Swal from "sweetalert2";
import ReactPaginate from "react-paginate";
import "./css/customScrollBar.css";
import ListSkeleton from "./ui/ListSkeleton";

const ProjectList = ({ search }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projectsData = useSelector((state) => state?.project?.projects);
  const pagination = useSelector((state) => state?.project?.pagination);
  const [filteredProjects, setFilteredProjects] = React.useState(
    projectsData || []
  );
  const loading = useSelector((state) => state?.project?.loading);

  React.useEffect(() => {
    filterProjects();
    Swal.close();
  }, [search, projectsData, loading]);

  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleDeleteProject = (projectId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#ff000",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your project has been deleted.",
          icon: "success",
          timer: 1000,
          showConfirmButton: false,
        });
        dispatch({
          type: "project/deleteProject",
          payload: { url: `/projects/${projectId}`, projectId },
        });
      }
    });
  };

  const handleEditProject = (projectId) => {
    navigate(`/projects/edit-project/${projectId}`);
  };

  function filterProjects() {
    if (search) {
      const filteredData = projectsData.filter((project) => {
        return project.name.toLowerCase().includes(search.toLowerCase());
      });
      setFilteredProjects(filteredData);
    } else {
      setFilteredProjects(projectsData);
    }
  }

  function handlePageChange(e) {
    Swal.fire({
      title: "Loading Projects",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    dispatch({
      type: "project/fetchProjects",
      payload: {
        url: `/projects`,
      },
      params: {
        page: e.selected + 1,
      },
    });
  }

  return (
    <div className="flex flex-col p-3">
      <table className="min-w-full text-left text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-ligh overflow-auto">
        <thead className="border-b font-medium w-fit overflow-auto">
          <tr className="border p-2 bg-gray-300  font-bold font-saira">
            {projectLabels?.map((column, index) => (
              <th key={index} scope="col" className=" p-4 font-bold select-none">
                {column}
              </th>
            ))}
          </tr>
        </thead>
        {filteredProjects?.length !== 0 && (
          <tbody>
            {filteredProjects?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-b hover:bg-gray-50 cursor-pointer overflow-auto font-roboto transition-all duration-300"
              >
                <td
                  className="p-4 px-6 text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-medium select-none"
                  onClick={() => {
                    handleProjectClick(row?.id);
                  }}
                >
                  {row?.name}
                </td>
                <td
                  className="p-4 px-6 text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-medium select-none"
                  onClick={() => {
                    handleProjectClick(row?.id);
                  }}
                >
                  {row?.mrs_year}
                </td>
                <td
                  className="p-4 px-6 text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-medium select-none"
                  onClick={() => {
                    handleProjectClick(row?.id);
                  }}
                >
                  {row?.location}
                </td>
                <td
                  className="p-4 px-6 text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-medium select-none"
                  onClick={() => {
                    handleProjectClick(row?.id);
                  }}
                >
                  {row?.psdp_or_apd_no}
                </td>
                <td
                  className="p-4 px-6 text-sm desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl font-medium select-none"
                  onClick={() => {
                    handleProjectClick(row?.id);
                  }}
                >
                  {row?.road_length}
                </td>
                <td className="p-4 text-2xl text-gray-400  ">
                  <Actions
                    hideAdd={true}
                    hidePreview={true}
                    OnDelete={() => {
                      handleDeleteProject(row?.id);
                    }}
                    deleteToolTip="Delete Project"
                    onEdit={() => {
                      handleEditProject(row?.id);
                    }}
                    editToolTip="Edit Project"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      {pagination?.currentPage ? (
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageChange}
          pageRangeDisplayed={pagination?.perPage}
          pageCount={Math.ceil(pagination?.pageCount)}
          // forcePage={parseInt(pagination?.currentPage - 1)}
          previousLabel="< Previous"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName={pagination?.currentPage === 1 ? "page-num disabled" : "page-num"}
          nextLinkClassName={pagination?.currentPage === pagination?.pageCount ? "page-num disabled" : "page-num"}
          activeLinkClassName="active"
        />

      ) : null}
      {loading && <ListSkeleton />}
      {filteredProjects?.length == 0 && !loading && (
        <div className="flex flex-row justify-center items-center w-full my-6">
          <p className="font-poppins text-2xl text-primary">
            No Project Found!
          </p>
        </div>
      )}
    </div>
  );
};

export default ProjectList;

export const fill = [

    {
        "A": "03-70-a",
        "B": "Formation of Embankment from Roadway Excavation in Common Material including compaction Modified AASHTO 90% by power roller.",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-b",
        "B": "Formation of Embankment from Roadway Excavation in Rock Material requiring blasting and compaction Modified AASHTO 95% of embankment by power roller.",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-c",
        "B": "Formation of Embankment from Borrow Excavation in Common Material including compaction Modified AASHTO 90% by power roller.",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-d",
        "B": "Formation of Embankment from Roadway Excavation in granular Material including compaction Modified AASHTO 90% by power roller.",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-e",
        "B": "Formation of Embankment from Roadway excavation in common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-f",
        "B": "Formation Of Embankment From Roadway Excavation In Hard Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-g",
        "B": "Formation Of Embankment From Roadway Excavation In Medium Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-h",
        "B": "Formation Of Embankment From Roadway Excavation In Soft Rock Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-i",
        "B": "Formation Of Embankment From Borrow Excavation In Common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-j",
        "B": "Formation Of Embankment From Structural Excavation In Common Material",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    },
    {
        "A": "03-70-k",
        "B": "Formation of Embankment from Roadway excavation in common Material (Hard Rock)",
        "C": " ",
        "D": 0,
        "inputs": ["C"]
    }


]
import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ items }) => {
    const { itemName, itemLink } = items;
    return (
        <div className="flex flex-row items-center p-2">
            <div className="p-2 text-gray-400 text-sm flex-1 font-roboto desktopSm:text-lg desktopMd:text-xl desktopXmd:text-2xl desktopLg:text-3xl  desktopXlg:text-4xl">
                {items.map((item, index) => (
                    <React.Fragment key={index}>
                        <Link to={`${item.itemLink}`} className={`p-1 ${index === items.length - 1 ? 'text-secondary' : ''}`}>{item.itemName}</Link>
                        {index !== items.length - 1 && <span className="p-1 ">|</span>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    );
};

export default Breadcrumb;

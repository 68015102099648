export const bill1_estimation = [

  {
    "A": "03-03-a",
    "B": "Excavate Unsuitable Common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",
    "K": 0
  },
  {
    "A": "03-03-b",
    "B": "Excavate Unsuitable Hard Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-c",
    "B": "Excavate Unsuitable Medium Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-d",
    "B": "Excavate Unsuitable Soft Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-e",
    "B": "Excavate Surplus Common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-f",
    "B": "Excavate Surplus Hard Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-g",
    "B": "Excavate Surplus Medium Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-03-h",
    "B": "Excavate Surplus Soft Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-62",
    "B": "Clearing and Grubbing by mechanical means",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-65",
    "B": "Compaction of Natural Ground",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-66-a",
    "B": "Roadway Excavation in Surplus / Unsuitable Common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",
    "K": 0
  },
  {
    "A": "03-66-b",
    "B": "Roadway Excavation in Surplus / Unsuitable Rock (Hard) Material requiring blasting.",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-66-c",
    "B": "Roadway Excavation in Surplus / Unsuitable Rock (Medium) Material requiring blasting",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-66-d",
    "B": "Roadway Excavation in Surplus / Unsuitable Rock (Soft) Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-a",
    "B": "Formation of Embankment from Roadway Excavation in Common Material includingcompaction Modified AASHTO 90% by powerroller.",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",
    "K": 0,
  },
  {
    "A": "03-70-b",
    "B": "Formation of Embankment from Roadway Excavation in Rock Material requiring blastingand  compaction Modified AASHTO 95% ofembankment by power roller.",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-c",
    "B": "Formation of Embankment from Borrow Excavation in Common Material includingcompaction Modified AASHTO 90% by powerroller.",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-d",
    "B": "Formation of Embankment from Roadway Excavation in granular  Material includingcompaction Modified AASHTO 90% by powerroller.",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-e",
    "B": "Formation of Embankment from Roadway excavation in common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-f",
    "B": "Formation Of Embankment From Roadway Excavation In Hard Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-g",
    "B": "Formation Of Embankment From Roadway Excavation In Medium Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-h",
    "B": "Formation Of Embankment From Roadway Excavation In Soft Rock Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-i",
    "B": "Formation Of Embankment From Borrow Excavation In Common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-j",
    "B": "Formation Of Embankment From Structural Excavation In Common Material",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-70-k",
    "B": "Formation of Embankment from Roadway excavation in common Material (Hard Rock)",
    "C": "No",
    "D": "m cube",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-71-a",
    "B": "Subgrade Preparation In Earth Cut ; Mod. AASHTO 95%",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",
    "K": 0
  },
  {
    "A": "03-71-b",
    "B": "Subgrade Preparation in Rock Cut ; Mod. AASHTO 95%",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  {
    "A": "03-71-c-i",
    "B": "Subgrade Preparation on Existing Road ; Mod. AASHTO 95%",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",


  },
  {
    "A": "03-71-c-ii",
    "B": "Subgrade Preparation in Existing Road Without Any Fill ; Mod. AASHTO 95%",
    "C": "No",
    "D": "m2",
    "E": "-",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "mustInclude": true,
    "included": true,
    "Section": "A",

  },
  // {
  //   "A": "Sub-Total A",
  //   "B": "",
  //   "C": "",
  //   "D": "",
  //   "E": "",
  //   "F": "",
  //   "G": "",
  //   "H": "",
  //   "I": 0,
  //   "J":0,
  //   "K": 0

  // },
  // {
  //   "A": "MRS code",
  //   "B": "Item description",
  //   "C": "Item selection",
  //   "D": "Unit",
  //   "E": "Number",
  //   "F": "Length",
  //   "G": "Width",
  //   "H": "Height",
  //   "I": "Quantity",
  //   "J":0,
  //   "K": "Amount (PKR)"
  // },
  {
    "A": "03-01-a",
    "B": "Earth excavation undressed upto single throw of kassi phaorah or shovel etc : in ashes, sand, soft soil or silt clearance",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B",
  },
  {
    "A": "03-01-b",
    "B": "Earth excavation undressed upto single throw of kassi phaorah or shove etc : in ordinary soil,",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-02-a",
    "B": "Earth excavation in ashes, sand & soft soil or silt clearance, undressed lead upto 15m.",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-02-b",
    "B": "Earth excavation in ashes, sand, shingle & soft soil or silt clearance by mechanical means undressed lead upto 15m.",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-04-a",
    "B": "Bed clearance and dressing slopes of drains including removing of weeds and roots etc. Excavated material undressed within 15m",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-04-b",
    "B": "Bed clearance and dressing slopes of drains including removing of weeds and roots etc. Excavated material dressed within 15m lead",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-05-a",
    "B": "Borrowpit excavation undressed lead upto 30m in Ordinary soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-05-b",
    "B": "Borrowpit excavation undressed lead upto 30m in Hard soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-05-c",
    "B": "Borrowpit excavation undressed lead upto 30m in shingle/gravel formation",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-06-a",
    "B": "Embankment formation in ordinary soil & compaction by mechanical means at optimum moistures content to 95% to 100% max. modified. AASHTO dry density (borrow area).",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-06-b",
    "B": "Embankment formation in ordinary soil & compaction by mechanical means at optimum moistures content to 90% max. modified AASHTO dry density (borrow area).",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-06-c",
    "B": "Embankment formation in ordinary soil & compaction  by mechanical means at optimum moistures content to 85% max. modified AASHTO dry density (borrow area).",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-07-a",
    "B": "Earth fill in lawns including dressing & compaction with earth available from cutting/excavation",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-07-b",
    "B": "Earth fill in lawns including dressing & compaction with suitable earth borrowed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-08",
    "B": "Furnishing And Planting Of Trees Including Maintenace For 2 Years",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "B"
  },
  {
    "A": "03-09-a",
    "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Ordinary Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-09-b",
    "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-09-c",
    "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Very hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-09-d",
    "B": "Earth excavation in open cut upto 1.5m depth for drains, pipes etc & disposal : in Gravel & shingle",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-10-a",
    "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Ordinary Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-10-b",
    "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-10-c",
    "B": "Earth excavation in open cut 1.5m  - 3m depth for Surface Water Drains, pipes etc & disposal : in Very Hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-10-d",
    "B": "Earth excavation in open cut 1.5m - 3m depth for drains, pipes etc & disposal : in Gravel & shingle",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-11-a",
    "B": "Excavation in shingle or gravel formation & rock not requiring blast, undressed, 50m lead : Dry",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-11-b",
    "B": "Excavation in shingle or gravel formation & rock not requiring blast, undressed, 50m lead : Wet",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-11-c",
    "B": "Excavation in shingle or gravel formation & rock not req. blast, undressed, 50m lead : in Flowing Water",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-12-a",
    "B": "Earth excavation in irrigation channels/drains & disposal upto 25m. & dressing : in Ordinary Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-12-b",
    "B": "Earth excavation in irrigation channels/drains & disposal upto 25m. & dressing : in Hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-12-c",
    "B": "Earth excavation in irrigation channels/drains & disposal upto 25m. & dressing : in Very Hard Soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-12-d",
    "B": "Earth excavation in irrigation channels/drains & disposal upto 25m. & dressing : in Shingle/Gravel",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-13-a",
    "B": "Rock Excavation, dressing & disposal up to 50m Soft Rock, slate, shale, schist or lateriate",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-13-b",
    "B": "Rock Excavation, dressing & disposal up to 50m Medium hard rock, requiring occasional blasting",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-a",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade I",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-b",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade II",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-c",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade III",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-d",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade IV",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-e",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade V",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-14-f",
    "B": "Excavation in hard rock requiring blasting and disposal upto 25m & dressing : Grade VI",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-a",
    "B": "Excavation in hard rock requiring blasting but blasting prohibited, dispose & dress within 25 m : Grade I",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-b",
    "B": "Excavation in hard rock requiring blasting where blasting prohibited, dispose & dress within 25 m : Grade II",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-c",
    "B": "Excavation in hard rock requiring blasting where blasting prohibited, dispose & dress within 25 m: Grade III",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-d",
    "B": "Excavation in hard rock requiring blasting where blasting prohibited, dispose & dress within 25 m: Grade IV",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-e",
    "B": "Excavation in hard rock requiring blasting where blasting prohibited, dispose & dress within 25 m: Grade V",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-15-f",
    "B": "Excavation in hard rock requiring blasting where blasting prohibited, dispose & dress within 25 m: Grade VI",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-16-a",
    "B": "Rehandling of earthwork Lead upto a single throw of kassi, shovel, phaorah",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-16-b",
    "B": "Rehandling of earthwork upto a lead of 25 m.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-17",
    "B": "Rehandling of gravel work or excavated rock, lead upto 25m.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-18-a",
    "B": "Filling, watering and ramming earth under floor with surplus earth from foundation, etc",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-18-b",
    "B": "Selected Fill Material under Footings",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-18-c",
    "B": "Provide and Fill River Bed Filter Material For Widening Portion.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-18-d",
    "B": "Filling, watering and ramming earth under floor with earth excavated from outside lead upto 30m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-19-a",
    "B": "Extra for every 15 m extra lead or part thereof for earthwork soft, ordinary, hard & very hard",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-19-b",
    "B": "Extra for every 15 m extra lead or part thereof for gravel, shingle or rock.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-20-a",
    "B": "Transportation of earth all types beyond 250 m and upto 500 m.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-20-b",
    "B": "Transportation of earth all types for every 100m extra lead beyond 500m upto 1.5 km.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-20-c",
    "B": "Transportation of earth all types for every 500m extra lead beyond 1.5 km. upto 8 km.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-20-d",
    "B": "Transportation of earth all types for every 1 km extra lead or part thereof beyond 8 km.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-21-a",
    "B": "Dressing & levelling earth to designed sections Ashes, sand, silt or soft soil upto cut or fill 6 inches",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-21-b",
    "B": "Dressing & levelling earth to designed sections Ordinary or hard soil  upto cut or fill 6 inches",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-21-c",
    "B": "Dressing & levelling earth to designed section Gravel work or soft rock not requiring blasting upto cut or fill 6 inches",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-22",
    "B": "Dowel dressing",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-23",
    "B": "Dressing slopes of banks or ground surface",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-24",
    "B": "Dressing of earthwork (done by machinery or otherwise & left undressed) to designed section",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-25-a",
    "B": "Excavation in foundation of building, bridges etc complete : in sand, ashes or loose soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-25-b",
    "B": "Excavation in foundation of building, bridges etc complete : in ordinary soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-25-c",
    "B": "Excavation in foundation of building, bridges etc complete : in hard soil or soft murum",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-25-d",
    "B": "Excavation in foundation of building, bridges etc complete : in shingle/gravel",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-26",
    "B": "Cutting hard rock such as granite, ballast, hard lime etc with chisels/hammers for small foundation",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-27",
    "B": "Extra for excavaton requiring shoring",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-a",
    "B": "Mixing & moistening of earthwork to OMC in layers for compaction etc complete",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-b-01",
    "B": "Compaction by rolling with animal driven roller or hand rammed : Soft or sandy soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-b-02",
    "B": "Compaction by rolling with animal driven roller or hand rammed : Ordinary soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-b-03",
    "B": "Compaction by rolling with animal driven roller or hand rammed : Hard soil",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-b-04",
    "B": "Compaction by rolling with animal driven roller or hand rammed : Admixture of shingle",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-c",
    "B": "Ramming earthwork (all types of soil)",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-28-d",
    "B": "Ramming earthwork behind retaining wall",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-29-a",
    "B": "Compaction of earth with power road roller 95% to 100% max. mod. AASHTO dry density",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-29-b",
    "B": "Compaction of earth with power road roller 90% max. mod. AASHTO dry density",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-29-c",
    "B": "Compaction of earth with power road roller 85% max. mod. AASHTO dry density",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-30",
    "B": "Extra for wet earthwork",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-31",
    "B": "Extra for slush or daldal including dewatering",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-32",
    "B": "Extra for puddling.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-33",
    "B": "Earthwork on small rain water drains, along canal banks,roads, drains, etc complete",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-34-a",
    "B": "Filling and Compacting Soil, Earth and Boulders behind retaining walls (Available material)",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-34-b",
    "B": "Filling and Compacting Soil, Earth and Boulders behind retaining walls by mechanical means (Available material)",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-35",
    "B": "Supply and spreading stone aggregate size 3/4\"",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-36",
    "B": "P/L Stone soling under foundation including consolidation etc.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-37",
    "B": "Dag belling 75 mm deep.",
    "C": "No",
    "D": "km",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-38",
    "B": "Turfing slopes of banks or lawns with grass sods including ploughing, laying, setting & watering",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-39-a",
    "B": "Berm cutting : Lead upto single throw of phaorah or shovel, without dressing",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-39-b",
    "B": "Berm cutting : Upto 25 m lead (including dressing)",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-40-a",
    "B": "Berm trimming both sides of channels Upto 1 m depth",
    "C": "No",
    "D": "km",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-40-b",
    "B": "Berm trimming both sides of channels Over 1m to 1.5m depth",
    "C": "No",
    "D": "km",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-40-c",
    "B": "Berm trimming both sides of channels Over 1.5m to 2.5m depth",
    "C": "No",
    "D": "km",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-41",
    "B": "Dressing Of Berm Without Extra Material",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-43-a-01",
    "B": "Making boundary or service roads complete in unploughed land : From 3 m to 6 m wide",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-43-a-02",
    "B": "Making boundary or service roads complete in unploughed land : Over 6 m to 12 m wide",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-43-b-01",
    "B": "Making boundary or service roads complete in ploughed land : From 3 m to 6m wide",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-43-b-02",
    "B": "Making boundary or service roads complete in ploughed land : Over 6 m to 12 m wide",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-44-a",
    "B": "Earthwork by boats, Digging and loading into boats, upto 25m lead",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-44-b",
    "B": "Earthwork by boats, including hire of boats Carriage by boats upto 250m.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-44-c",
    "B": "Earthwork by boats, including hiring of boats Extra for every additional 50m beyond 250m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-44-d",
    "B": "Earthwork by boats, Unloading earth from boats",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-45",
    "B": "Unloading earth from BG trucks and clearing 1.5m from rail",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-46",
    "B": "Earthwork by tramway, digging and loading in trucks, upto 25m lead",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-47",
    "B": "Unloading earth from BG trucks and spreading upto 5m from rail",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-48",
    "B": "Supplying clean and screened river or pit sand within 150m including removal of loose earth or overburden.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-49-a",
    "B": "Excavation in open cut for sewers & manhole except shingle, gravel & rock : Upto 2m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-49-b",
    "B": "Excavation in open cut for sewers & manholes except shingle, gravel & rock : 2m to 5m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-49-c",
    "B": "Excavation in open cut for sewers & manholes except shingle, gravel & rock : Below 5m depth",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-50-a",
    "B": "Trench Excavation in open cutting below water level for sewers & manholes : Upto 1.25m depth",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-50-b",
    "B": "Trench Excavation in open cutting below water level for sewers & manholes : 1.25m to 2.5m depth",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-50-c",
    "B": "Trench Excavation in open cutting below water level for sewers & manholes : Exceeding 2.5m depth",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-51",
    "B": "Excavation of trench in all kinds of soils except cutting in rock for pilelines upto 1.5m depth",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-52-a",
    "B": "Uprooting stump and removing within 50 m upto 2 m girth",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "B"
  },
  {
    "A": "03-52-b",
    "B": "Uprooting stump and removing within 50 m above 2 m girth",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "B"
  },
  {
    "A": "03-52-c",
    "B": "Uprooting stump and removing within 50 m upto 2 m girth including sand filling and trinches",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "B"
  },
  {
    "A": "03-52-d",
    "B": "Uprooting stump and removing within 50 m above 2 m girth including sand filling and trenches",
    "C": "No",
    "D": "Each",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "B"
  },
  {
    "A": "03-53-a",
    "B": "Jungle clearance and removing within 50m Light Jungle",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-53-b",
    "B": "Jungle clearance and removing within 50m Thick Jungle",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-54",
    "B": "Uprooting sarkanda growth & disposal within 50m",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-55",
    "B": "Stripping",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-57",
    "B": "Levelling, dressing and making lawns",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-58",
    "B": "Turfing of lawn with Dacca Grass",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-59-a",
    "B": "Excavation and Clearance of shingle, gravel including sand, soft soil and silt deposits in channel bed upto 25m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-59-b",
    "B": "Excavation and Clearance of shingle, gravel including sand, soft soil and silt deposits by mechanical means in channel bed upto 25m",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-60",
    "B": "Clearance of site  by cutting, removing all shrubs, trees, upto 6 inches (152 mm) girth, etc. and taking out their entire roots and filling the hollows with earth complete with dressing, consolidating and watering the filling including stacking the serviceable materials and disposal of useless material as directed, lead to 10 chains, cost of earth included.",
    "C": "No",
    "D": "m2",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "B"
  },
  {
    "A": "03-61-a",
    "B": "Clearance of choked up syphon including dewatering Upto 1.25m dia",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-61-b",
    "B": "Clearance of choked up syphon including dewatering Exceeding 1.25m dia",
    "C": "No",
    "D": "m",
    "E": 1,
    "F": "",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-a",
    "B": "Earthwork by mechanical means in drains and irrigation channels in DRY soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-b",
    "B": "Earthwork by mechanical means in drains and irrigation channels in DRY & WET soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-c",
    "B": "Earthwork by mechanical means in drains and irrigation channels in WET & SLUSH soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-d",
    "B": "Earthwork by mechanical means in drains and irrigation channels in SLUSH soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-e",
    "B": "Earthwork by mechanical means in drains and irrigation channels in SLUSH soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-f",
    "B": "Earthwork by mechanical means in drains and irrigation channels in BORROW AREA soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-72-g",
    "B": "Earthwork by mechanical means in drains and irrigation channels in UNDER WATER soil dressed to designed section, grades profile/with excavated material, disposed off within 50 feet (15.2 m) lead and dressed as directed.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-73",
    "B": "Provide, place and compact impervious clay core in dam embankment of specified grading, permiability i/c leveling dressing and haulage obtaining 95% modified AASHTO dry density i/c 1.5 KM lead",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-74",
    "B": "Provide , Place & compact shoulder material (sandy / silty gravel) in Dam Embankment, of specified grading, leveling/ dressing i/c haulage for obtaining of 95% modified AASHTHO dry density.",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-75",
    "B": "Provide , Place & compact Fine Filter in chimney drain vertical / horizantol on downstream of clay core",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-76",
    "B": "Provide , Place & compact Coarse Filter (well graded gravel) in chimney drain vertical / horizantol on downstream of fine filter i/c leveling, moistening etc",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-77",
    "B": "Excavation for core trench of Dam Embankment/Spillway/Intake & Outlet Structure and Irrigation System upto a minimum depth of 35 ft in common soil including removing of excavated material by machinery in 1.5 KM radius",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-78-a",
    "B": "Excavation for core trench of Dam Embankment/Spillway/Intake & Outlet Structure and Irrigation System upto a minimum depth of 35 ft in shingle gravel including removing of excavated material by machinery in 1.5 KM radius",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  {
    "A": "03-78-b",
    "B": "Excavation for core trench of Dam Embankment/Spillway/Intake & Outlet Structure and Irrigation System upto design depth in Soft Rock/Shale requiring 20% blasting i/c removing of material from outside of the structure area lead upto 1.5 KM ",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"


  },
  {
    "A": "03-78-c",
    "B": "Excavation for core trench of Dam Embankment/Spillway/Intake & Outlet Structure and Irrigation System upto design depth in Medium Hard Rock requiring 50% blasting i/c removing of material from outside of the structure area lead upto 1.5 KM",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"

  },
  {
    "A": "03-78-d",
    "B": "Excavation for core trench of Dam Embankment/Spillway/Intake & Outlet Structure and Irrigation System upto design depth in Hard Rock requiring 75% blasting i/c removing of material from outside of the structure area lead upto 1.5 KM",
    "C": "No",
    "D": "m cube",
    "E": 1,
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ],
    "Section": "B"
  },
  // {
  //   "A": "Sub-Total B",
  //   "B": "",
  //   "C": "",
  //   "D": "",
  //   "E": "",
  //   "F": "",
  //   "G": "",
  //   "H": "",
  //   "I": 0,
  //   "J":0,
  //   "K": 0,
  //   "inputFields": [

  //   ]
  // },
  // {
  //   "A": "MRS code",
  //   "B": "Item description",
  //   "C": "Item selection",
  //   "D": "Unit",
  //   "E": "Number",
  //   "F": "Area",
  //   "G": "",
  //   "H": "Height",
  //   "I": "Quantity",
  //   "J":0,
  //   "K": "Amount (PKR)",
  //   "inputFields": [
  //   ]
  // },
  {
    "A": "03-42-a",
    "B": "Ploughing and levelling borrow pits Upto 1m depth",
    "C": "No",
    "D": "ha",
    "E": "",
    "F": 2,
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "C",
  },
  {
    "A": "03-42-b",
    "B": "Ploughing and levelling borrow pits Exceeding 1m depth",
    "C": "No",
    "D": "ha",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "C",
  },
  {
    "A": "03-56",
    "B": "Ploughing 3 times",
    "C": "No",
    "D": "ha",
    "E": 1,
    "F": "",
    "G": "",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G"
    ],
    "Section": "C",
  },
  // {
  //   "A": "Sub-Total C",
  //   "B": "",
  //   "C": "",
  //   "D": "",
  //   "E": "",
  //   "F": "",
  //   "G": "",
  //   "H": "",
  //   "I": 0,
  //   "J":0,
  //   "K": 0,
  //   "inputFields": [

  //   ]
  // },
  // {
  //   "A": "MRS code",
  //   "B": "Item description",
  //   "C": "Item selection",
  //   "D": "Unit",
  //   "E": "Number",
  //   "F": "Length",
  //   "G": "Width",
  //   "H": "Height",
  //   "I": "Quantity",
  //   "J":0,
  //   "K": "Amount (PKR)",
  //   "inputFields": [

  //   ]
  // },
  {
    "A": "03-63",
    "B": "Removal of Tree : Girth 150mm - 300mm including removal of stump & backfilling with sand",
    "C": "No",
    "D": "No",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "D"
  },
  {
    "A": "03-64-a",
    "B": "Removal of Tree : Girth 300mm - 600mm including removal of stump & backfilling with sand",
    "C": "No",
    "D": "No",
    "E": 0,
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,

    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "D"
  },
  {
    "A": "03-64-b",
    "B": "Removal of Tree : Girth over 600mm including removal of stump & backfilling with sand",
    "C": "No",
    "D": "No",
    "E": "",
    "F": "-",
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ],
    "Section": "D"
  },
  // {
  //   "A": "Sub-Total D",
  //   "B": "",
  //   "C": "",
  //   "D": "",
  //   "E": "",
  //   "F": "",
  //   "G": "",
  //   "H": "",
  //   "I": 0,
  //   "J":0,
  //   "K": 0,
  //   "inputFields": [

  //   ]
  // },
  {
    "A": "Sub-Total Bill 1 (A+B+C+D)",
    "B": "",
    "C": "",
    "D": "",
    "E": "",
    "F": "",
    "G": "",
    "H": "",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [

    ]
  }
]
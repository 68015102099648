import React, { useCallback, useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import signup_img from "../assets/bg_signup.png";
import logo from "../assets/logo.png";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";
import Selector from "../components/Selector";
import { AllCitiesListInPakistan, CitiesListOfKPK } from "../helpers";
import Input from "../components/Input";
import Button from "../components/Button";
import Label from "../components/Label";
import axios from "axios";
import { userLogin } from "../store/actions";
import "../components/css/customScrollBar.css";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import Select from "react-select";

const api_url = process.env.REACT_APP_API_URL + "/site/signup";

export default function SignupVerifiedUser() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    // mrs_year: "",
    pec_number: "",
    organization: "",
    city: "",
    password: "",
    username: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passType, setPassType] = useState("password");
  const [loading, setLoading] = useState(false);

  const togglePassword = (e) => {
    e.preventDefault();
    if (showPassword) {
      setShowPassword(false);
    } else {
      setShowPassword(true);
    }
  };

  useEffect(() => {
    return showPassword ? setPassType("text") : setPassType("password");
  }, [showPassword]);

  const handleConfirmPassword = () => {};
  const handleSignup = async (e) => {
    setLoading(true);
    const errors = checkValidation(userData);
    if (Object.keys(errors).length > 0) {
      setLoading(false);
      Swal.fire({
        position: "top",
        icon: "error",
        toast: true,
        title: Object.values(errors)
          .map((msg) => msg)
          .join("\n"),
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }
    e.preventDefault();
    // dispatch(userLogin({ userData }));
    // return navigate("/projects");

    await axios
      .post(api_url, userData)
      .then((response) => {
        if (response.data === true) {
          setLoading(false);
          navigate("/login");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          position: "top",
          icon: "error",
          toast: true,
          title: Object.values(JSON.parse(error?.response?.data?.message))
            .map((msg) => msg)
            .join("\n"),
          showConfirmButton: false,
          timer: 1500,
        });
        console.error("Error:", error);
      });
  };

  function isAlphabetic(username) {
    var alphabeticRegex = /^[a-zA-Z]+$/;
    return alphabeticRegex.test(username);
  }

  function checkValidation(usrData) {
    let errors = {};
    let EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (usrData.name === "") {
      errors.name = "Name is required";
    }
    const fullName = usrData.name.replace(/\s/g, '');
    if (usrData.name !== "" && !isAlphabetic(fullName)) {
      errors.name = "Name should be alphabetic";
    }
    if (usrData.email === "") {
      errors.email = "Email is required";
    } else if (usrData.email !== "" && !usrData.email.includes("@")) {
      errors.email = "Email should be valid";
    } else if (
      usrData.email !== "" &&
      EmailRegex.test(usrData.email) === false
    ) {
      errors.email = "Email should be valid";
    }
    if (usrData.pec_number === "") {
      errors.pec_number = "PEC Number is required";
    }
    if (usrData.organization === "") {
      errors.organization = "Organization is required";
    }
    if (usrData.city === "") {
      errors.city = "City is required";
    }
    if (usrData.password === "") {
      errors.password = "Password is required";
    }
    if (Object.keys(errors).length > 0) {
      return errors;
    }
    return {};
  }

  return (
    <form>
      <div className=" flex flex-row justify-center font-poppins items-center bg-gray-100 h-screen p-20 ">
        <div className=" flex flex-row justify-center  bg-white  m-10 h-fit w-full rounded-lg  ">
          <div className=" w-full flex flex-col  items-center p-5 overflow-auto h-max-fit h-full   ">
            <img src={logo} alt="logo" className="h-20 w-20" />
            <span className="p-5 font-bold">Please enter your details.</span>

            <div className="overflow-auto h-max-fit h-96   w-full flex flex-col  items-center p-8">
              <div className="flex flex-col gap-1 h-full w-4/5">
                <Label>Full Name</Label>
                <Input
                  type="text"
                  required
                  placeholder="Full Name"
                  name="name"
                  className="h-10 w-50 text-sm text-gray-400"
                  onChange={(e) =>
                    setUserData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>Email</Label>
                <Input
                  type="email"
                  required
                  name="email"
                  placeholder="Email"
                  className="h-10 text-sm text-gray-400"
                  width="w-50"
                  onChange={(e) =>
                    setUserData((prevData) => ({
                      ...prevData,
                      username: e.target.value,
                      email: e.target.value,
                    }))
                  }
                />
              </div>
              {/* <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                                <Label >MRS Year</Label>
                                <div className="flex flex-row gap-1 h-full w-full ">
                                    <Input
                                        type="number"
                                        required
                                        name='mrs_year'
                                        placeholder='MRS Year'
                                        className="h-10 text-sm text-gray-400"
                                        onChange={(e) => setUserData((prevData) => ({ ...prevData, mrs_year: e.target.value }))}

                                    />

                                </div>
                            </div> */}
              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>PEC Number</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type="text"
                    required
                    placeholder="PEC Number"
                    name="pec_number"
                    className="h-10 text-sm text-gray-400"
                    onChange={(e) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        pec_number: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>Organization Name</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type="text"
                    required
                    name="organization"
                    placeholder="Organization Name"
                    autoComplete="name"
                    className="h-10 text-sm text-gray-400"
                    onChange={(e) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        organization: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>City</Label>
                {/* <div className="flex flex-row gap-1 h-full w-full ">
                  <Selector
                    options={Cities}
                    onChange={(e) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        city: e.value,
                      }))
                    }
                  />
                </div> */}
                <Select
                  className="border-gray-300 border-b-2 text-primary font-poppins"
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: "none",
                      boxShadow: "none",
                    }),
                  }}
                  classNamePrefix="select"
                  isSearchable={true}
                  options={AllCitiesListInPakistan}
                  onChange={(e) => {
                    console.log(e);
                    setUserData((prevData) => ({
                      ...prevData,
                      city: e.value,
                    }));
                  }}
                />
              </div>

              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>Password</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type={passType}
                    required
                    name="password"
                    placeholder="Password"
                    className="h-10 text-sm text-gray-400"
                    onChange={(e) =>
                      setUserData((prevData) => ({
                        ...prevData,
                        password: e.target.value,
                      }))
                    }
                  />
                  <button
                    className="flex justify-center items-center focus:outline-none text-gray-500  "
                    onClick={(e) => {
                      togglePassword(e);
                    }}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                </div>
              </div>
              <div className="mt-5 flex flex-col gap-1 h-full w-4/5">
                <Label>Confirm Password</Label>
                <div className="flex flex-row gap-1 h-full w-full ">
                  <Input
                    type={passType}
                    name="confirmpassword"
                    required
                    placeholder="Confirm Password"
                    className="h-10 text-sm text-gray-400"
                  />
                  <button
                    className="flex justify-center items-center focus:outline-none text-gray-500  "
                    onClick={togglePassword}
                  >
                    {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                  </button>
                </div>
              </div>

              {/* <div className="mt-3 flex flex-col  h-full w-4/5">
                                <div className="flex flex-row gap-1 h-full w-full ">
                                    <Input
                                        type='checkbox'
                                        required
                                        width='w-fit'
                                        name='usercheckbox'

                                    />
                                    <span
                                        className="w-full text-sm text-gray-500  "
                                    >
                                        I hereby declare that information provided is true & correct
                                    </span>
                                </div>
                            </div> */}

              <div className="w-1/2 pt-5 ">
                <Button
                  label={"Sign Up"}
                  loading={loading}
                  type={"button"}
                  className={
                    "rounded-lg font-poppins text-white hover:bg-orange-400 w-full"
                  }
                  onClick={(e) => {
                    handleSignup(e);
                  }}
                />
              </div>

              <div className="w-4/5 pt-5 text-center">
                <p>
                  Already have an account{" "}
                  <span className="text-primary font-poppins font-bold">
                    <NavLink to={"/"}>Login</NavLink>
                  </span>
                </p>
              </div>
            </div>
            <hr></hr>
          </div>
          <div className="flex justify-center items-center  w-full ">
            <img src={signup_img} className="w-full" alt="login_img" />
          </div>
        </div>
      </div>
    </form>
  );
}

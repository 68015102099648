import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectSegments from "./pages/ProjectSegments";
import SignupVerifiedUser from "./pages/SignupVerifiedUser";
import Profile from "./pages/Profile";
import AddProjectForm from "./pages/AddProjectForm";
import RoadModule from "./pages/segments/RoadModule";
import TBridgeModule from "./pages/segments/TBridgeModule";
import SlabBridge from "./pages/segments/SlabBridge";
import BoxCulvert from "./pages/segments/BoxCulvert";
import SlabCulvert from "./pages/segments/SlabCulvert";
import CounterfortRetainingWall from "./pages/segments/retaining-walls/CounterfortRetainingWall";
import CantileverRetainingWall from "./pages/segments/retaining-walls/CantileverRetainingWall";
import GravityRetainingWallInward from "./pages/segments/retaining-walls/GravityRetainingWallInward";
import GravityRetainingWallOutward from "./pages/segments/retaining-walls/GravityRetainingWallOutward";
import ClosedCauseway from "./pages/segments/causeways/ClosedCauseway";
import VentedCauseway from "./pages/segments/causeways/VentedCauseway";
import Instruction from "./pages/Instruction";
import DownloadDocPage from "./pages/DownloadDocPage";
import Adt from "./pages/Adt";
import Esal from "./pages/Esal";
import WideningOfCurve from "./pages/WideningOfCurve";
import SuperElevationRunOff from "./pages/SuperElevationRunOff";
import Settings from "./pages/Settings";
import { useSelector } from "react-redux";
import InAugurationPage from "./pages/InAugurationPage";

function App() {
  const isUserLoggedIn = useSelector((state) => state?.user);
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<Home />} />
      <Route path="/launch" element={<InAugurationPage />} />
      <Route path="/home" element={<Home />} />
      <Route path="/home/launch" element={<Home />} />
      <Route path="/signup" element={<SignupVerifiedUser />} />
      <Route path="/login" element={<Login />} />
      <Route path="/download-doc/:name" element={<DownloadDocPage />} />

      {/* Private Generic Routes */}
      <Route element={!isUserLoggedIn && <Login />}>

        <Route path="/adt" element={<Adt />} />

        <Route path="/esal" element={<Esal />} />

        <Route path="/profile" element={<Profile />} />

        <Route path="/instructions/*" element={<Instruction />} />

        <Route path="/widening-of-curve" element={<WideningOfCurve />} />

        <Route
          path="/super-elevation-run-off"
          element={<SuperElevationRunOff />}
        />
        <Route path="/settings" element={<Settings />} />

      </Route>

      {/* <Route path="/road/:projectId/segment/:segmentId" element={<RoadModule />} />
       */}

      {/* Road Private Routes */}
      <Route path="/road/*" element={!isUserLoggedIn && <Login />}>
        <Route path=":projectId" element={<RoadModule />} />
        <Route path=":projectId/segment/:segmentId" element={<RoadModule />} />
      </Route>

      {/** Project Private Route */}
      <Route path="/projects/*" element={!isUserLoggedIn && <Login />}>
        <Route path="" element={<Projects />} />
        <Route path="add-project" element={<AddProjectForm />} />
        <Route path="edit-project/:projectId" element={<AddProjectForm />} />
        <Route path=":projectId" element={<ProjectSegments />} />
      </Route>

      {/** Allied Infrastructure Private Routes */}

      <Route path="/add-allied-infrastructure/:projectId/*" element={!isUserLoggedIn && <Login />}>

        <Route path="t-beam-bridges/:segmentId" element={<TBridgeModule />} />
        <Route path="t-beam-bridges" element={<TBridgeModule />} />

        <Route path="slab-bridges/:segmentId" element={<SlabBridge />} />
        <Route path="slab-bridges" element={<SlabBridge />} />

        <Route path="box-culverts/:segmentId" element={<BoxCulvert />} />
        <Route path="box-culverts" element={<BoxCulvert />} />

        <Route path="counterfort-retaining-walls/:segmentId" element={<CounterfortRetainingWall />} />
        <Route path="counterfort-retaining-walls" element={<CounterfortRetainingWall />} />

        <Route path="cantilever-retaining-walls/:segmentId" element={<CantileverRetainingWall />} />
        <Route path="cantilever-retaining-walls" element={<CantileverRetainingWall />} />

        <Route path="gravity-retaining-wall-sloping-inwards/:segmentId" element={<GravityRetainingWallInward />} />
        <Route path="gravity-retaining-wall-sloping-inwards" element={<GravityRetainingWallInward />} />

        <Route path="gravity-retaining-wall-sloping-outwards/:segmentId" element={<GravityRetainingWallOutward />} />
        <Route path="gravity-retaining-wall-sloping-outwards" element={<GravityRetainingWallOutward />} />

        <Route path="closed-causeways/:segmentId" element={<ClosedCauseway />} />
        <Route path="closed-causeways" element={<ClosedCauseway />} />

        <Route path="vented-causeways/:segmentId" element={<VentedCauseway />} />
        <Route path="vented-causeways" element={<VentedCauseway />} />

        <Route path="slab-culverts/:segmentId" element={<SlabCulvert />} />
        <Route path="slab-culverts" element={<SlabCulvert />} />
      </Route>

    </Routes>
  );
}

export default App;

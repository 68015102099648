import React from "react";
import { BiIcons } from "../components/Icons";

const SearchBar = ({ ...props }) => {
  const searchIcon = "BiSearch";

  return (
    <div className="flex rounded-lg gap-2  bg-gray-200 items-center p-2 font-roboto desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
      <BiIcons iconName={searchIcon} />
      <input
        type="text"
        placeholder="Search by name"
        name="searchproject"
        id="searchproject"
        className="h-fit w-fit focus:outline-none p-1 rounded-lg  bg-gray-200 "
        value={props?.search ?? ""}
        onChange={(e) => (props?.setSearch ? props?.setSearch(e.target.value) : {})}
      />
    </div>
  );
};

export default SearchBar;

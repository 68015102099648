import { createSlice } from "@reduxjs/toolkit";
import { getRequiredSn, model } from "../../helpers";

const setBin = ({
  priorityValue,
  optionalValue,
  priorityRange,
  optionalRange,
  optionalRangeValues,
  afterSelect,
  afterSelectOptional,
}) => {
  let foundValue = "-";
  if (priorityValue) {
    const i = model.binFromRange(priorityValue, priorityRange);
    foundValue = priorityRange[i]?.label;
    afterSelect(i);
  } else {
    const j = model.binFromRange(parseInt(optionalValue), optionalRange);
    foundValue = optionalRange[j]?.label;
    afterSelectOptional(j);
  }
};
//subgrade
const setSubgradeBin = (psiValue, cbrValue, binModel) => {
  setBin({
    priorityValue: psiValue,
    optionalValue: cbrValue,
    priorityRange: model.subgradeMrRange,
    optionalRange: model.subgradeCBRRange,
    optionalRangeValues: model.subgradeCBRRangeValues,
    afterSelect: (i) => {
      binModel.setSelectedSubGrade(i);
    },
    afterSelectOptional: (i) => {
      binModel.setSelectedSubGradeCbr(i);
    },
  });
};

// subbase
const setSubbaseBin = (psiValue, cbrValue, binModel) => {
  setBin({
    priorityValue: psiValue,
    optionalValue: cbrValue,
    priorityRange: model.subBaseEbsRange,
    optionalRange: model.subBaseCBRRange,
    optionalRangeValues: model.subBaseCBRRangeValues,
    afterSelect: (i) => {
      binModel.setSelectedSubBase(i);
    },
    afterSelectOptional: (i) => {
      binModel.setSelectedSubBaseCbr(i);
    },
  });
};
// base
const setbaseBin = (psiValue, cbrValue, binModel) => {
  setBin({
    priorityValue: psiValue,
    optionalValue: cbrValue,
    priorityRange: model.baseEBSRange,
    optionalRange: model.baseCBRRange,
    optionalRangeValues: model.baseCBRRangeValue,
    afterSelect: (i) => {
      binModel.setSelectedBase(i);
    },
    afterSelectOptional: (i) => {
      binModel.setSelectedBaseCbr(i);
    },
  });
};

const setEsalBin = (esalBinValue, binModel) => {
  const esalBinIndex = model.esalBinSelection(esalBinValue);
  binModel.setSelectedEsalBin(esalBinIndex);
};

const identifyModule = (updatedModel) => {
  /** module code */
  //I39=I17&"_"&I25&"_"&I33&"_"&I37
  //I17="(TL_"&VLOOKUP(esalBinLabel,I11:J16,2,FALSE)&")"=(TL_0.151-0.50)
  //I25="(SG_"&VLOOKUP(subgradeBinLabel,I18:K24,2,FALSE)&")"=(SG_(CBR_10-25.998)_(MR_15000-20557))
  //I33="(SB_"&VLOOKUP(subbaseBinLabel,I26:K32,2,FALSE)&")"=(SB_(CBR_40-49.99)_(MRsb_17000-17999) (S))
  //I37=="(BS_"&VLOOKUP(baseBinLabel,I34:K36,2,FALSE)&")"=(BS_(CBR_100)_(MRbs_30000-45000))
  //search I39 in db

  let subGrade = model.getBinRangeFromIndex(
    updatedModel.subgradeCBRRange,
    updatedModel.selectedSubGradeCbr
  );
  if (updatedModel.selectedSubGrade)
    subGrade = model.getBinRangeFromIndex(
      updatedModel.subgradeMrRange,
      updatedModel.selectedSubGrade
    );
  //
  let subBase = model.getBinRangeFromIndex(
    updatedModel.subBaseCBRRange,
    updatedModel.selectedSubBaseCbr
  );
  if (updatedModel.selectedSubBase)
    subBase = model.getBinRangeFromIndex(
      updatedModel.subBaseEbsRange,
      updatedModel.selectedSubBase
    );
  //
  let base = model.getBinRangeFromIndex(
    updatedModel.baseCBRRange,
    updatedModel.selectedBaseCbr
  );
  if (updatedModel.selectedBase)
    base = model.getBinRangeFromIndex(
      updatedModel.baseEBSRange,
      updatedModel.selectedBase
    );

  const esalTl = model.getBinRangeFromIndex(
    updatedModel.esalBins,
    updatedModel.selectedEsalBin
  );

  // console.log(tl, sg, sb, bs);
  return { subGrade, subBase, base, esalTl };
};

const initialState = {
  binIndexes: {
    subgradePsi: null,
    subgradeCbr: null,
    subbasePsi: null,
    subbaseCbr: null,
    basePsi: null,
    baseCbr: null,
    esalBin: null,
  },
  model: { ...model },
  identifyParams: { tl: null, sg: null, sb: null, bs: null },
  roadModule: {
    nomenclature: null,
  },
  mrs: {
    "03-01-b": 0,
    "16-04-a": 0,
    "16-04-b": 0,
    "16-05-a": 0,
    "16-05-b": 0,
  },
  parameters: {
    m1: 1,
    m2: 1,
    m3: 1,
    Pt: 2,
    Pi: 4,
    R: 95,
    So: 0.45,
    Eac: 417000,
  },
  thicknessSection: {
    ac: 3.5,
    ab: 12,
    asb: 39.5,
  },
  cut: {},
  fill: {},
  subgrade: {},
  roadFormInputs: {
    fill: { 0: { start: 0, end: 0, width: 0, averageWidth: 0, volume: 0 } },
    cut: { 0: { start: 0, end: 0, width: 0, averageWidth: 0, volume: 0 } },
    compaction_ng: {
      0: { start: 0, end: 0, width: 0, averageWidth: 0, area: 0 },
    },
    clearing_and_grubbing: {
      0: { start: 0, end: 0, width: 0, averageWidth: 0, area: 0 },
    },
    subgrade: { 0: { start: 0, end: 0, width: 0, averageWidth: 0, area: 0 } },
  },
  bill1_estimates_A: {},
  bill1_estimates_B: {},
  bill1_estimates_C: {},
  bill1_estimates_D: {},
  bill2_estimates: {},
  bill3_estimates: {},
  bill6_estimates: {},
  bill5_estimates: {},
  bill7_estimates: {},
  roadMaterial: {},
  drawingInputValues: {},
  adt: {},
  adtEsal: { dl: 10, g: 7, df: 0.5, lf: 1 },
};

export const roadSlice = createSlice({
  name: "road",
  initialState: initialState,
  reducers: {
    resetToInitialValues(state, action) {
      return {
        ...initialState,
      }
    },
    setEditInitFormInputs(state, action) {
      return {
        ...state,
        ...action.payload,
      };
    },
    setRoadFormInputs(state, action) {
      const { name, ...others } = action.payload;

      return {
        ...state,
        roadFormInputs: {
          ...state.roadFormInputs,
          [name]: others,
        },
      };
    },
    modelChanged(state, action) {
      const { name, value } = action.payload;

      return {
        ...state,
        binIndexes: {
          ...state.binIndexes,
          [name]: value,
        },
        model: {
          ...model,
        },
      };
    },
    roadMaterialChanged(state, action) {
      const { name, value, text } = action.payload;

      return {
        ...state,
        roadMaterial: {
          ...state.roadMaterial,
          [name]: value,
          [`${name}_text`]: text,
        },
      };
    },
    updateParameters(state, action) {
      const { name, value } = action.payload;
      const updatedParameters = {
        ...state.parameters,
        [name]: value,
      };
      const calculatedValues = getRequiredSn(updatedParameters, state.model);

      return {
        ...state,
        parameters: {
          ...updatedParameters,
          ...calculatedValues,
        },
      };
    },
    resetParameters(state, action) {
      return {
        ...state,
        parameters: initialState.parameters,
      };
    },
    updateThickness(state, action) {
      const { name, value } = action.payload;

      return {
        ...state,
        thicknessSection: {
          ...state.thicknessSection,
          [name]: isNaN(value) ? 0 : parseFloat(value),
        },
      };
    },
    resetThickness(state, action) {
      return {
        ...state,
        thicknessSection: initialState.thicknessSection,
      };
    },
    initModel(state, action) {
      return {
        ...state,
        model: {
          ...state.model,
        },
        mrs: {
          ...state.mrs,
        },
      };
    },

    binSetter(state, action) {
      const updatedModel = { ...state.model };

      setSubgradeBin(
        state.binIndexes.subgradePsi,
        state.binIndexes.subgradeCbr,
        updatedModel
      );
      setSubbaseBin(
        state.binIndexes.subbasePsi,
        state.binIndexes.subbaseCbr,
        updatedModel
      );
      setbaseBin(
        state.binIndexes.basePsi,
        state.binIndexes.baseCbr,
        updatedModel
      );
      setEsalBin(state.binIndexes.esalBin, updatedModel);

      // return console.log("🚀 ~ binSetter ~ updatedModel:", updatedModel);

      return {
        ...state,
        model: {
          ...state.model,
          ...updatedModel,
        },
      };
    },
    identifyBin(state, action) {
      const updatedModel = { ...state.model };

      const { subGrade, subBase, base, esalTl } = identifyModule(updatedModel);
      return {
        ...state,
        identifyParams: {
          ...state.identifyParams,
          sg: subGrade,
          sb: subBase,
          bs: base,
          tl: esalTl,
        },
      };
    },
    setRoadModule(state, action) {
      return {
        ...state,
        roadModule: action.payload,
        thicknessSection: {
          ac: action.payload.ac,
          ab: action.payload.base,
          asb: action.payload.subBase,
        },
      };
    },

    setMrsYearPrice(state, action) {
      return {
        ...state,
        mrs: {
          ...state.mrs,
          [action.payload.item_code]: action.payload.price,
        },
      };
    },

    setMrsYearPriceArray(state, action) {
      const updatedMrs = {
        ...state.mrs,
      }
      for (let i in action.payload) {
        updatedMrs[action.payload[i].item_code] = action.payload[i].price;
      }
      return {
        ...state,
        mrs: updatedMrs,
      };
    },

    setDrawingInputValues(state, action) {
      return {
        ...state,
        drawingInputValues: action.payload,
      };
    },
    setBillEstimates(state, action) {
      const { billName, billData } = action.payload;
      return {
        ...state,
        [billName]: {
          ...state[billName],
          ...billData,
        },
      };
    },


    deleteBillEstimation(state, action) {
      const { billName } = action.payload;
      return {
        ...state,
        [billName]: {

        },
      };
    },

    //NOT FOUND
    updateBillEstimates(state, action) {
      const { billName, index, item, billData } = action.payload;
      const calculateAbleInputs = state[billName][item]?.inputFields;
      console.log("🚀 ~ updateBillEstimates ~ state[billName][item]:", state[billName][item][index])
      let totalQuantity = 0;

      calculateAbleInputs.forEach((i) => {
        let temp1 = state[billName][item][i];
        if (index === i) {
          temp1 = billData;

        }
        if (!isNaN(temp1) && temp1 >= 0) {
          if (totalQuantity === 0) totalQuantity = 1;
          totalQuantity *= parseFloat(temp1);

        }
      });

      console.log(calculateAbleInputs.includes('E'));
      if (parseFloat(state[billName][item]["F"]) > 0 && !calculateAbleInputs.includes('F')) {
        totalQuantity *= parseFloat(state[billName][item]["F"]);
      }

      if (parseFloat(state[billName][item]["G"]) > 0 && !calculateAbleInputs.includes('G')) {
        totalQuantity *= parseFloat(state[billName][item]["G"]);
      }

      if (parseFloat(state[billName][item]["H"]) > 0 && !calculateAbleInputs.includes('H')) {
        totalQuantity *= parseFloat(state[billName][item]["H"]);
      }
      console.log("🚀 ~ updateBillEstimates ~ totalQuantity:", totalQuantity)


      // totalQuantity = totalQuantity * parseFloat(billData);
      let K = 0; // K is Amount(PKR)
      let C = totalQuantity > 0 ? "Yes" : "No";
      let J = state?.mrs[item] && parseFloat(state?.mrs[item]); // J is Unit Rate(PKR)
      if (totalQuantity > 0 && J && !isNaN(J)) {
        K = totalQuantity * J;
      }
      return {
        ...state,
        [billName]: {
          ...state[billName],
          [item]: {
            ...state[billName][item],
            [index]: billData,
            I: totalQuantity,
            C,
            J,
            K,
          },
        },
      };
    },
    updateBill7Estimates(state, action) {
      const { billName, index, item, billData } = action.payload;
      const calculateAbleInputs = state[billName][item]?.inputFields;
      console.log("🚀 ~ updateBillEstimates ~ state[billName][item]:", state[billName][item][index])
      let totalQuantity = 0;
      let rate = 0;

      calculateAbleInputs.forEach((i) => {
        let temp1 = state[billName][item][i];
        if (index === i) {
          temp1 = billData;
        }
        if (!isNaN(temp1) && temp1 >= 0) {
          if (i === 'J') {
            rate = temp1;
          }
          else {
            if (totalQuantity === 0) totalQuantity = 1;
            totalQuantity *= parseFloat(temp1);
          }
        }
      });
      // totalQuantity = totalQuantity * parseFloat(billData);
      let K = 0; // K is Amount(PKR)
      let C = totalQuantity > 0 ? "Yes" : "No";
      // let J =  // J is Unit Rate(PKR)
      if (totalQuantity > 0 && rate && !isNaN(rate)) {
        K = totalQuantity * rate;
      }
      return {
        ...state,
        [billName]: {
          ...state[billName],
          [item]: {
            ...state[billName][item],
            [index]: billData,
            I: totalQuantity,
            C,
            J: rate,
            K,
          },
        },
      };
    },

    updateQuantity(state, action) {
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          [action.payload.mrs_code]: {
            ...state[action.payload.name][action.payload.mrs_code],
            ["C"]: parseFloat(action.payload["C"]),
            ["D"]: parseFloat(action.payload["D"]),
          },
        },
      };
    },
    changeAdt(state, action) {
      const { key, inputIndex, value } = action.payload;
      return {
        ...state,
        adt: {
          ...state.adt,
          [key]: {
            ...state.adt[key],
            [inputIndex]: value,
          },
        },
      };
    },
    changeAdtEsal(state, action) {
      const { name, value } = action.payload;
      return {
        ...state,
        adtEsal: {
          ...state.adtEsal,
          [name]: value,
        },
      };
    },
    includeBillLine(state, action) {
      const { bill, code, include } = action.payload;
      return {
        ...state,
        [bill]: {
          ...state[bill],
          [code]: {
            ...state[bill][code],
            included: include,
            // ...state[bill][code].k = state[bill][code]?.I * state[bill][code]?.J,
          },
        },
      };
    },

  },
});

export const bill2_estimation = [
  {
    "A": "16-01",
    "B": "Providing and Laying sub-base course of brick on edge 4.5\" thick",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.983,
    "H": 0.279,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-02",
    "B": "Providing and Laying sub-base course of brick aggregate, including compaction to required camber, grade & density",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.983,
    "H": 0.279,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-04-a",
    "B": "Granular Sub Base Course using Pit Run Gravel",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.983,
    "H": 0.279,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-04-b",
    "B": "Granular Sub Base Using Crushed Stone Aggregate",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.983,
    "H": 0.279,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-05-a",
    "B": "Aggregate Base Course",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.552,
    "H": 0.152,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-05-b",
    "B": "Water Bound Macadam Base Course",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 1000.0,
    "G": 7.552,
    "H": 0.152,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-06",
    "B": "Providing and Laying road edging of 3\" wide & 9\" deep brick on end, complete",
    "C": "No",
    "D": "m",
    "E": "",
    "F": 1000.0,
    "G": "-",
    "H": "-",
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E"
    ]
  },
  {
    "A": "16-03",
    "B": "Graded Crushed Aggregate Crack-Relief Layer",
    "C": "No",
    "D": "m cube",
    "E": "",
    "F": 500.0,
    "G": 2.5,
    "H": 0.25,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [
      "E", "F", "G", "H"
    ]
  },
  {
    "A": "Sub-Total Bill 2",
    "B": NaN,
    "C": NaN,
    "D": NaN,
    "E": NaN,
    "F": NaN,
    "G": NaN,
    "H": NaN,
    "I": 0,
    "J": 0,
    "K": 0,
    "inputFields": [

    ]
  }
]
import React, { useEffect, useRef, useState } from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import Button from "./Button";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import ToolsDropdown from "./ToolsDropdown";

export default function DownloadScreenHeader() {
  const [userExist, setUserExist] = useState(false);
  const isUser = useSelector((state) => state.user);
  const checkUserLoggedIn = (userStatus) => {
    if (userStatus === null) {
      return setUserExist(false);
    }
    return setUserExist(true);
  };

  useEffect(() => {
    checkUserLoggedIn(isUser);
  }, []);

  return (
    <div className="flex flex-row w-screen items-center py-3 bg-white sticky border-b-2 border-gray-100 top-0 z-10">
      <div className="flex-none flex justify-center items-center  w-48 h-20 desktopMd:w-52 desktopMd:h-28 desktopXmd:w-56 desktopXmd:h-32 desktopLg:w-60 desktopLg:h-40 desktopXlg:w-64 desktopXlg:h-48 ml-10">
        <img
          src={logo}
          alt="logo_img"
          className="h-24 w-24 desktopMd:h-32 desktopMd:w-32 desktopXmd:h-36 desktopXmd:w-36 desktopLg:h-42 desktopLg:w-42 desktopXlg:h-48 desktopXlg:w-48"
        />
      </div>
      <div className=" grow flex flex-row text-gray-500 items-center h-20 justify-center gap-x-20 font-poppins font-semibold text-base desktopSm:gap-x-28 desktopMd:gap-x-32 desktopXmd:gap-x-36 desktopLg:gap-x-44 desktopXlg:gap-x-48 desktopMd:h-32 desktopXmd:h-36 desktopLg:h-44 desktopXlg:h-52  desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl ">
        <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md">
          <a href="/home">Home</a>
        </ul>
        <ToolsDropdown />
        {/* <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md">
          <a href="#">About Us</a>
        </ul> */}
        {/* <ul className="hover:text-primary active:text-primary">
                    <a href="#">Allied Infra</a>
                </ul>
                <ul className="hover:text-primary active:text-primary">
                    <a href="#">Roads</a>
                </ul> */}
        {/* <ul className="hover:text-primary active:text-primary">
                    <a href="#">PC-1 form</a>
                </ul> */}
        {/* <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md">
          <a href="#" onClick={handleScroll}>
            FAQs
          </a>
        </ul> */}
        <ul className="hover:text-primary active:text-primary py-1 px-2 hover:bg-gray-300 rounded-md">
          <a href="/login">{userExist ? "Projects" : "Login/Signup"}</a>
        </ul>
        {/* <ul className="hover:text-primary active:text-primary">
                    <a href={`${userExist ? '/projects' : '/login'}`}>Account</a>
                </ul> */}
      </div>
      <div className="flex-none flex w-48 h-20 justify-center items-center mr-10">
        <Link to="#">
          <Button
            label={"Contact Us"}
            className={
              "bg-white  border-primary border-2  rounded-full  w-full  px-8 py-3"
            }
            textColor="text-primary hover:text-white font-saira"
            hoverBg="hover:bg-primary"
            icon={<IoIosSend className="text-2xl" />}
          />
        </Link>
      </div>
    </div>
  );
}

import React from "react";
import kp_dd_logo from "../assets/logo-bg-white.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";

export default function Footer() {
    return (
        <div className="flex flex-col bg-primary desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl">
            <div className="flex ">
                <div className="flex-1  p-4">
                    <div className=" flex flex-col  gap-5 p-10">
                        <img src={kp_dd_logo} alt="kp-dd-logo" className="h-fit w-1/6" />
                        <p className="w-fit h-fit text-white font-roboto">
                        Use the PIM Portal to explore over 1600 infrastructure project designs for roads and bridges along with cost estimates and engineer drawings.
                        </p>
                    </div>
                </div>
                <div className="flex-1  p-4">

                    <div className="p-10 text-white ">
                        <p className="font-bold pb-4  font-saira">Relevant Links</p>
                        <div className="flex flex-col gap-4  font-roboto">
                            <ul><a href="https://kp.gov.pk/" target="_blank" className="hover:text-secondary">KPK Gov.</a></ul>
                            <ul><a href="https://pndkp.gov.pk/" target="_blank" className="hover:text-secondary">Planning & Development KPK</a></ul>
                            <ul><a href="https://pndkp.gov.pk/tenders/" target="_blank" className="hover:text-secondary">P&DD KP Tenders</a></ul>
                            <ul><a href="https://pndkp.gov.pk/jobs/" target="_blank" className="hover:text-secondary">P&DD Jobs</a></ul>
                        </div>
                    </div>
                </div>
                <div className="flex-1  p-4">

                    <div className="p-10 text-white">
                        <p className="font-bold pb-4  font-saira">Contact Us</p>
                        <div className="flex flex-col gap-4  font-roboto ">
                            <CallNumber />
                            <ul className=" text-lg pt-5 flex flex-row gap-2  items-center desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl"><BsFillEnvelopeFill /> PDD@example.com</ul>
                            <ul className=" flex flex-row  items-center gap-2"> <MdLocationOn className="text-2xl desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl " />Planning & Development KPK, Civil Secretariat, Peshawar KPK.</ul>

                        </div>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center text-white border-t border-gray-500 w-full p-2 py-5 font-roboto ">
                Copyright &copy; 2021 Planning and Development Department. All Rights Reserved.
            </div>
        </div>
    );
}


export const CallNumber = () => {
    return (
        <div className="p-4 bg-[#3C5E64] flex flex-row  gap-8 font-saira">
            <div className="bg-white text-[#00AA55] rounded-full p-3 h-fit  text-2xl">
                <BsFillTelephoneFill />
            </div>
            <div className=" text-lg text-white ">
                <p className=" font-bold">+92 91 92 10494-94</p>
                <p>Consult With Our Experts</p>
            </div>

        </div>
    )
}

import React from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";

const Button = ({
  label = "Button",
  icon,
  className,
  iconStyle,
  textColor = "text-white",
  hoverBg = "hover:bg-secondary",
  bgColor = "bg-primary",
  onClick,
  loading,
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`flex items-center justify-center px-4 py-2 gap-2  text-md  rounded ${textColor} ${hoverBg} ${bgColor}  focus:outline-none  font-poppins transition-all duration-300 desktopSm:text-xl desktopMd:text-2xl desktopXmd:text-3xl desktopLg:text-4xl  desktopXlg:text-5xl desktopMd:p-4 desktopXmd:p-4 desktopLg:p-6 desktopXlg:p-8 ${className}  `}
      {...props}
    >
      {icon && <span className={` ${iconStyle}`}>{icon}</span>}
      {typeof loading === undefined ? label : loading ? <Dots /> : label}
    </button>
  );
};

export default Button;

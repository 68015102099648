import React, { useEffect, useReducer } from "react";
import BackendLayout from "../../../components/BackendLayout";
import Heading from "../../../components/Heading";
import Selector from "../../../components/Selector";
import Input from "../../../components/Input";
import Button from "../../../components/Button";
import Breadcrumb from "../../../components/Breadcumb";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MRS_Years, Wall_Material, bearingCapacity, handleAddSegment, handleUpdateSegment } from "../../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";


const GravityRetainingWallOutward = () => {
  const { projectId, segmentId } = useParams();
  const navigate = useNavigate();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      typeOfWallMaterials: "",
      bearingCapacity: "",
      numberOfRetaingWallSegments: "",
      lengthOfRetainingWall: "",
      heightOfWallAboveGL: "",
      depthOfFoundation: "",
      MRSAreaLocationCostFactor: "",
      year: ""


    }
  );


  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );

  const dispatch = useDispatch();

  // const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  // const loading = useSelector((state) => state.project?.loading);
  // const created = useSelector((state) => state.project.created);

  // useEffect(() => {
  //   if (created) {
  //     navigate(`/projects/${projectId}`);
  //   }
  //   Swal.close();
  // }, [created]);
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  const created = useSelector((state) => state.project.created);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
      console.log(formState)
    }

    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "GravityRetainingWallSlopingOutward",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "GravityRetainingWallSlopingOutward",
      projectId,
      // segmentInformation,
      segmentId

    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Gravity-Reatining-Outwards-Wall", itemLink: "" },
        ]}
      />

      <Heading
        item={"Gravity Retaining Wall Slope Outwards Module"}
        className={"px-8 py-5"}
      />

      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Type Of Wall Materials
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Type Of Wall Materials"
                name="typeOfWallMaterials"
                required
                value={Wall_Material.find(
                  (f) => f.value === formState?.typeOfWallMaterials
                )}
                options={Wall_Material}
                onChange={(e) => updateFormState({ typeOfWallMaterials: e.value })}
              />
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Bearing Capacity
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Bearing Capacity"
                name="bearingCapacity"
                required
                value={bearingCapacity.find(
                  (f) => f.value === formState?.bearingCapacity
                )}
                options={bearingCapacity}
                onChange={(e) => updateFormState({ bearingCapacity: e.value })}
              />
            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Number Of Retaing Wall Segments (Greater than or equal to 1)
              </label>
              <Input
                type="text"
                min={1}
                name="numberOfRetaingWallSegments"
                required
                className="text-lg  bg-white"
                placeholder="0.00"
                value={formState?.numberOfRetaingWallSegments}
                onChange={(e) => updateFormState({ numberOfRetaingWallSegments: e.target.value })}
              />
              {parseInt(formState?.numberOfRetaingWallSegments) < 1 ? (
                <span className="text-red-500">
                  Select any value greater than or equal to 1
                </span>
              ) : (
                formState?.numberOfRetaingWallSegments && <span className="text-green-500">&#10004;</span>
              )}
            </div>

            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Length of Retaining Wall (m)(Less than or equal to 5)
              </label>
              <Input
                type="text"
                name="lengthOfRetainingWall"
                required
                className="text-lg "
                min={1}
                max={5}
                onChange={(e) => updateFormState({ lengthOfRetainingWall: e.target.value })}
                value={formState?.lengthOfRetainingWall}
              />

              {
                parseInt(formState?.lengthOfRetainingWall) > 5 ? (
                  <span className="text-red-500">Select any value less than or equal to 5</span>
                ) : (
                  formState?.lengthOfRetainingWall && <span className="text-green-500">&#10004;</span>
                )
              }

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Depth of Foundation, Dt (m)
              </label>
              <Input
                type="text"
                name="depthOfFoundation"
                required
                className="text-lg  bg-white"
                value={formState?.depthOfFoundation}
                onChange={(e) => updateFormState({ depthOfFoundation: e.value })}

              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Height of Wall above GL, h (m)
              </label>
              <Input
                type="text"
                name="heightOfWallAboveGL"
                required
                className="text-lg "
                min={1}
                max={5}
                value={formState?.heightOfWallAboveGL}
                onChange={(e) => updateFormState({ heightOfWallAboveGL: e.target.value })}

              />

            </div>

          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Mrs Area Location Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({ MRSAreaLocationCostFactor: e.target.value })
                }

              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>
              {/* <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select MRS Year"
                name="year"
                required
                isSearchable={true}
                value={MRS_Years.find(
                  (city) => city.value === formState?.year
                )}
                options={MRS_Years}
                onChange={(e) => updateFormState({ year: e.value })}
              /> */}
              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}

              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
        </div>
      </form>
    </BackendLayout>
  );
};

export default GravityRetainingWallOutward;

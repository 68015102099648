import React, { useEffect, useReducer } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import BackendLayout from "../../components/BackendLayout";
import Heading from "../../components/Heading";
import Breadcrumb from "../../components/Breadcumb";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import {
  MRS_Years,
  NO_OF_LANES,
  SKEW_ANGLE,
  handleAddSegment,
  handleUpdateSegment,
} from "../../helpers";
import Swal from "sweetalert2";
import Select from "react-select";

const BoxCulvert = () => {
  const { projectId, segmentId } = useParams();
  const navigate = useNavigate();
  const [formState, updateFormState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      noOfLanes: "",
      noOfCellsInBoxCulvert: "",
      widthOfBoxCulvert: "",
      heightOfBoxCulvert: "",
      skewAngle: "",
      heightOfFillOnCulvert: "",
      MRSAreaLocationCostFactor: "",
      year: "",
    }
  );
  const segmentInformation = useSelector((state) => state.project?.segmentInfo);
  const projectData = useSelector((state) =>
    state.project?.projects?.find((p) => p.id === parseInt(projectId, 10))
  );
  const dispatch = useDispatch();
  const created = useSelector((state) => state.project.created);
  const currentEditedSegment = projectData?.segments?.find(
    (s) => s.id === parseInt(segmentId, 10)
  );

  useEffect(() => {
    if (created) {
      navigate(`/projects/${projectId}`);
    }
    if (segmentId) {
      updateFormState({
        ...JSON.parse(
          currentEditedSegment?.inputs[currentEditedSegment?.inputs.length - 1]
            ?.input_value
        ),
      });
    }
    Swal.close();
  }, [created, segmentId, projectData]);

  const handleAdd = (e) => {
    Swal.fire({
      title: "Save and Submitting...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    handleAddSegment(e, dispatch, {
      module: "BoxCulvert",
      projectId,
      segmentInformation,
    });
  };

  const handleUpdate = (e) => {
    Swal.fire({
      title: "Updating Segment ...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      toast: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    handleUpdateSegment(e, dispatch, {
      module: "BoxCulvert",
      projectId,
      //segmentInformation,
      segmentId

    });
    // console.log(demoData);
  };


  return (
    <BackendLayout screenName="Modular Design">
      <Breadcrumb
        items={[
          { itemName: "Home", itemLink: "/projects" },
          { itemName: projectData.name, itemLink: `/projects/${projectId}` },
          { itemName: "Box-Culvert", itemLink: "" },
        ]}
      />

      <Heading item={"Box Culvert Module"} className={"px-8 py-5"} />

      <form
        onSubmit={(e) => {
          { segmentId ? handleUpdate(e) : handleAdd(e) }
        }}
      >
        <div className="flex flex-col gap-8 p-8 flex-grow font-poppins">
          <div className="flex flex-row gap-12  ">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">No. of Lanes</label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="No of Lanes"
                name="noOfLanes"
                required
                value={NO_OF_LANES.find(
                  (f) => f.value === formState?.noOfLanes
                )}
                options={NO_OF_LANES}
                onChange={(e) => updateFormState({ noOfLanes: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                No Of Cells In Box Culvert
              </label>
              <Input
                type="text"
                name="noOfCellsInBoxCulvert"
                required
                className="text-lg  bg-white"
                placeholder="0"
                min={1}
                value={formState?.noOfCellsInBoxCulvert}
                onChange={(e) =>
                  updateFormState({ noOfCellsInBoxCulvert: e.target.value })
                }
              />
              {parseInt(formState?.noOfCellsInBoxCulvert) < 1 ? <span className="text-red-500">Input any number greater than or equal to 1</span> : formState?.noOfCellsInBoxCulvert && <span className="text-green-500">&#10004;</span>}

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Width of Box Culvert (m)(1.0-3.0)
              </label>
              <Input
                type="text"
                name="widthOfBoxCulvert"
                required
                placeholder={"1-3"}
                className="text-lg "
                min={1}
                max={3}
                value={formState?.widthOfBoxCulvert}
                onChange={(e) =>
                  updateFormState({ widthOfBoxCulvert: e.target.value })
                }

              />
              {parseInt(formState?.widthOfBoxCulvert) > 3 ? <span className="text-red-500">Input any number less than or equal to 3</span> : formState?.widthOfBoxCulvert && <span className="text-green-500">&#10004;</span>}

            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Height of Box Culvert (m)

              </label>
              <Input
                type="text"
                name="heightOfBoxCulvert"
                required
                className="text-lg "
                min={1}
                max={4}
                value={formState?.heightOfBoxCulvert}
                onChange={(e) =>
                  updateFormState({ heightOfBoxCulvert: e.target.value })
                }
              />
              {parseInt(formState?.widthOfBoxCulvert) > 2 ?
                (parseInt(formState?.heightOfBoxCulvert) > 3 ?
                  <span className="text-red-500">Input any number less than or equal to 3.0 m</span> :
                  formState?.heightOfBoxCulvert && <span className="text-green-500">&#10004;</span>
                ) :
                (parseInt(formState?.widthOfBoxCulvert) > 1 ?
                  (parseInt(formState?.heightOfBoxCulvert) > 4 ?
                    <span className="text-red-500">Input any number less than or equal to 4.0 m</span> :
                    formState?.heightOfBoxCulvert && <span className="text-green-500">&#10004;</span>
                  ) :
                  (parseInt(formState?.widthOfBoxCulvert) <= 1 ?
                    (parseInt(formState?.heightOfBoxCulvert) > 2 ?
                      <span className="text-red-500">Input any number less than or equal to 2.0 m</span> :
                      formState?.heightOfBoxCulvert && <span className="text-green-500">&#10004;</span>
                    ) :
                    null // Handle the case if widthOfBoxCulvert is neither greater than 2 nor greater than 1
                  )
                )
              }

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Skew Angle (degree)
              </label>
              <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select Skew Angle"
                name="skewAngle"
                required
                value={SKEW_ANGLE.find(
                  (f) => f.value === formState?.skewAngle
                )}
                options={SKEW_ANGLE}
                onChange={(e) => updateFormState({ skewAngle: e.value })}
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                Height of Fill On Culvert (m)(0.0-3.0)
              </label>
              <Input
                type="text"
                name="heightOfFillOnCulvert"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                min={0}
                max={3}
                value={formState?.heightOfFillOnCulvert}
                onChange={(e) =>
                  updateFormState({ heightOfFillOnCulvert: e.target.value })
                }
              />
              {parseInt(formState?.heightOfFillOnCulvert) > 3 ? <span className="text-red-500">Input number from 0 to 3</span> : formState?.heightOfFillOnCulvert && <span className="text-green-500">&#10004;</span>}

            </div>
          </div>

          <div className="flex flex-row gap-12">
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md">
                MRS Area/Location/Cost Factor
              </label>
              <Input
                type="text"
                name="MRSAreaLocationCostFactor"
                required
                className="text-lg bg-white"
                placeholder="0.00"
                value={formState?.MRSAreaLocationCostFactor}
                onChange={(e) =>
                  updateFormState({ MRSAreaLocationCostFactor: e.target.value })
                }
              />
            </div>
            <div className="flex flex-col flex-1 gap-1">
              <label className="text-segmentLabel text-md" hidden>MRS Year</label>
              {/* <Select
                className="border-gray-300 border-b-2 text-primary font-poppins"
                styles={{
                  control: (base) => ({
                    ...base,
                    border: "none",
                    boxShadow: "none",
                  }),
                }}
                classNamePrefix="select"
                placeholder="Select MRS Year"
                name="year"
                required
                isSearchable={true}
                value={MRS_Years.find(
                  (city) => city.value === formState?.year
                )}
                options={MRS_Years}
                onChange={(e) => updateFormState({ year: e.value })}
              /> */}
              <Input
                type="text"
                name="year"
                required
                className="text-lg bg-white empty:hidden "
                // placeholder="0.00"
                value={projectData?.mrs_year}

              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-center py-5 items-center gap-10">
          <Button label={`Cancel`} className={"bg-red-500"} hoverBg="hover:bg-red-700" onClick={() => { navigate(`/projects/${projectId}`); }} />

          {
            parseInt(formState?.noOfCellsInBoxCulvert) < 1 || parseInt(formState?.heightOfFillOnCulvert) > 3 || parseInt(formState?.widthOfBoxCulvert) > 3
              ?
              <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} className={"disabled opacity-20 cursor-not-allowed "} disabled />
              :
              <Button label={` ${segmentId ? "Update Segment" : "Save & Submit"}`} type={"Submit"} />
          }
        </div>
      </form>
    </BackendLayout>
  );
};

export default BoxCulvert;
